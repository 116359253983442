(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingDetailController', BookingDetailController);

    BookingDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$state', '$uibModalInstance', '$http', '$uibModal',
    'entity', 'Principal', 'Booking', 'OpsShipper', 'OpsColoader', 'OpsReport', 'BookingTruck', 'OpsPorts', 'OpsCost', 'OpsCompany',
    'BookingCtns', 'BookingPrice', 'OpsTemplate', 'OpsRates', 'Guide', 'DataUtils', 'OpsCarrier', 'WmsWarehouse',
    'GuideCtns', 'OpsFiles', 'Email', 'AddressBook', 'WmsIn', 'OpsVender'];

    function BookingDetailController($timeout, $scope, $rootScope, $translate, $state, $uibModalInstance, $http, $uibModal,
        entity, Principal, Booking, OpsShipper, OpsColoader, OpsReport, BookingTruck, OpsPorts, OpsCost, OpsCompany,
        BookingCtns, BookingPrice, OpsTemplate, OpsRates, Guide, DataUtils, OpsCarrier, WmsWarehouse,
        GuideCtns, OpsFiles, Email, AddressBook, WmsIn, OpsVender) {
        $scope.guide = entity;

        var guideCopy = {};
        var isEmail = false;
        $scope.files = new Array();
        $scope.isFba = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if( $scope.guide.shipmentType == 'EXPRESS' 
                || $scope.guide.shipmentType == 'FBASEA' 
                || $scope.guide.shipmentType == 'LOCALKG' 
                || $scope.guide.shipmentType == 'FBARAIL'
                || $scope.guide.shipmentType == 'FBAAIR'
                || $scope.guide.shipmentType == 'WMS'
                || $scope.guide.shipmentType == 'LOCAL'){
                return true;
            }
            return false;
        }

        $scope.isZB = function() {
            return $rootScope.isZb($rootScope.HOST_COMPANY_ID);
        }
        $scope.bkRemarks = $translate.instant('autopsApp.booking.home.tips_content');
        var getBkTerms = function() {
            if(294 == $rootScope.HOST_COMPANY_ID && $rootScope.membershipExpired){
                $rootScope.OPS_ALERT($translate.instant('global.membershipExpiredAlert', {param: $rootScope.membershipExpired}), {icon: 1}); 
                $uibModalInstance.dismiss();
                return;
            }
            if($scope.isFba()){
                $scope.bkRemarks = $scope.bkRemarks + "\n请仔细核对提交装箱单发票件重尺、货值（注意不能低申报漏报瞒报）、品名 、HS CODE 、税率等信息；\n";
                if($scope.isZB()){
                    $scope.bkRemarks = "请仔细核对提交装箱单发票件重尺、货值（注意不能低申报漏报瞒报）、品名 、HS CODE 、税率等信息；\n我司预收税率，为使用我司税号清关，由于外贸商业机密，我司不提供关税清单和税费截图；\n如货物到港清关时，遇到反倾销或海关大幅调整关税的情况，我司将提供凭证，收取关税差额；\n如客户要求清完关后凭税单及发票付关税，我司将收取关税代缴手续费（代缴关税总金额的5%）;";           
                }
            }
        }
        var loadCompanyIdAndCurs = function() {
            if (!$rootScope.OPSCURRENCYS){
                $rootScope.loadCurrencys();                
            }
            if(!$rootScope.HOST_COMPANY_ID){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                    getBkTerms();
                })
            }            
            else {
                getBkTerms();
            }
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadCompanyIdAndCurs();
            });
        }
        else {
            loadCompanyIdAndCurs();
        }

        $timeout(function (){
            initData();
            if($scope.guide.booking.sellingId && !$scope.guide.booking.id){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.refreshRatesTips'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            }
            getCargpPic();    
            $scope.getWmsAndPols();
            $rootScope.LOAD_JOB_OWNERSHIP_COMABB($scope.guide);
            // $scope.refreshRates();
        });




        $scope.isYhsp = function() {
            if($rootScope.HOST_COMPANY_ID == 320 
                || $rootScope.HOST_COMPANY_ID == 337
                || $rootScope.HOST_COMPANY_ID== 338){
                return true;
            }
            if($rootScope.HOST_COMPANY_ID == 326 && $scope.guide.checkStatus !='approved'){
                return true;
            }
            return false;
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }


        var goBack = function() {
            $uibModalInstance.close(guideCopy.booking);
        }

        $scope.goBack = function() {
            if (!angular.equals($scope.guide, guideCopy) && !$scope.guide.booking.isLocked) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    goBack();
                    layer.close(index);
                }, function() {});
                return;
            } else {
                goBack();
            }
        }
        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing       
                $scope.goBack();              
            }
        });
        $scope.portList = [];
        $scope.deliveryToPortList = [];

        $scope.loadFbaPorts = function() {
            if ($rootScope.OPSPORTS.fbaPorts && $rootScope.OPSPORTS.fbaPorts.length > 0){
                return;
            }
            OpsPorts.getFBAPorts(function(result){
                $rootScope.OPSPORTS.fbaPorts = [];
                angular.forEach(result, function(data){
                    $rootScope.OPSPORTS.fbaPorts.push(data);
                })
                initDeliveryToPortList();
            })
        };
        var initDeliveryToPortList = function() {
            $scope.deliveryToPortList = [];
            if($rootScope.OPSPORTS.fbaPorts){
                angular.forEach($rootScope.OPSPORTS.fbaPorts, function(data){
                    $scope.deliveryToPortList.push(data);
                })
            }
            if($scope.portList){
                angular.forEach($scope.portList, function(data){
                    $scope.deliveryToPortList.push(data);
                })
            }
            
        }

        $scope.loadPorts = function(fd) {
            if(!$scope.guide.booking[fd] && fd){
                $scope.guide.booking[fd] = "";
            }
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initPort();
            })
        };

        var initPort = function() {
            if ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR") {
                $scope.portList = $rootScope.OPSPORTS.airports;                
            }
            else {
                $scope.portList =  $rootScope.OPSPORTS.seaports;
            }
            $scope.loadFbaPorts();
            initDeliveryToPortList();   
        };

        var initBooking = function() {
            if ($scope.guide.shipmentType == "FCL" && (!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0)) {
                $scope.guide.guideFclCtns = [];
                $scope.guide.guideFclCtns.push({});
            }
            if (!$scope.guide.booking.bookingPrices || $scope.guide.booking.bookingPrices.length == 0) {
                $scope.guide.booking.bookingPrices = [];
            }
            if ($scope.guide.truckRequired == true && !$scope.guide.booking.bookingTrucks) {
                $scope.guide.booking.bookingTrucks = [{}];
            }
            if ($scope.guide.customRequired == true && !$scope.guide.bookingCustoms) {
                $scope.guide.bookingCustoms = [{}];
            }
            guideCopy = angular.copy($scope.guide);
            $rootScope.getCountryName($scope.guide.destCountryCode);
            if ($scope.guide.shipmentType == "FCL" && $scope.guide.booking.id && $scope.guide.booking.status != "new" && $scope.guide.id) {
                GuideCtns.get({
                    id: $scope.guide.id
                }, function(result) {
                    if ($scope.guide.guideFclCtns) {
                        for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                            if ($scope.guide.guideFclCtns[i].ctnType && $scope.guide.guideFclCtns[i].num) {
                                var size = 0;
                                for (var ii = 0; ii < result.length; ii++) {
                                    if (result[ii].ctnType == $scope.guide.guideFclCtns[i].ctnType) {
                                        size++;
                                    }
                                }
                                for (var j = 0; j < $scope.guide.guideFclCtns[i].num - size; j++) {
                                    if(!$scope.guide.guideCtns){
                                        $scope.guide.guideCtns = [];
                                    }
                                    $scope.guide.guideCtns.push({
                                        ctnType: $scope.guide.guideFclCtns[i].ctnType
                                    });
                                }
                            }
                        }
                    }
                });
            }
            initPort();
        }

        
        // $scope.onColoaderSelected = function(data) {
        //     $scope.guide.booking.bookingToId = data.id;
        //     $scope.guide.booking.bookingToName = data.companyName;
        //     $scope.guide.booking.bookingToAddress = data.address;
        //     $scope.guide.booking.bookingToAttn = data.attn;
        //     $scope.guide.booking.bookingToTel = data.tel;
        //     $scope.guide.booking.bookingToFax = data.fax;
        //     $scope.guide.booking.bookingToMob = data.mob;
        //     $scope.guide.booking.bookingToEmail = data.email;
        //     $scope.guide.booking.bookingToCc = data.cc;
        // }
        // var chooseFirstColoader = function() {
        //     if(!$scope.guide.booking.bookingToName){
        //         $scope.onColoaderSelected($scope.opsColoaders[0]);
        //     }  
        // }
        // $scope.opsColoaders = [];
        // $scope.loadOpsColoader = function() {
        //     if ($scope.opsColoaders && $scope.opsColoaders.length > 0) {
        //         chooseFirstColoader();
        //         return;
        //     };
        //     OpsColoader.getAll(function(result) {
        //         $scope.opsColoaders = result;
        //         for (var i = $scope.opsColoaders.length -1; i >=0; i--) {
        //             if (!$scope.opsColoaders[i].companyName){
        //                 $scope.opsColoaders.splice(i, 1);
        //             }
        //         }
        //         if($scope.opsColoaders.length > 0){
        //             chooseFirstColoader();
        //         }                
        //     });
        // };

        var initData = function() {
            if ($rootScope.id == "view") {
                initBooking();
            } else if ($rootScope.id == "copy") {
                $scope.guide = DataUtils.clearCopyGuide($scope.guide);
                $scope.guide.siCutTime = null;
                $scope.guide.cls = null;
                $scope.guide.vsl = null;
                $scope.guide.voy = null;
                $scope.guide.etd = null;
                $scope.guide.eta = null;                
                $scope.guide.booking.status = "new";
                $scope.guide.status = "new";
                guideCopy = angular.copy($scope.guide);
                initPort();
            } else {
                if (!$scope.guide){
                    $scope.guide = {
                        ownImporter: false,
                        warehouseRequired: true
                    };
                    $scope.guide.bookingCustoms = [];
                    $scope.guide.booking = {
                        shipperBl: "TO ORDER",
                        cneeBl: "TO ORDER",
                        notifyBl: "SAME AS CONSIGNEE",
                        bookingCtns: [{pkgType: "CTNS"}],
                        bookingPrices: [],
                        bookingTrucks: [],
                        status: "new"
                    }
                };
                if(!$scope.guide.shipmentType){
                    if($rootScope.bookingType == "fms"){
                        $scope.guide.shipmentType = ($rootScope.HOST_COMPANY_ID == 258?"AIR":"FCL");
                        $scope.guide.chargeByCbm = ($rootScope.HOST_COMPANY_ID == 258?false:true);
                    }
                    else if($rootScope.bookingType == "fba"){
                        $scope.guide.shipmentType = "FBASEA";
                        $scope.guide.chargeByCbm = true;
                    }
                    else {
                        $scope.guide.shipmentType = "LOCAL";
                        $scope.guide.chargeByCbm = false;
                    }
                }
                $scope.guide.dontSynTtl = false;
                // $scope.loadOpsColoader();
                initPort();
                // if($scope.guide.booking.deliveryTo){
                //     var address = {};
                //     address.code = $scope.guide.booking.deliveryTo;
                //     $scope._selected(address, 'deliveryChoose');
                // }              

            }
            if(!$scope.guide.valueCur){
                $scope.guide.valueCur = "USD";
            }
        }
        $scope.isFcl = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return true;
            }
            if($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'LCL' 
                || $scope.guide.shipmentType == 'EXPRESS' || $scope.guide.shipmentType == 'LOCAL' || $scope.guide.shipmentType == 'WMS'
                || $scope.guide.shipmentType == 'BULK' || $scope.guide.shipmentType == 'FBASEA' || $scope.guide.shipmentType == 'FBAAIR' || $scope.guide.shipmentType == 'FBARAIL'
                || $scope.guide.shipmentType == 'LOCALKG'){
                return false;
            }
            return true;
        }



        $scope.isWmsLocal = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if($scope.guide.shipmentType == 'WMS'
                || $scope.guide.shipmentType == 'LOCALKG'
                || $scope.guide.shipmentType == 'LOCAL'){
                return true;
            }
            return false;
        }
        $scope.isLocal = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if($scope.guide.shipmentType == 'LOCALKG'
                || $scope.guide.shipmentType == 'LOCAL'){
                return true;
            }
            return false;
        }

        $scope._selected = function($item, which){
            switch(which){
                case "porChoose":                    
                    $scope.guide.booking.porId = $item.code;               
                    break;
                case "polChoose":                    
                    $scope.guide.booking.polId = $item.code;               
                    $scope.guide.polRegion = $item.region;
                    break;
                case "podChoose":
                    $scope.guide.booking.podId = $item.code; 
                    if(!$scope.guide.podRegion) {
                        $scope.guide.podRegion = $item.region;
                    }                     
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;   
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;                    
                    break;
                case "deliveryChoose":
                    $scope.guide.booking.deliverytoId = $item.code;  
                    if($scope.isFba()){
                        AddressBook.getFbaAddress({code: $item.code, countryCode: $item.countryCode}, function(result){
                            DataUtils.onGuideAddressChoosed($scope.guide, result);
                            $rootScope.OWN_IMPORTER_CHECK(result.countryCode, $scope.guide);
                        });      
                    }               
                    break;
            }
        }

        $scope.addCtn = function() {
            if(!$scope.guide.booking.bookingCtns){
                $scope.guide.booking.bookingCtns = [];
            }
            $scope.guide.booking.bookingCtns.push({pkgType: "CTNS", importCountryCode:$scope.guide.destCountryCode});
        };

        $scope.addPice = function() {
            if (!$scope.guide.shipmentType) {
                layer.msg($translate.instant('autopsApp.booking.home.completeInfoNotice'));
                return;
            };
            $scope.guide.booking.bookingPrices.push({
                "itemId": "",
                "item": "",
                "currency": $rootScope.account.baseCurrency,
                "unit": "BL",
                "count": 1,
                "paymentTerm": "PPD"
            });
        };

        $scope.generateGwVol = function() {
            if($scope.guide.hblConfirmedTime){
                return;
            }
            if ($scope.guide.booking.gwVol) {
                $scope.guide.booking.gwVol = angular.uppercase($scope.guide.booking.gwVol);
                return;
            }
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.gw + " KGS" + "\n" + $scope.guide.vol + " CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.vol + " CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.booking.gwVol = $scope.guide.gw + " KGS";
            };
        };
        $scope.generatePrice = function() {
            if (!$scope.guide.shipmentType) {
                // $('#myTab a[href="#bookingDetails"]').tab('show');
                layer.msg($translate.instant('autopsApp.booking.home.completeInfoNotice'));
            } 
            else if ($scope.guide.shipmentType == "FBASEA" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR" || $scope.guide.shipmentType == 'FBARAIL') {
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "1",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "SHIPMENT",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
            } 
            else if ($scope.guide.shipmentType == "FCL") {
                if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length != 0) {
                    for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                        if ($scope.guide.guideFclCtns[i].ctnType) {
                            $scope.guide.booking.bookingPrices.push({
                                "itemId": "1",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                                "currency": "USD",
                                "unit": $scope.guide.guideFclCtns[i].ctnType,
                                "count": $scope.guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD"
                            });

                            $scope.guide.booking.bookingPrices.push({
                                "itemId": "2",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.mtf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": $scope.guide.guideFclCtns[i].ctnType,
                                "count": $scope.guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD"
                            });
                            $scope.guide.booking.bookingPrices.push({
                                "itemId": "3",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.gzf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": $scope.guide.guideFclCtns[i].ctnType,
                                "count": $scope.guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD"
                            });
                            if ($scope.guide.truckRequired) {
                                $scope.guide.booking.bookingPrices.push({
                                    "itemId": "4",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": $scope.guide.guideFclCtns[i].ctnType,
                                    "count": $scope.guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD"
                                });
                            };
                            if ($scope.guide.fumigationRequired) {
                                $scope.guide.booking.bookingPrices.push({
                                    "itemId": "5",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": $scope.guide.guideFclCtns[i].ctnType,
                                    "count": $scope.guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD"
                                });
                            };
                            if ($scope.guide.warehouseRequired) {
                                $scope.guide.booking.bookingPrices.push({
                                    "itemId": "6",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ckf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": $scope.guide.guideFclCtns[i].ctnType,
                                    "count": $scope.guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD"
                                });
                            };
                        };
                    };
                };
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                if ($scope.guide.customRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "13",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.insuranceRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.coRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.booking.serviceTerm == "CY-DOOR" || $scope.guide.booking.serviceTerm == "DOOR-DOOR") {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length != 0) {
                        for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                            if ($scope.guide.guideFclCtns[i].ctnType) {
                                $scope.guide.booking.bookingPrices.push({
                                    "itemId": "17",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dmtf'),
                                    "currency": "USD",
                                    "unit": $scope.guide.guideFclCtns[i].ctnType,
                                    "count": $scope.guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD"
                                });
                                $scope.guide.booking.bookingPrices.push({
                                    "itemId": "18",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                                    "currency": "USD",
                                    "unit": $scope.guide.guideFclCtns[i].ctnType,
                                    "count": $scope.guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD"
                                });

                            };
                        };
                    };
                };
            } else if ($scope.guide.shipmentType == "AIR") {
                if ($scope.guide.vol * 167 > $scope.guide.gw) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "10",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.kyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "VW",
                        "count": $scope.guide.vol * 167,
                        "paymentTerm": "PPD"
                    });
                } else {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "10",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.kyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "KG",
                        "count": $scope.guide.gw,
                        "paymentTerm": "PPD"
                    });
                };
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "11",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.zkf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                if ($scope.guide.truckRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "4",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.fumigationRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.insuranceRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.coRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.booking.serviceTerm == "CY-DOOR" || $scope.guide.booking.serviceTerm == "DOOR-DOOR") {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };

            } else if ($scope.guide.shipmentType == "LCL") {
                if ($scope.guide.vol * 1000 > $scope.guide.gw) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "1",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                        "currency": "USD",
                        "unit": "MT",
                        "count": $scope.guide.vol,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": $scope.guide.vol,
                        "paymentTerm": "PPD"
                    });
                } else {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "1",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                        "currency": "USD",
                        "unit": "MT",
                        "count": $scope.guide.gw / 1000,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": $scope.guide.gw / 1000,
                        "paymentTerm": "PPD"
                    });
                };
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                $scope.guide.booking.bookingPrices.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD"
                });
                if ($scope.guide.truckRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "4",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.fumigationRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.insuranceRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.coRequired) {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                };
                if ($scope.guide.booking.serviceTerm == "CY-DOOR" || $scope.guide.booking.serviceTerm == "DOOR-DOOR") {
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    $scope.guide.booking.bookingPrices.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD"
                    });
                    if ($scope.guide.vol * 1000 > $scope.guide.gw) {
                        $scope.guide.booking.bookingPrices.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": $scope.guide.vol,
                            "paymentTerm": "PPD"
                        });
                    } else {
                        $scope.guide.booking.bookingPrices.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": $scope.guide.gw / 1000,
                            "paymentTerm": "PPD"
                        });
                    };
                };
            };
        };

        $scope.deleteCtn = function(bookingCtn) {
            if (bookingCtn.id && $scope.guide.id) {
                BookingCtns.delete({
                    id: bookingCtn.id
                }, function(){
                    layer.msg($translate.instant('global.delete_success'));
                    var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                    if (index > -1) {
                        $scope.guide.booking.bookingCtns.splice(index, 1);
                    };
                    $scope.updateVolGw();
                }, function(result){
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }
            else {
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
                $scope.updateVolGw();
            }

        };
        $scope.deletePrice = function(bookingPrice) {
            if (bookingPrice.id) {
                BookingPrice.delete({
                    id: bookingPrice.id
                });
            };
            var index = $scope.guide.booking.bookingPrices.indexOf(bookingPrice);
            if (index > -1) {
                $scope.guide.booking.bookingPrices.splice(index, 1);
            };
        };

        // 日期控件
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        var emailType = "";
        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            if (isEmail) {
                isEmail = false;
                $scope.generateMultiAttachment(emailType);
            } else {
                layer.msg($translate.instant('global.messages.submitted'));
                // goBack();
            }
        };
        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } 
            else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        var generateGwAndVol = function() {
            if ($scope.guide.shipmentType == "FCL" && $scope.guide.guideFclCtns) {
                var totalGw = 0;
                var totalVol = 0;
                for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                    if ($scope.guide.guideFclCtns[i].gw) {
                        totalGw = totalGw + $scope.guide.guideFclCtns[i].gw * $scope.guide.guideFclCtns[i].num;
                    }
                    if ($scope.guide.guideFclCtns[i].vol) {
                        totalVol = totalVol + $scope.guide.guideFclCtns[i].vol * $scope.guide.guideFclCtns[i].num;
                    }
                }
                $scope.guide.gw = totalGw;
                $scope.guide.vol = totalVol;
            }
        };     
        

        var loadGuide = function() {
            $scope.isSaving = true;
            Guide.getGuideBookingDTO({id : $scope.guide.id}, function(result){
                $scope.isSaving = false;
                $scope.guide = result;
                guideCopy = angular.copy($scope.guide);
                $rootScope.getCountryName($scope.guide.destCountryCode);
                getCargpPic();
                layer.msg($translate.instant('entity.action.loadSuccess'));
            });            
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:guideUpdate', result);
            layer.msg($translate.instant('global.save_success'));
            getCargpPic();    
        }

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else {
                $rootScope.ALERT_ERROR(result);
            }            
        }

        $scope.save = function() {
            if (angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            // alert($rootScope.account.id);
            $scope.isSaving = true;
            generateGwAndVol();
            if (!$scope.guide.id) {
                $scope.guide.shipperUserId = $rootScope.account.id;
                $scope.guide.customerUserId = $rootScope.account.id;
                $scope.guide.booking.createdTime = new Date();      
            } 
            $scope.guide.booking.lastModifiedTime = new Date();
            Guide.updateGuideBooking($scope.guide, onSaveSuccess, onSaveError);
        };

        var preSendBooking = function() {
            $scope.guide.booking.lastModifiedTime = new Date();
            $scope.guide.booking.status = "Requested";
            generateGwAndVol();
            $scope.generatePkg();
        }

        var firstSendSuccess = function(result) {
            $scope.isSaving = false;
            if(result.alertMsg){
                $rootScope.OPS_ALERT(result.alertMsg)
                return;
            }
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            if (isEmail) {
                isEmail = false;
                $scope.generateMultiAttachment("firstBk");
            } else {
                var guideReportDTO = angular.copy($scope.guide);
                guideReportDTO.opType = 2;
                guideReportDTO.reportType = 10;
                guideReportDTO.fileType = 1;
                OpsReport.generateGuide({}, guideReportDTO);
                layer.msg($translate.instant('global.messages.submitted'));
                // goBack();
            }
            getCargpPic();    
        };
        
        var firstSendError = function(result) {
            $scope.isSaving = false;
            $scope.guide.booking.status = "new";

            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        $scope.resend = function() {
            if ($scope.guide.booking.isLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };

            if ($scope.guide.shipmentType == "FCL" && (!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0)) {
                layer.msg($translate.instant('autopsApp.booking.bookingCtns.chooseCtns'));
                return;
            };

            checkBooking();
            if (!isAllOk) {
                return;
            }
            if($scope.isFba() && !$scope.isLocal() && $rootScope.HAVENOAUTH('INGPIC')){
                Booking.checkCargoPic($scope.guide.booking, function(result){
                    if(result.cargoStr){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoPicAlert', {cargoStr: result.cargoStr}),{icon: 0});
                        return;
                    }
                    else {
                        doReSend();
                    }
                })
            }
            else {
                doReSend();
            }
        };

        var doReSend = function() {
            if ($scope.guide.booking.bookingPrices) {
                for (var i = 0; i < $scope.guide.booking.bookingPrices.length; i++) {
                    if ($scope.guide.booking.bookingPrices[i].itemId && ($scope.guide.booking.bookingPrices[i].itemId == 1 || $scope.guide.booking.bookingPrices[i].itemId == 10)) {
                        if ($scope.guide.booking.bookingPrices[i].paymentTerm) {
                            $scope.guide.booking.freightTerm = $scope.guide.booking.bookingPrices[i].paymentTerm;
                        }
                    }
                }
            }
            if ($scope.guide.booking.bookingTrucks) {
                if ($scope.guide.booking.bookingTrucks.length > 0 && !$scope.guide.booking.bookingTrucks[0].status) {
                    $scope.guide.booking.bookingTrucks[0].status = "new";
                    $scope.guide.booking.bookingTrucks[0].isExport = true;
                }
            }
            if(!$scope.guide.submited){
                $scope.guide.booking.status = "Amended";
                $scope.guide.status = "Amended";
            }
            $scope.guide.booking.lastModifiedTime = new Date();
            $scope.generatePkg();
            if (isEmail) {
                $scope.isSaving = true;
                Guide.updateGuideBooking($scope.guide, onSendSuccess, onSendError);
            } else {
                var title = $translate.instant('autopsApp.booking.home.resend_comfirm_title');
                var content = $scope.guide.booking.bookingToName + $translate.instant('autopsApp.booking.home.resend_comfirm_content');
                layer.confirm(content, {
                    title: title,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function() {
                    layer.closeAll();
                    $scope.isSaving = true;
                    Guide.updateGuideBooking($scope.guide, onSendSuccess, onSendError);
                }, function() {
                    return;
                });
            }
        }

        $scope.send = function() {
            if ($scope.guide.booking.isLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.shipmentType == "FCL" && (!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0)) {
                layer.msg($translate.instant('autopsApp.booking.bookingCtns.chooseCtns'));
                return;
            };
            checkBooking();
            if (!isAllOk) {
                return;
            }

            if($scope.isFba() && !$scope.isLocal() && $rootScope.HAVENOAUTH('INGPIC')){
                Booking.checkCargoPic($scope.guide.booking, function(result){
                    if(result.cargoStr){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoPicAlert', {cargoStr: result.cargoStr}),{icon: 0});
                        return;
                    }
                    else {
                        termConfirmPop();
                    }                    
                })
            }
            else {
                termConfirmPop();
            }
        };

        
      
        var termConfirmPop = function() {
            Booking.getBookingTerm(function(result){
                var entity = {};
                entity.remarks = result.bookingTerm;
                $uibModal.open({
                    templateUrl: 'app/entities/bookingCtns/bookingCtns-detail.html',
                    controller: 'BookingCtnsDetailController',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return entity;
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsCost');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                    doSend();
                }, function() {
                })
            });   
        }


        var doSend = function() {
            if (!$scope.guide.id) {
                $scope.guide.shipperUserId = $rootScope.account.id;
                $scope.guide.booking.createdTime = new Date();      
            } 
            if ($scope.guide.booking.bookingPrices) {
                for (var i = 0; i < $scope.guide.booking.bookingPrices.length; i++) {
                    if ($scope.guide.booking.bookingPrices[i].itemId && ($scope.guide.booking.bookingPrices[i].itemId == 1 || $scope.guide.booking.bookingPrices[i].itemId == 10)) {
                        if ($scope.guide.booking.bookingPrices[i].paymentTerm) {
                            $scope.guide.booking.freightTerm = $scope.guide.booking.bookingPrices[i].paymentTerm;
                        }
                    }
                }
            }

            if ($scope.guide.booking.bookingTrucks) {
                if ($scope.guide.booking.bookingTrucks.length > 0 && !$scope.guide.booking.bookingTrucks[0].status) {
                    $scope.guide.booking.bookingTrucks[0].status = "new";
                    $scope.guide.booking.bookingTrucks[0].isExport = true;
                }
            }
            $scope.isSaving = true;
            preSendBooking();
            Guide.save($scope.guide, firstSendSuccess, firstSendError);
        }

        // SHIPPER/cnee/notify/coloader 数据加载
        $scope._onItemSelected = function(data, rate) {
            rate.itemId = data.id;
            if ($translate.instant("autopsApp.booking.opsItem.cnen") == "nameEn") {
                rate.item = data.nameEn;
            } else {
                rate.item = data.nameCn;
            };
            if (data.currency) {
                rate.currency = data.currency;
            }            
            // if(data.price){
            //     rate.price = data.price;
            // }
            if (data.unit) {
                rate.unit = data.unit;
            }
            rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
            $scope.totalChange(rate);
        }

        $scope.opsShippers = [];
        $scope.loadOpsShipper = function() {
            if ($scope.opsShippers && $scope.opsShippers.length > 0) {
                return;
            }    
            OpsShipper.getAll({
                userId: $rootScope.account.id
            }, function(result) {
                $scope.opsShippers = result;
                for (var i = $scope.opsShippers.length -1; i >=0; i--) {
                    if (!$scope.opsShippers[i].companyName){
                        $scope.opsShippers.splice(i, 1);
                    }
                }
            })
        };

        $scope.useTemplate = function() {
            var entity = {};            
            entity.shipmentType = "booking";  
            entity.guide = {};         
            entity.guide.booking = $scope.guide.booking;
            $uibModal.open({
                templateUrl: 'app/entities/opsTemplate/opsTemplate-choose.html',
                controller: 'OpsTemplateChooseController',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                for (var i = 0; i < result.length; i++) {
                    result[i].id = null;
                    $scope.guide.booking.bookingPrices.push(result[i]);
                };
            }, function() {});
        };

        var isAllOk = false;
        var checkBooking = function() {
            isAllOk = true;
            if($rootScope.HOST_COMPANY_ID == 647 && !$scope.guide.productName && $rootScope.isFba()){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.productNameRequired'));
                isAllOk = false;
                return;
            }
            if (!$scope.guide.booking.porName && $scope.isFba() && $scope.isZB() && !$scope.isLocal()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.por_invalid'));
                isAllOk = false;
                return;
            };
            if (!$scope.guide.productName && $scope.isFba() && $scope.isZB() && !$scope.isLocal()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.productNullAlert'));
                isAllOk = false;
                return;
            };

            if (!$scope.guide.booking.deliveryTo 
                && (!$scope.guide.shiptoPostcode || !$scope.guide.destCountryCode)
                && $scope.isFba() && !$scope.isLocal()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.deliveryTo_invalid'));
                isAllOk = false;
                return;
            };
            if($scope.isFba() && $rootScope.HAVEAUTH('PL_REQ')){
                if ((!$scope.guide.booking.bookingCtns || $scope.guide.booking.bookingCtns.length == 0) && !$scope.isLocal()) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.pl_required'));
                    isAllOk = false;
                    return;
                };
                if (!$scope.guide.fbaRef) {
                    for(var i = $scope.guide.booking.bookingCtns.length - 1; i >= 0; i--){
                        if(!$scope.guide.booking.bookingCtns[i].fbaRef){
                            $rootScope.OPS_ALERT("Reference Id is required!");
                            isAllOk = false;
                            return;
                        }
                    }
                };
                if (!$scope.guide.shipmentId) {
                    for(var i = $scope.guide.booking.bookingCtns.length - 1; i >= 0; i--){
                        if(!$scope.guide.booking.bookingCtns[i].shipmentId){
                            $rootScope.OPS_ALERT("Shipment Id is required!");
                            isAllOk = false;
                            return;
                        }
                    }
                };
            }

            if (!$scope.guide.booking.polName && !$scope.isFba()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.pol_invalid'));
                isAllOk = false;
                return;
            };
            if (!$scope.guide.booking.podName && !$scope.isFba()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.pod_invalid'));
                isAllOk = false;
                return;
            };

            if ($scope.guide.ownImporter && $scope.isFba() && !$scope.isWmsLocal()) {
                if(!$scope.guide.booking.importerName || !$scope.guide.booking.importerAddress || !$scope.guide.booking.importerTaxId){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.importerInfo_invalid'));
                    isAllOk = false;
                    return;
                }
            };
            
            if (!$scope.guide.shipmentType) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.type_invalid'));
                isAllOk = false;
                return;
            };
            if (!$scope.guide.cargoReadyDate) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.CRD_invalid'));
                isAllOk = false;
                return;
            };
            if ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "LCL") {
                if (!$scope.guide.gw) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.gw_null'));
                    isAllOk = false;
                    return;
                };
                if (!$scope.guide.vol) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.vol_null'));
                    isAllOk = false;
                    return;
                };
            };

            if ($scope.guide.shipmentType == "FCL" && $scope.guide.guideFclCtns) {
                if (!$scope.guide.guideFclCtns[0].num) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.ctn_num_null'));
                    isAllOk = false;
                    return;
                };
                if (!$scope.guide.guideFclCtns[0].ctnType) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.ctn_type_null'));
                    isAllOk = false;
                    return;
                };
            };
            
            if (!$scope.guide.booking.mark && ($scope.guide.shipmentType == "LCL" || $scope.guide.shipmentType == "AIR")) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.mark_invalid'));
                isAllOk = false;
                return;
            };

            if ($scope.guide.truckRequired) {
                if (!$scope.guide.booking.bookingTrucks[0] || !$scope.guide.booking.bookingTrucks[0].loadTime) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.loadTime_null'));
                    isAllOk = false;
                    return;
                };
                if (!$scope.guide.booking.bookingTrucks[0].loadAddress) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.loadAddress_null'));
                    isAllOk = false;
                    return;
                };
                if (!$scope.guide.booking.bookingTrucks[0].attn) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.attn_null'));
                    isAllOk = false;
                    return;
                };
            };

            if ($scope.guide.customRequired && !$scope.isLocal()) {
                if (!$scope.guide.bookingCustoms || $scope.guide.bookingCustoms.length == 0 
                    || !$scope.guide.bookingCustoms[0].exportType) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.exportType_null'));
                    isAllOk = false;
                    return;
                };
            };

            if ($scope.guide.insuranceRequired && !$scope.guide.cargoValue) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoValue_null'));
                isAllOk = false;
                return;
            };

            // if($scope.isZB() && $scope.isFba()){
            //     var cargoNames = new Set();
            //     angular.forEach($scope.guide.booking.bookingCtns, function(ctn){
            //         if(ctn.cargoName){
            //             if(cargoNames.has(ctn.cargoName)){
            //                 $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoNameRepeat'));
            //                 isAllOk = false;
            //                 return;
            //             }
            //             else {
            //                 cargoNames.add(ctn.cargoName);
            //             }
            //         }
            //     })
            // }
        };

        $scope.shipmentTypeChange = function(type) {
            initPort();
            if (type == "FCL" && (!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0)) {
                $scope.guide.guideFclCtns = [];
                $scope.guide.guideFclCtns.push({});
            }
            if (type.indexOf('AIR') !== -1 || type == "EXPRESS" || type == "LOCALKG" || type == "LOCAL") {
                $scope.guide.chargeByCbm = false;
                $scope.portList = $rootScope.OPSPORTS.airports;          
                if ($scope.guide.booking.polName && $scope.guide.booking.polName.length > 3) {
                    $scope.guide.booking.polName = null;
                    $scope.guide.polRegion = null;
                }
                if ($scope.guide.booking.podName && $scope.guide.booking.podName.length > 3) {
                    $scope.guide.booking.podName = null;
                    $scope.guide.podRegion = null;
                }
                if ($scope.guide.booking.destName && $scope.guide.booking.destName.length > 3) {
                    $scope.guide.booking.destName = null;
                }
            } else {
                $scope.guide.chargeByCbm = true;
                $scope.portList = $rootScope.OPSPORTS.seaports;
                if ($scope.guide.booking.polName && $scope.guide.booking.polName.length < 5) {
                    $scope.guide.booking.polName = null;
                    $scope.guide.polRegion = null;
                }
                if ($scope.guide.booking.podName && $scope.guide.booking.podName.length < 5) {
                    $scope.guide.booking.podName = null;
                    $scope.guide.podRegion = null;
                }
                if ($scope.guide.booking.destName && $scope.guide.booking.destName.length < 5) {
                    $scope.guide.booking.destName = null;
                }
            }
            $scope.updateVolGw();
        };

        $scope.isSame = function() {
            return angular.equals($scope.guide, guideCopy);
        }

        $scope.generatePkg = function() {
            if($scope.guide.booking.pkgsAndType){
                $scope.guide.booking.pkgsAndType = angular.uppercase($scope.guide.booking.pkgsAndType);
                return;
            }
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum + " " + $scope.guide.booking.pkgType;
            } else if ($scope.guide.pkgNum) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum + " ";
            } else if ($scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = " " + $scope.guide.booking.pkgType;
            };
            $scope.guide.booking.pkgsAndType = angular.uppercase($scope.guide.booking.pkgsAndType);
        }

        $scope.addGCtn = function() {
            if(!$scope.guide.guideCtns){
                $scope.guide.guideCtns = [];
            }
            $scope.guide.guideCtns.push({});
        };
        $scope.deleteGCtn = function(ctn) {
            if (ctn.id) {
                GuideCtns.delete({
                    id: ctn.id
                });
            }
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            }
        }

        var onSendSiSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            if (isEmail) {
                isEmail = false;
                $scope.generateMultiAttachment(emailType);
            } else {
                layer.msg($translate.instant('global.messages.submitted'));
                // goBack();
            }
        }

        $scope.sendSi = function() {
            if (angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            if ($scope.guide.booking.isLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            $scope.isSaving = true;
            $scope.guide.booking.status = "SiSubmitted";
            $scope.guide.booking.lastModifiedTime = new Date();
            $scope.guide.booking.siReceivedTime = new Date();
            Guide.sendSi($scope.guide, onSendSiSuccess, onSendError);
        }

        $scope.previewBooking = function() {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = 10;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.booking.viewBookingTitle"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            });
        }

        $scope.previewSi = function(reportCode) {
            if(!$scope.guide.id){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = reportCode;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            });
        }

        $scope.exportBooking = function(format) {
            $scope.fileName = 'Booking';
            $scope.format = format;
            $scope.generateBooking();
        }

        $scope.generateBooking = function() {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = 10;
            guideReportDTO.fileType = $scope.format;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $scope.exportExcelOrPdf(result);
            });
        }


        $scope.exportSi = function(format) {
            $scope.fileName = "SI";
            $scope.format = format;
            $scope.generateShippment();
        }

        $scope.generateShippment = function() {
            if(!$scope.guide.id){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = 190;
            guideReportDTO.fileType = $scope.format;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $scope.exportExcelOrPdf(result);
            });
        }

        $scope.exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 2) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".pdf";
                aTag.href = blobURL;
                aTag.click();
            }
        }

        $scope.saveAsRatesTempalte = function() {
            if (!$scope.guide.booking.bookingPrices || $scope.guide.booking.bookingPrices.length == 0) {
                layer.msg($translate.instant('autopsApp.booking.home.no_prices'));
            }
            var ratesCopy = angular.copy($scope.guide.booking.bookingPrices);
            $uibModal.open({
                templateUrl: 'app/entities/guide/template-rates.html',
                controller: 'TemplateRatesController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return ratesCopy;
                    }]
                }
            }).result.then(function(result) {
                $translate.refresh();
            }, function() {});
        }


        $scope.saveAsBookingTempalte = function() {
            if (!$scope.guide.booking.id) {
                layer.msg($translate.instant('autopsApp.booking.home.save_booking_firstly'));
                return;
            }
            if ($scope.guide.booking.isTemplate) {
                layer.msg($translate.instant('autopsApp.booking.home.is_template_already'));
                return;
            }
            var bookingTemplate = {};
            bookingTemplate.createdBy = $rootScope.account.id;
            bookingTemplate.bookingId = $scope.guide.id;
            $uibModal.open({
                templateUrl: 'app/entities/bookingTemplate/bookingTemplate-dialog.html',
                controller: 'BookingTemplateDialogController',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingTemplate');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return bookingTemplate;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide.booking.isTemplate = true;
                $translate.refresh();
            }, function() {});
        }

        $scope.HBLpreview = function(reportCode) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if (!$scope.guide.booking.isLocked) {
                return;
            }
            var guideReportDTO = {};

            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                guideReportDTO = result;
                guideReportDTO.opType = 1;
                guideReportDTO.reportType = reportCode;
                guideReportDTO.fileType = 1;
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    $rootScope.PRINTBYTES(result.data);
                });
            });
        }
        $scope.totalChange = function(bookingPrice) {
            if (bookingPrice.price && bookingPrice.count) {
                bookingPrice.total = bookingPrice.price * bookingPrice.count;
            }
            $translate.refresh();

        }

        var activeTabCopy = $rootScope.BOOKINGACTIVETAB == null?0:$rootScope.BOOKINGACTIVETAB;
        $scope.activeTab = activeTabCopy;
        $scope.setActiveTab = function (index) {
            if(index == 2){
                $scope.activeTab = activeTabCopy;
            }
            else {
                activeTabCopy = index;
            }            
        }
        var onCancelSuccess = function (result) {
            loadGuide();
            layer.msg($translate.instant('global.cancel_success'));
        };
        var onCancelError = function (result) {            
            if (result.status === 409) {
                $rootScope.OPS_ALERT($translate.instant('global.lockedAlert'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            }            
        };

        $scope.cancelBooking = function () {
            var content = $translate.instant("autopsApp.booking.delete.question", {id: $scope.guide.booking.bookingNum});
            layer.confirm(content, {
                title: $translate.instant('entity.delete.cancel_title'),
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function() {
                layer.closeAll();
                Booking.delete({id: $scope.guide.booking.id}, onCancelSuccess, onCancelError);
            }, function() {
                return;
            });
        };

        $scope.showContactList = function(id) {
            if (!id || ($scope.guide.booking.id && $scope.guide.booking.status != 'new')){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return OpsColoader.getContactsList({id: id}).$promise;
                    }]
                }
            }).result.then(function(contact) {
                $scope.guide.booking.bookingToAttn = contact.attn;
                $scope.guide.booking.bookingToTel = contact.tel;
                $scope.guide.booking.bookingToFax = contact.fax;
                $scope.guide.booking.bookingToMob = contact.mob;
                $scope.guide.booking.bookingToEmail = contact.email;
                $translate.refresh();
            }, function() {});
        }

        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide, true);
        }

        $scope.updateGwVolPkgStr = function() {
            DataUtils.updateGuideCw($scope.guide);
        }

        $scope.loadUserInfo = function() {
            if (!$scope.guide.booking.bookingTrucks){
                $scope.guide.booking.bookingTrucks = [];
                $scope.guide.booking.bookingTrucks.push({});
            }
            if (!$scope.guide.booking.bookingTrucks[0]){
                $scope.guide.booking.bookingTrucks[0] = {};
            }
            if ($scope.guide.booking.bookingTrucks[0].attn){
                return;
            }          
            $scope.guide.booking.bookingTrucks[0].attn = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            $scope.guide.booking.bookingTrucks[0].attnMob = $rootScope.account.mob;
            $scope.guide.booking.bookingTrucks[0].attnTel = $rootScope.account.tel;
            $scope.guide.booking.bookingTrucks[0].attnFax = $rootScope.account.fax;
            $scope.guide.booking.bookingTrucks[0].attnEmail = $rootScope.account.email;
            $scope.guide.booking.bookingTrucks[0].loadAddress = $rootScope.account.companyAddress;
            $translate.refresh();
        }

        $scope.onShipperSelected = function(data, chooseTarget) {
            if (chooseTarget == "bookingShipper") {
                $scope.guide.booking.shipperBl = data.blShow;
            } else if (chooseTarget == "bookingCnee") {
                $scope.guide.booking.cneeBl = data.blShow;
                $scope.guide.booking.cneeId = data.choosedId;
            } else if (chooseTarget == "bookingNotify") {
                $scope.guide.booking.notifyBl = data.blShow;
            }
        }

        $scope.bookingPriceLocked = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if($scope.guide.booking.status == 'new' || $scope.guide.booking.status == 'Declined' || !$scope.guide.id){
                return false;
            }
            if ($scope.guide.submited) {
                return true;
            }
            if ($scope.guide.booking.isLocked || $scope.guide.checkStatus == "approved" || $scope.guide.checkStatus == "approved") {
                return true;
            }
            return false;
        }
        $scope.getQuotation = function() {
            if ($scope.bookingPriceLocked()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.bookingPriceLockedAlert'));
                return;
            }
            var guide = angular.copy($scope.guide);
            guide.booking.gw = guide.gw;
            guide.booking.vol = guide.vol;
            guide.booking.isNew = false;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return guide.booking;
                    }],                    
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                $scope.chooseOneCost(result);
            }, function() {

            });
        }

        $scope.clearSellingId = function(){
            if($scope.guide.booking.sellingId && $scope.guide.submited){
                $rootScope.OPS_ALERT("服务已锁定，请联系业务/客服修改!");
                return;
            }
            $scope.guide.deliveryChannel = null;
            $scope.guide.productName = null;
            $scope.guide.deliveryWay = null;
            $scope.guide.booking.divVolBy = null;
            $scope.guide.booking.sellingId = null;   
            // $scope.refreshRates();
        }

        $scope.chooseOneCost = function(result) {
            DataUtils.updateGuideByProduct($scope.guide, result, true);
            $scope.updateGwVolPkgStr();
            $scope.refreshRates();
            $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.refreshRatesTips'), {
                icon: 0, 
                title :$translate.instant('global.alert_title'),
                btn: [$translate.instant('global.ok_answer')]
            })
        }
        function getChannelCopyGuide(){
            var guideEquiry = angular.copy($scope.guide);
            guideEquiry.gw = guideEquiry.booking.gw;
            guideEquiry.vol = guideEquiry.booking.vol;
            guideEquiry.pkgNum = guideEquiry.booking.pkgNum;
            if($rootScope.HAVEAUTH('ORDER_BY_CHANNEL')){
                guideEquiry.productName = null;
            }       
            else{
                guideEquiry.deliveryChannel = null;
            }
            return guideEquiry;
        }
        $scope.autoRatesDTO = {};
        $scope.refreshRates = function() {
            if($scope.guide.submited || !$scope.guide.id){
                return;
            }
            if($scope.isFcl()){
                fclRefreshRates();
            }
            else {
                var guideEquiry = getChannelCopyGuide();
                fbaRefreshRates(guideEquiry);
                $rootScope.CHECK_GUIDE_CHANNEL(guideEquiry);
            }
        }


        function fbaRefreshRates(guideEquiry) {
            if($rootScope.HAVEAUTH('ORDER_BY_CHANNEL') && !guideEquiry.deliveryChannel){
                layer.msg($translate.instant('autopsApp.booking.channelNullAlert')); 
                return; 
            }         
            if(!$rootScope.HAVEAUTH('ORDER_BY_CHANNEL') && !guideEquiry.productName){
                layer.msg($translate.instant('autopsApp.booking.productNullAlert'));
                return; 
            }
            var loadIndex = layer.load(1, {shade: 0.3}); 
            OpsCost.guideTryCost(guideEquiry, function(result){
                $scope.autoRatesDTO.ratesList = result;
                layer.close(loadIndex);
                if(!$scope.autoRatesDTO.ratesList || $scope.autoRatesDTO.ratesList.length == 0){
                    layer.msg($translate.instant('autopsApp.opsCost.noTrialRate'));
                }
                else {
                    $scope.autoRatesDTO.ratesList.sort(function(a, b) {
                        if(a.freight < b.freight){
                            return -1;
                        }
                        return 1;
                    });
                }
                $translate.refresh();
            }, function(error){
                layer.close(loadIndex);
                $scope.autoRatesDTO = {};
                $rootScope.ALERT_ERROR(error);
                // if($scope.guide.productName){
                //     $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.invalidProduct'));
                // }
            })
        }

        $scope.onProductChoosed = function(productName, refreshRates) {
            if (!productName) {
                return;
            }
            var shipmentType = "FBA";
            if ($scope.guide.shipmentType == "FCL") {
                shipmentType = "FCL";
            } else if ($scope.guide.shipmentType == "LCL") {
                shipmentType = "LCL";
            } else if ($scope.guide.shipmentType == "AIR") {
                shipmentType = "AIR";
            }
            OpsCost.findOneByProduct({
                isSelling: true,
                productName: productName,
                shipmentType: shipmentType,
                dest: $scope.guide.booking.deliveryTo
            }, function(result) {
                if (result && result.id) {
                    DataUtils.updateGuideByProduct($scope.guide, result, false);
                    if(refreshRates){
                        $scope.refreshRates();
                    }
                    $translate.refresh();
                }
            })
        }

        function fclRefreshRates() {
            var loadIndex = layer.load(1, {shade: 0.3}); 
            OpsCost.refreshRates($scope.guide, function(result){
                layer.close(loadIndex);
                $scope.autoRatesDTO = result;
                if(!result.ratesList || result.ratesList.length == 0){
                    if($scope.guide.productName){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.invalidProduct'));
                    }
                }
                else {
                    $scope.autoRatesDTO.ratesList.sort(function(a, b) {
                        if(a.freight < b.freight){
                            return -1;
                        }
                        return 1;
                    });
                }
            }, function () {
                layer.close(loadIndex);
                $scope.autoRatesDTO = null;
                if($scope.guide.productName){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.invalidProduct'));
                }
            })
        }



        $rootScope.LOAD_REPORT_TEMPLATES();

        $scope.onCarrierSelected = function(carrier) {       
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.carrierFullName;
        }

        $scope.combineHblIntoMbl = function() {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if(!$scope.hbls){return;}
            Booking.getHbls({refGuideId: $scope.guide.id}, function(result){
                var gw = 0,//件毛体合并
                    pkgs = 0,
                    vol = 0,
                    chargeWeight = 0,
                    isSamePkg = true,
                    pkgType;
                angular.forEach(result, function(data) {
                    if (data.pkgNum) {
                        pkgs = pkgs + data.pkgNum;
                    }
                    if (data.gw) {
                        gw = gw + data.gw;
                    }
                    if (data.vol) {
                        vol = vol + data.vol;
                    }
                    if (data.chargeWeight) {
                        chargeWeight = chargeWeight + data.chargeWeight;
                    }
                    if (!pkgType && data.pkgType) {
                        pkgType = data.pkgType;
                    }
                    if (data.pkgType && pkgType != data.pkgType) {
                        isSamePkg = false;
                    }
                })
                $scope.guide.pkgNum = pkgs;
                $scope.guide.chargeWeight = chargeWeight;
                $scope.guide.gw = gw;
                $scope.guide.vol = vol;
                if (isSamePkg) {
                    $scope.guide.booking.pkgType = pkgType;
                } else {
                    $scope.guide.booking.pkgType = "PKGS";
                }
                $scope.updateGwVolPkgStr();
                $translate.refresh();
                layer.msg($translate.instant('autopsApp.booking.home.combineHblsQuestion'));
            })
        }

        $scope.getHblsCount = function() {
            if(!$scope.guide.id 
                || ($scope.guide.shipmentType != 'FCL' && $scope.guide.shipmentType != 'AIR' && $scope.guide.shipmentType != 'LCL')){
                return;
            }
            Booking.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }

        $scope.hblView = function() {
            if(!$scope.guide.id 
                || ($scope.guide.shipmentType != 'FCL' && $scope.guide.shipmentType != 'AIR' && $scope.guide.shipmentType != 'LCL')){
                return;
            }            
            Guide.getGuideForHbls({
                id: $scope.guide.id
            }, function(result) {
                var entity = {};
                entity.guide = result;
                $uibModal.open({
                    templateUrl: 'app/entities/booking/booking-dialog.html',
                    controller: 'BookingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return entity;
                        }]
                    }
                }).result.then(function(result) {
                    $scope.getHblsCount();
                }, function(result) {
                    $scope.getHblsCount();
                });
            });
        };

        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if(!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed || $scope.guide.booking.isLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {  
                    loadGuide();
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                                            
                });
        };

        $scope.excelExport = function() {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            var guides = [];
            var guide = {
                booking: $scope.guide.booking,
                id: $scope.guide.id
            };
            guides.push(guide);
            var fileName = $translate.instant('autopsApp.guide.packingList');           
            OpsFiles.exportPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    
        $scope.previewReport = function(code, truckId) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                var guideReportDTO = angular.copy($scope.guide);
                guideReportDTO.booking.goodsDiscription = guideReportDTO.cargoName;
                guideReportDTO.booking.mark = "N/M";
                guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
                guideReportDTO.reportType = code;
                guideReportDTO.opType = 1;
                guideReportDTO.fileType = 1;
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    $rootScope.PRINTBYTES(result.data);
                }, function(error) {
                });
            })
        }

        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                var guideReportDTO = angular.copy($scope.guide);
                guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
                guideReportDTO.reportType = code;
                guideReportDTO.opType = 1;
                guideReportDTO.fileType = format;
                guideReportDTO.fileName = fileName;
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    $rootScope.EXPORTDOWNLOAD(result, format, fileName);
                });
            })
        }
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.jobNum){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if (!angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.printIn = function(reportType){
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if(!$scope.guide.jobNum){
                layer.msg($translate.instant('autopsApp.guide.submitAlert'));
                return;
            }
            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;

            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        $scope.warehouses = null;
        $scope.getWarehouses = function () {
            if ($scope.warehouses){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                $scope.warehouses = data;
            });
        }
        $scope.wmsAndPols = null;
        $scope.getWmsAndPols = function () {
            if ($scope.wmsAndPols){
                return;
            }
            OpsCost.getWmsAndPols(function (data) {
                $scope.wmsAndPols = data;
            });
        }
        $scope.wmsPolSelected = function(warehouse) {
            $scope.guide.booking.porName = warehouse.name;
            if(!$scope.guide.id && hostCompanyChanged(warehouse.companyId)){
                $scope.guide.companyId = warehouse.companyId;
                $rootScope.LOAD_CONFIGURATION($scope.guide.companyId);
                $scope.guide.productName = null;
            }            
            $scope.wmsWarehouseSelected(warehouse);
        }

        var hostCompanyChanged = function(companyId) {
            if(!companyId){
                return false;
            }
            if($scope.guide.companyId && companyId != $scope.guide.companyId){
                return true;
            }
            if(!$scope.guide.companyId && companyId != $rootScope.HOST_COMPANY_ID){
                return true;
            }
            return false;
        }
        

        $scope.wmsWarehouseSelected = function(warehouse) {
            if(!warehouse.id){
                return;
            }
            if($scope.guide.jobNum && warehouse.companyId && $scope.guide.companyId != warehouse.companyId){
                $scope.guide.whName = guideCopy.whName;
                layer.msg($translate.instant('autopsApp.guide.warehouseInvalidAlert'));
                return;
            }
            $scope.guide.whName = warehouse.name;
            $scope.guide.whId = warehouse.id;
            if(!$scope.guide.id && hostCompanyChanged(warehouse.companyId)){
                $scope.guide.companyId = warehouse.companyId;
                $rootScope.LOAD_CONFIGURATION($scope.guide.companyId);
                $scope.guide.productName = null;
                // $scope.guide.medCompanyId = warehouse.medCompanyId;
                // $scope.guide.isVirtual = warehouse.isVirtual;
                // $scope.guide.medCompany = warehouse.medCompany;
                // $scope.guide.medVenderId = warehouse.medVenderId;
            }
        }

        $scope.setFdValue = function(fd, value) {
            $scope.guide[fd] = value;
            if(fd == 'ownImporter'){
                $scope.guide.booking.importerName = null;
                $scope.guide.booking.importerTaxId = null;
                $scope.guide.booking.importerEori = null;
                $scope.guide.booking.importerAddress = null;
            }
        }

        $scope.getCargo = function() {
            if (!$scope.guide.booking.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entt = {};
            entt.guide = angular.copy($scope.guide);
            entt.guide.shipperId = null;
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function() {
            });
        }

        $scope.plConfirm = function() {
            guideCopy.plConfirmed = $scope.guide.plConfirmed;
            if (!$scope.guide.booking.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            Booking.plConfirm({
                guideId: $scope.guide.id,
                plConfirmed: $scope.guide.plConfirmed
            }, function(result) {
                loadGuide();
                layer.msg($translate.instant('global.save_success'));
            }, function(error){
                $scope.isSaving = false;
                $scope.guide.plConfirmed = !$scope.guide.plConfirmed;
                if(error.status == 403){
                    layer.msg($translate.instant('autopsApp.booking.wmsNotInAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }                
            });
        }

        $scope.opsFactorys = [];
        $scope.loadOpsFactory = function() {
            if ($scope.opsFactorys.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                OpsVender.getOpsFactorys(function(result) {
                    $scope.isSaving = false;
                    $scope.opsFactorys = result;             
                });
            };
        };
        $scope._factoryOnSelected = function(data, truck) {
            truck.factoryName = data.companyName;
            truck.loadAddress = data.address;
            truck.attn = data.attn;
            truck.attnTel = data.tel;
            truck.attnFax = data.fax;
            truck.attnMob = data.mob;
            truck.attnEmail = data.email;
        }

        $scope.deleteGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.delete.question', {
                id: $scope.guide.jobNum
            }), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                Guide.delete({
                    id: $scope.guide.id
                }, function() {
                    layer.close(index);
                    layer.msg($translate.instant('global.delete_success'));
                    $scope.$emit('autopsApp:loadAllRequred');                    
                    goBack();
                });
            }, function() {});
        }

        var updateUnsubscribe = $rootScope.$on('autopsApp:isRemoteConfirmed', function(event, result) {
            if(!result){
                $scope.guide.isRemote = false;
            }
            if(result.postcode == $scope.guide.shiptoPostcode && result.country == $scope.guide.destCountryCode ){
                $scope.guide.isRemote = true;
            }
        });
        $scope.$on('$destroy', updateUnsubscribe);

        $scope.truckRequiredChange = function() {
            if($scope.guide.truckRequired && !$scope.guide.booking.bookingTrucks){
                $scope.guide.booking.bookingTrucks = [{}];
            }
        }
        $scope.customRequiredChange = function() {
            if($scope.guide.customRequired && !$scope.guide.bookingCustoms){
                $scope.guide.bookingCustoms = [{}];
            }
        }

        $scope.tips = $translate.instant('global.blueNullAlert');
        $scope.setTips = function(msg) {
            if(!msg){
                $scope.tips = $translate.instant('global.blueNullAlert');
            }
            else {
                $scope.tips = msg;
            }
        }
        $scope.updatePrice = function(bookingCtn) {
            if (bookingCtn.pieces && bookingCtn.unitPrice) {
                bookingCtn.totalPrice = DataUtils.round(bookingCtn.pieces * bookingCtn.unitPrice, 2);
                $scope.updateDuty(bookingCtn);
                $rootScope.updateCargoValue($scope.guide);
            }
            var pieces = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(bcn){
                if(bcn.pieces){
                    pieces+=bcn.pieces;
                }
            })
            $scope.guide.pieces = pieces;
        };
        $scope.updateDuty = function(bookingCtn) {
            bookingCtn.dutyAmt = DataUtils.getDuty(bookingCtn).dutyAmt;
            $scope.mixPlChange(bookingCtn);
        };

        $scope.mixPlChange = function(bookingCtn) {
            if(!bookingCtn.mixedPls || bookingCtn.mixedPls.length == 0){
                return;
            }
            var gwTtl = 0, volTtl = 0, dutyAmt = 0, pieces = 0, totalPrice = 0;
            angular.forEach(bookingCtn.mixedPls, function(data){
                if(data.gwTtl){
                    gwTtl += data.gwTtl;
                }
                if(data.volTtl){
                    volTtl += data.volTtl;
                }
                if(data.dutyAmt){
                    dutyAmt += data.dutyAmt;
                }
                if(data.pieces){
                    pieces += data.pieces;
                }      
                if(data.totalPrice){
                    totalPrice += data.totalPrice;
                }                           
            })
            bookingCtn.gwTtl = DataUtils.round(gwTtl, 2);;
            if(bookingCtn.pkgNum){
                bookingCtn.gw = DataUtils.round(gwTtl/bookingCtn.pkgNum, 3);
            }
            bookingCtn.volTtl = volTtl;
            if(bookingCtn.pkgNum){
                bookingCtn.vol = DataUtils.round(volTtl/bookingCtn.pkgNum, 5);
            }
            bookingCtn.dutyAmt = dutyAmt;
            bookingCtn.pieces = pieces;
            bookingCtn.totalPrice = totalPrice;
        };     

        $scope.addFclCtn = function() {
            if(!$scope.guide.guideFclCtns){
                $scope.guide.guideFclCtns = [];
            }
            $scope.guide.guideFclCtns.push({});
            $translate.refresh();
        };

        $scope.updateByFclCtn = function() {
            DataUtils.updateByFclCtn($scope.guide);
        }

        $scope.deleteFclCtn = function(fclCtn) {
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (fclCtn.id && $scope.guide.id) {
                var loadIndex = layer.load(1, {shade: 0.3});
                GuideFclCtn.delete({
                    id: fclCtn.id
                }, function(){
                    layer.close(loadIndex);  
                    loadGuide();
                }, function(result){
                    layer.close(loadIndex);  
                    layer.msg($translate.instant('global.delete_fail'));
                });
            }
            else {
                var index = $scope.guide.guideFclCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.guideFclCtns.splice(index, 1);
                };
            }
        };

        $scope.updateVol = function(bookingCtn) {
            if (bookingCtn.length && bookingCtn.width && bookingCtn.height) {
                bookingCtn.width = bookingCtn.width.toFixed(4) - 0;
                bookingCtn.length = bookingCtn.length.toFixed(4) - 0;
                bookingCtn.height = bookingCtn.height.toFixed(4) - 0;
                bookingCtn.vol = bookingCtn.length * bookingCtn.width * bookingCtn.height / 1000000;
                bookingCtn.vol = DataUtils.round(bookingCtn.vol, 5);
                $scope.volChange(bookingCtn);
            }
        };
        $scope.volChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.vol){
                bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                $scope.updateVolGw();
            }
        };
        $scope.gwChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gw){
                bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 2);
                $scope.updateVolGw();
            }
        };
        function getCargpPic() {
            if(!$scope.guide.booking.bookingCtns || $scope.guide.booking.bookingCtns.length == 0){
                $scope.addCtn();
                guideCopy = angular.copy($scope.guide);
                return;
            }
            angular.forEach($scope.guide.booking.bookingCtns, function(bcn){
                if(bcn.id){
                    var filePath = "/" + "bookingCtns/" + bcn.id + "/cargoPic/";
                    OpsFiles.getFilesList({
                        filepath: filePath,
                        companyId: $scope.guide.companyId
                    }, function(data) {
                        bcn.cargoPics = data;
                        guideCopy = angular.copy($scope.guide);
                    });
                    if(bcn.mixedPls && bcn.mixedPls.length > 0){
                        angular.forEach(bcn.mixedPls, function(mixPl){
                            var filePath = "/" + "mixedPl/" + mixPl.id + "/cargoPic/";
                            OpsFiles.getFilesList({
                                filepath: filePath,
                                companyId: $scope.guide.companyId
                            }, function(data) {
                                mixPl.cargoPics = data;
                                guideCopy = angular.copy($scope.guide);
                            });
                        })
                    }
                }
            })            
        }
        $scope.selectChannelAlert = function() {
            if(!$scope.guide.destCountryCode || !$scope.guide.shiptoPostcode || !$scope.guide.gw){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.selectChannelAlert'));
            }
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:guideUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }   
        });
        $scope.$on('$destroy', unsubscribeUpdateByChannel);

        $scope.isMixedPl = function() {
            var isMixedPl = false;
            if($scope.guide && $scope.guide.booking && $scope.guide.booking.bookingCtns && $scope.guide.booking.bookingCtns.length){
                angular.forEach($scope.guide.booking.bookingCtns, function(data){
                    if(data.mixedPls && data.mixedPls.length){
                        isMixedPl = true;
                    }
                })
            }
            return isMixedPl;
        }


    }
})();