(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCarrierDetailController', OpsCarrierDetailController);

    OpsCarrierDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', '$translate', '$uibModal', '$location',
        'entity', 'OpsCarrier', 'uiGridConstants', 'WmsWarehouse', 'JsExpression', 'AddressBook', 'ExpressParcel', 'PublishRate', 'DataUtils'
    ];

    function OpsCarrierDetailController($scope, $rootScope, $timeout, $uibModalInstance, $translate, $uibModal, $location,
        entity, OpsCarrier, uiGridConstants, WmsWarehouse, JsExpression, AddressBook, ExpressParcel, PublishRate, DataUtils) {
        var vm = this;
        vm.opsCarrier = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addZones = addZones;
        vm.saveZone = saveZone;
        vm.discountMgmt = discountMgmt;
        vm.acceptableCargos = [];
        vm.getWarehouses = getWarehouses;
        vm.excelTxt = null;
        vm.delAll = delAll;
        vm.loadPublishRateList = loadPublishRateList;
        vm.editPublishRate = editPublishRate;
        vm.delPublishRate = delPublishRate;
        vm.addPublishRate = addPublishRate;

        function discountMgmt() {
            var requestBody = {
                chargeChannelId: vm.opsCarrier.id,
                chargeChannel: vm.opsCarrier.channel
            };
            $uibModal.open({
                templateUrl: 'app/entities/account-auth/account-auth-dialog.html',
                controller: 'AccountAuthDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return requestBody;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountAuth');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        function addZones() {
            if (!vm.excelTxt) {
                return;
            }
            var rows = vm.excelTxt.split("\n");
            var newZones = [];
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i]) {
                    continue;
                }
                var cols = rows[i].split("\t");
                var newZone = {
                    companyId: vm.opsCarrier.companyId,
                    channelId: vm.opsCarrier.id
                }
                if (cols.length > 0) {
                    newZone.zoneName = cols[0];
                }
                if (cols.length > 1) {
                    newZone.destName = cols[1];
                }
                if (cols.length > 2) {
                    newZone.conuntryCode = cols[2];
                }
                if (cols.length > 3) {
                    newZone.zip = cols[3];
                }
                if (cols.length > 4) {
                    newZone.zipEnd = cols[4];
                }
                if (cols.length > 5) {
                    newZone.whZip = cols[5];
                }
                if (cols.length > 6 && !isNaN(cols[6])) {
                    newZone.expectedTt = cols[6];
                }
                newZones.push(newZone);
            }
            var msg = checkZones(newZones);
            if (!msg) {
                if (!$scope.gridOptions.data) {
                    $scope.gridOptions.data = [];
                }
                angular.forEach(newZones, function(zone) {
                    if (isNewZone(zone)) {
                        $scope.gridOptions.data.splice(0, 0, zone);
                    }
                })
                vm.excelTxt = null;
            } else {
                $rootScope.OPS_ALERT(msg);
            }
        }

        function isNewZone(zone) {
            for (var i = 0; i < $scope.gridOptions.data.length; i++) {
                if ($scope.gridOptions.data[i].zoneName == zone.zoneName &&
                    $scope.gridOptions.data[i].destName == zone.destName &&
                    $scope.gridOptions.data[i].conuntryCode == zone.conuntryCode &&
                    $scope.gridOptions.data[i].zip == zone.zip &&
                    $scope.gridOptions.data[i].zipEnd == zone.zipEnd &&
                    $scope.gridOptions.data[i].whZip == zone.whZip) {
                    return false;
                }
            }
            return true;
        }

        function checkZones(zones) {
            for (var i = 0; i < zones.length; i++) {
                if (!zones[i].zoneName) {
                    return "区域名称(第一列)不能为空！";
                }
                if (zones[i].conuntryCode && zones[i].conuntryCode.length != 2) {
                    return zones[i].conuntryCode + ": 国家代码(第三列)应为2字代码！";
                }
            }
            return null;
        }

        function saveZone() {
            var loadIndex = layer.load(1, { shade: 0.3 });
            vm.isSaving = true;
            OpsCarrier.saveAll($scope.gridOptions.data, function(results) {
                vm.isSaving = false;
                $scope.gridOptions.data = results;
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                layer.close(loadIndex);
                layer.msg($translate.instant('global.save_success'));
            }, function(error) {
                vm.isSaving = false;
                layer.close(loadIndex);
                layer.msg($translate.instant('global.save_fail'));
            })
        }

        $scope.saveOne = function(zone) {
            vm.isSaving = true;
            var zones = [];
            zones.push(zone);
            OpsCarrier.saveAll(zones, function(results) {
                vm.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, function(error) {
                vm.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            })
        }

        function delAll(unsavedOnly) {
            if (unsavedOnly) {
                doDelAll(unsavedOnly);
                return;
            }
            layer.confirm($translate.instant('global.delAllAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                doDelAll(unsavedOnly);
            }, function() {});
        }

        function doDelAll(unsavedOnly) {
            for (var i = $scope.gridOptions.data.length - 1; i >= 0; i--) {
                if (!$scope.gridOptions.data[i].id) {
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            if (unsavedOnly) {
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                return;
            }
            OpsCarrier.delAll($scope.gridOptions.data, function(argument) {
                $scope.gridOptions.data = [];
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                layer.msg($translate.instant('global.delete_success'));
            })
        }

        $scope.activeTab = 0;
        $scope.loadZonedList = function() {
            if (!vm.opsCarrier.id) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.activeTab = 0;
                return;
            }
            OpsCarrier.getServiceZonesByChannel({ channelId: vm.opsCarrier.id }, function(results) {
                $scope.gridOptions.data = results;
                if (copyId) {
                    angular.forEach($scope.gridOptions.data, function(data) {
                        data.id = null;
                        data.channelId = null;
                    })
                }
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            })
        }

        vm.publishRates = [];
        function loadPublishRateList(type) {
            if (!vm.opsCarrier.id) {
                layer.msg($translate.instant('global.saveAlert'));
                $scope.activeTab = 0;
                return;
            }
            if(type == "settlement" && !vm.opsCarrier.assistCharge){
                layer.msg("结算计费未启用!");
                $scope.activeTab = 4;
                return;
            }
            if(type == "cost" && !vm.opsCarrier.costCharge){
                layer.msg("成本计费未启用!");
                $scope.activeTab = 4;
                return;
            }
            PublishRate.loadPublishRateList({
                channelId: vm.opsCarrier.id,
                type: type
            }, function(results) {
                vm.publishRates = results;
                angular.forEach(vm.publishRates, function(value, key){
                    value.venderTypeLabel = DataUtils.getLabelFromKvList(value.venderType, $rootScope.CLIENT_TYPES);
                });
            })
        }

        $scope.delZone = function(zone) {
            if (!zone.id) {
                var idx = $scope.gridOptions.data.indexOf(zone);
                if (idx !== -1) {
                    $scope.gridOptions.data.splice(idx, 1);
                }
            } else {
                var delRows = [];
                delRows.push(zone);
                OpsCarrier.delAll(delRows, function(argument) {
                    var idx = $scope.gridOptions.data.indexOf(zone);
                    if (idx !== -1) {
                        $scope.gridOptions.data.splice(idx, 1);
                    }
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                    layer.msg($translate.instant('global.delete_success'));
                })
            }
        }


        $scope.gridOptions = {
            data: [],
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('global.menu.entities.zoneMgmt') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: false,

            minimumColumnSize: 70,
            columnDefs: [{
                    field: ' ',
                    width: 70,
                    displayName: " ",
                    pinnedLeft: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableCellEdit: false,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><button type="button"ng-click="grid.appScope.delZone(row.entity)"class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-trash"></span></button><button type="button"ng-click="grid.appScope.saveOne(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-floppy-disk"></span></button></div>'
                },
                {
                    field: 'zoneName',
                    displayName: $translate.instant('autopsApp.opsCarrier.zoneName')
                },
                {
                    field: 'destName',
                    displayName: $translate.instant('autopsApp.opsCarrier.destName')
                },
                {
                    field: 'conuntryCode',
                    displayName: $translate.instant('autopsApp.opsCarrier.conuntryCode')
                },
                {
                    field: 'zip',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCarrier.zip')
                },
                {
                    field: 'zipEnd',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCarrier.zipEnd')
                },
                {
                    field: 'whZip',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCarrier.whZip')
                },
                {
                    field: 'expectedTt',
                    type: 'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.expectedTt')
                },
                {
                    field: 'minWeightOrder',
                    type: 'number',
                    displayName: $translate.instant('autopsApp.opsCarrier.minWeightOrder')
                }
            ],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        }
        var copyId = null;
        var jsExpressions = null;
        $timeout(function() {
            if (vm.opsCarrier && vm.opsCarrier.acceptableCargo) {
                vm.acceptableCargos = vm.opsCarrier.acceptableCargo.split(" ");
            }
            if ($location.path().indexOf("copy") >= 0) {
                copyId = vm.opsCarrier.id;
                $scope.loadZonedList();
                vm.opsCarrier.id = null;
                vm.opsCarrier.effectiveDate = null;
                vm.opsCarrier.expireDate = null;
                JsExpression.getJsExpressionsByTypeId({
                    id: copyId,
                    type: "channel"
                }, function(resultList) {
                    jsExpressions = resultList;
                    angular.forEach(jsExpressions, function(data) {
                        data.id = null;
                        data.channelId = null;
                    })
                })
            }
        });
        $scope.cargoTypeChange = function(type) {
            if (vm.acceptableCargos.indexOf(type) == -1) {
                vm.acceptableCargos.push(type);
            }
        }
        $scope.removeCargoType = function(idx) {
            vm.acceptableCargos.splice(idx, 1);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (!vm.opsCarrier.effectiveDate && vm.opsCarrier.expireDate) {
                layer.msg("请输入生效日期");
                return;
            }

            if (!vm.opsCarrier.warehouseName) {
                vm.opsCarrier.warehouseId = null;
            }

            if (vm.acceptableCargos.length > 0) {
                vm.opsCarrier.acceptableCargo = "";
                angular.forEach(vm.acceptableCargos, function(type) {
                    vm.opsCarrier.acceptableCargo += type + " ";
                })
            }
            vm.isSaving = true;
            if (vm.opsCarrier.id !== null) {
                OpsCarrier.update(vm.opsCarrier, onSaveSuccess, onSaveError);
            } else {
                OpsCarrier.save(vm.opsCarrier, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.opsCarrier = result;
            $scope.$emit('autopsApp:opsCarrierUpdate', result);
            layer.msg($translate.instant('global.save_success'));
            // $uibModalInstance.close(result);
            if (copyId) {
                copyId = null;
                if ($scope.gridOptions.data.length > 0) {
                    angular.forEach($scope.gridOptions.data, function(data) {
                        data.channelId = result.id;
                    })
                    saveZone();
                }
                if (jsExpressions && jsExpressions.length > 0) {
                    angular.forEach(jsExpressions, function(data) {
                        data.channelId = result.id;
                        JsExpression.save(data);
                    });
                }
            }
            $translate.refresh();
        }
        function onSaveError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        $scope.onCarrierSelected = function(carrier) {
            vm.opsCarrier.code = carrier.code;
        }
        vm.msg = null;
        $scope.setMsg = function(msg) {
            vm.msg = msg;
        }

        $scope.opFilter = function(item) {
            return item.authorities.indexOf("ROLE_OPERATOR") !== -1;
        };
        $scope.docUnitChange = function() {
            if (vm.opsCarrier.chargeType == 'cbm') {
                if (vm.opsCarrier.docUnit && vm.opsCarrier.docUnit < 0.001) {
                    vm.opsCarrier.docUnit = 0.001;
                }
            } else if (vm.opsCarrier.docUnit && vm.opsCarrier.docUnit < 1) {
                vm.opsCarrier.docUnit = 1;
            }
        }
        $scope.chargeTypeChange = function() {
            vm.opsCarrier.volCoe = null;
            vm.opsCarrier.docUnit = null;
            vm.opsCarrier.maxDocWeight = null;
            vm.opsCarrier.weightCarry = null;
            vm.opsCarrier.maxWeight = null;
            vm.opsCarrier.minWeight = null;
            vm.opsCarrier.minWeightOrder = null;
        }


        vm.warehouses = null;

        function getWarehouses() {
            if (vm.warehouses) {
                return;
            }
            if (!$rootScope.account.companyId) {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true
                }, function(data) {
                    vm.warehouses = data;
                });
            } else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function(data) {
                    vm.warehouses = data;
                });
            }
        }

        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;
            }
            if ($scope.bindedAccounts.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    vm.isSaving = false;
                });
            };
        };



        $scope.serviceTypes = null;
        $scope.getParcelServices = function() {
            if (!vm.opsCarrier.addressCode || ($scope.serviceTypes && $scope.serviceTypes.length)) {
                return;
            }
            ExpressParcel.getServicesList({ labelChannelId: vm.opsCarrier.addressCode }, function(results) {
                $scope.serviceTypes = results;
            })
        }
        $scope.accountChoosed = function(item) {
            vm.opsCarrier.addressCode = item.code;
            vm.opsCarrier.serviceType=null;
            vm.opsCarrier.serviceDescription=null;
            $scope.serviceTypes = null
        }


        function addPublishRate(type){
            var newPublishRate = {
                companyId: vm.opsCarrier.companyId,
                channelId: vm.opsCarrier.id,
                channelName: vm.opsCarrier.channel,
                type: type
            }
            editPublishRate(newPublishRate);
        }

        function editPublishRate(publishRate){
            $uibModal.open({
                templateUrl: 'app/entities/publish-rate/publish-rate-dialog.html',
                controller: 'PublishRateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return publishRate;
                    }]
                }
            }).result.then(function() {
                loadPublishRateList(publishRate.type)
            }, function() {
                loadPublishRateList(publishRate.type)
            });
        }
        function delPublishRate(publishRate){
            $uibModal.open({
                templateUrl: 'app/entities/publish-rate/publish-rate-delete-dialog.html',
                controller: 'PublishRateDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return publishRate;
                    }]
                }
            }).result.then(function() {
                loadPublishRateList(publishRate.type);
            }, function() {
                loadPublishRateList(publishRate.type)
            });
        }






    }
})();
