(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountPayment', AccountPayment);

    AccountPayment.$inject = ['$resource', 'DateUtils'];
    
    function AccountPayment($resource, DateUtils) {
        return $resource('api/accountPayments/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountPayment.deadLine = DateUtils.convertLocalDateToServer(data.accountPayment.deadLine);
                    data.accountPayment.economicDate = DateUtils.convertLocalDateToServer(data.accountPayment.economicDate);
                    data.accountPayment.accountDate = DateUtils.convertLocalDateToServer(data.accountPayment.accountDate);
                    return angular.toJson(data);
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);
                    return data;
                }
            },
            'getByVoucherId': {
                method: 'GET',
                url:'api/getAccountPaymentByVoucherId/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    return data;
                }
            },
            'getByDnNum': {
                method: 'GET',
                url:'api/getAccountPaymentByDnNum',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    data.deadLine = DateUtils.convertLocalDateFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateFromServer(data.accountDate);
                    return data;
                }
            },
            'setAudited': {
                method: 'GET',
                url:'api/accountPayments/setAudited',
                transformResponse: function (data) {
                    if(!data){return;}
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    return data;
                }
            },
            'setAlertTag': {
                method: 'GET',
                url:'api/accountPayments/setAlertTag',
                transformResponse: function (data) {
                    if(!data){return;}
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    return data;
                }
            },
            'setApprove': {
                method: 'GET',
                url:'api/accountPayments/setApprove',
                transformResponse: function (data) {
                    if(!data){return;}
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    return data;
                }
            },
            'getByDnNumOrVender': {
                method: 'GET',
                url:'api/accountPayments/getAccountPaymentByDnNumOrVender',
                isArray: true
            },
            'getUnpaidListTwo': {
                method: 'GET',
                url:'api/accountPayments/getUnpaidListTwo'
            },
            'changeBillType': {
                method: 'GET',
                url:'api/accountPayments/changeBillType'
            },
            'submitApplicationBatch': {
                method: 'POST',
                url:'api/accountPayments/submitApplicationBatch',
                isArray: true
            },  
            'setApproveBatch': {
                method: 'POST',
                url:'api/accountPayments/setApproveBatch',
                isArray: true
            },  
            'setAuditedBatch': {
                method: 'POST',
                url:'api/accountPayments/setAuditedBatch',
                isArray: true
            },  
            'setManual': {
                method: 'GET',
                url:'api/accountPayments/setManual'
            },            
            'getFilterPage': {
                method: 'POST',
                url:'api/accountPayments/getFilterPage',
                isArray: true
            },    
            'searchByDnNum': {
                method: 'POST',
                url:'api/accountPayments/searchByDnNum',
                isArray: true
            },
            'searchByDnNumForPrint': {
                method: 'GET',
                url:'api/accountPayments/searchByDnNumForPrint',
                isArray: true
            },            
            'getForInvoiceBatch': {
                method: 'GET',
                url:'api/accountPayments/getForInvoiceBatch',
                isArray: true
            },
            'getAllByInvoice': {
                method:'GET',
                url:'api/accountPayment/getAllByInvoice',
                isArray:true
            },  
            'updateWithoutRates': {
                method:'PUT',
                url:'api/accountPaymentsWithoutRates'
            },
            'getGuides': {
                method:'PUT',
                url:'api/accountPayments/getGuides',
                isArray:true
            },
            'agingAnalysisNew': {
                method: 'PUT',
                url:'api/accountPayment/agingAnalysisNew',
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.cutDate = DateUtils.convertLocalDateToServer(data.cutDate);
                    data.backToDate = DateUtils.convertLocalDateToServer(data.backToDate);
                    return angular.toJson(data);
                }
            },
            'agingAnalysis': {
                method: 'PUT',
                url:'api/accountPayment/agingAnalysis',
                isArray: false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.cutDate = DateUtils.convertLocalDateToServer(data.cutDate);
                    data.backToDate = DateUtils.convertLocalDateToServer(data.backToDate);
                    return angular.toJson(data);
                }
            },
            'updateInvoiceNum': {
                method: 'PUT',
                url:'api/accountPayments/updateInvoiceNum'
            },
            'getUnpaidList': {
                method: 'GET',
                url:'api/accountPayment/getUnpaidList',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for (var i = 0; i < data.length; i++){
                        data[i].createdTime = DateUtils.convertDateTimeFromServer(data[i].createdTime);
                        data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                        data.approvalTime = DateUtils.convertDateTimeFromServer(data.approvalTime);
                    }
                    
                    return data;
                }
            },       
            'advanceSearchRequests': {
                method:'PUT',
                url:'api/accountPayment/advanceSearchRequests',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.economicDateFM = DateUtils.convertLocalDateToServer(data.economicDateFM);
                    data.economicDateTO = DateUtils.convertLocalDateToServer(data.economicDateTO);
                    data.deadLineFM = DateUtils.convertLocalDateToServer(data.deadLineFM);
                    data.deadLineTO = DateUtils.convertLocalDateToServer(data.deadLineTO);
                    data.accountDateFM = DateUtils.convertLocalDateToServer(data.accountDateFM);
                    data.accountDateTO = DateUtils.convertLocalDateToServer(data.accountDateTO);                    
                    return angular.toJson(data);
                },
            },
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    data.deadLine = DateUtils.convertLocalDateToServer(data.deadLine);
                    data.economicDate = DateUtils.convertLocalDateToServer(data.economicDate);
                    data.accountDate = DateUtils.convertLocalDateToServer(data.accountDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.deadLine = DateUtils.convertDateTimeFromServer(data.deadLine);
                    data.economicDate = DateUtils.convertDateTimeFromServer(data.economicDate);
                    data.accountDate = DateUtils.convertDateTimeFromServer(data.accountDate);                    
                    return data;
                } 
            }
        });
    }
})();
