(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoHeadDetailController', WoHeadDetailController);

    WoHeadDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModal', '$uibModalInstance',
    'entity', 'WoHead', 'WinAES', 'WoBody'];

    function WoHeadDetailController($scope, $rootScope, $timeout, $translate, $uibModal, $uibModalInstance,
        entity, WoHead, WinAES, WoBody) {

        var vm = this;
        vm.clear = clear;
        vm.woHead = entity;
        vm.addWoBody = addWoBody;
        vm.changeType = changeType;
        vm.loadWo = loadWo;
        vm.closeWo = closeWo;
        vm.openWo = openWo;
        vm.technicalSupport = technicalSupport;
        vm.knowledgeBase = knowledgeBase;
        vm.fastReply = fastReply;
        vm.updateInternalRemarks = updateInternalRemarks;
        vm.updateWoTitle = updateWoTitle;
        vm.setServiceRating = setServiceRating;
        vm.setSubmitterRating = setSubmitterRating;
        vm.urgentTag = urgentTag;
        vm.auditRequired = auditRequired;
        vm.endWo = endWo;
        vm.evaluationSubmit = evaluationSubmit;
        vm.delWoBody = delWoBody;

        $rootScope.CURRENT_WO_ID = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
            vm.woHead = {};
        }

        $timeout(function (){
            loadPics();
        });
        function loadWo(){
            WoHead.get({id : vm.woHead.id}, function(result){
                layer.msg($translate.instant("entity.action.loadSuccess"));
                vm.woHead = result;
                loadPics();
            });
        }

        function loadPics(){
            vm.woHead.encodeId = WinAES.encode(vm.woHead.id.toString());
            angular.forEach(vm.woHead.woBodys, function(data){
                data.files = [];
                $rootScope.LOADFILES(data.files, '/woBody/' + data.id + '/', vm.woHead.companyId);
            });
        }

        vm.woBody = {};
        vm.isSaving = false;

        function addWoBody(){
            vm.isSaving = true;
            vm.woBody.headId = vm.woHead.id;
            vm.woBody.userName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.woBody.time = new Date();
            vm.woBody.cusReply = $rootScope.account.companyId == 1;
            WoHead.addWoBody(vm.woBody, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (result) {
            vm.woBody.content = null;
            vm.isSaving = false;
            angular.forEach(result.woBodys, function(newOne){
                newOne.files = [];
                angular.forEach(vm.woHead.woBodys, function(oldOne){
                    if(newOne.id == oldOne.id){
                        newOne.files = oldOne.files;
                    }
                })
            })
            vm.woHead = result;
            vm.woHead.encodeId = WinAES.encode(vm.woHead.id.toString());
            layer.msg($translate.instant('global.messages.submitted'));
        }
        function changeType(type){
            if(vm.woHead.endTime){
                $rootScope.OPS_ALERT("工单已关闭");
                return;
            }
            if(type == vm.woHead.type || $rootScope.account.companyId != 1){
                return;
            }
            vm.isSaving = true;
            WoHead.changeType({
                id: vm.woHead.id,
                type: type
            }, onSaveSuccess, onSaveError);
        }
        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        function closeWo () {
            if(vm.woHead.endTime && (vm.woHead.serviceRating || $rootScope.account.companyId == 1)){
                $rootScope.OPS_ALERT("工单已关闭");
                return;
            }
            if(!vm.woHead.endTime && $rootScope.account.companyId == 1){
                WoHead.closeWoByCus({
                    id: vm.woHead.id
                }, onSaveSuccess, onSaveError);
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wo-body/wo-body-dialog.html',
                controller: 'WoBodyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return vm.woHead;
                    }]
                }
            }).result.then(onSaveSuccess);
        }
        function openWo () {
            if($rootScope.account.companyId !== 1 && vm.woHead.endTime){
                $rootScope.OPS_ALERT("工单已关闭");
                return;
            }
            WoHead.openWo({
                id: vm.woHead.id
            }, onSaveSuccess, onSaveError);
        }

        function setServiceRating (serviceRating) {
            WoHead.setServiceRating({
                id: vm.woHead.id,
                serviceRating: serviceRating
            }, function(){
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        function setSubmitterRating (submitterRating) {
            WoHead.setSubmitterRating({
                id: vm.woHead.id,
                submitterRating: submitterRating
            }, function(){
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }

        function technicalSupport () {
            if($rootScope.account.id == 1987){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            WoHead.technicalSupport({
                id: vm.woHead.id
            }, function(result){
                vm.woHead.technicalSupport = result.technicalSupport;
                vm.woHead.ldSubmitter = result.ldSubmitter;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }
        function auditRequired () {
            if($rootScope.account.id != 9339 && $rootScope.account.id != 5){
                return;
            }
            WoHead.auditRequired({
                id: vm.woHead.id
            }, function(result){
                vm.woHead.auditRequired = result.auditRequired;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        function knowledgeBase () {
            if($rootScope.account.id != 5){
                return;
            }
            WoHead.knowledgeBase({
                id: vm.woHead.id
            }, function(result){
                vm.woHead.knowledgeBase = result.knowledgeBase;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        function urgentTag () {
            if($rootScope.account.companyId != 1){
                return;
            }
            WoHead.urgentTag({
                id: vm.woHead.id
            }, function(result){
                vm.woHead.urgentTag = result.urgentTag;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        vm.fastReplyTemplate = {
            advice: "感谢您的宝贵建议！研发会按照建议功能的通用性（建议数量）排期，顺序安排实现（通用的系统优化建议都会尽量安排实现，但是建议类没有实现承诺和预计完成时间，是否实现以及实现顺序取决于通用性，也就是建议的通用性越强，建议数越多，会越快安排实现。）。",
            customizedBegin: "定制需求转研发",
            customized: "功能定制已完成，请安排相关同事测试，有问题反馈给我们处理，谢谢！",
            problemRecurrence: "请截图说明，系统什么位置进行什么操作，导致的问题出现。",
            logRequired: "请提供问题订单的修改日志",
            freeApi: "系统已对接API，如贵司可直接使用，则免费开通给贵司使用。\n如需调整才能使用，根据调整内容，收取不低于RMB1000调试费用",
            customizationIns: "*****功能定制确认前，请将定制功能的所有需求，在本工单上完整描述说明。功能定制开始后，未在工单描述说明的内容均不在定制范围内（包含但不限于通过微信/QQ/客服群/电话/会议沟通的内容）。\n*****定制内容仅以本工单描述为准。通过其它渠道沟通并确认的内容，也都需要体现在工单上备查，防止误解和忘记。\n*****后期增加或修改需求，均需要重新评估，会产生新的定制费用。"
        }


        function fastReply (type) {
            WoHead.fastReply({
                id: vm.woHead.id,
                msg: vm.fastReplyTemplate[type]
            }, onSaveSuccess, onSaveError);
        }

        function updateInternalRemarks () {
            WoHead.updateInternalRemarks({
                id: vm.woHead.id,
                internalRemarks: vm.woHead.internalRemarks
            }, function(){
                layer.msg($translate.instant('global.save_success'));
            }, onSaveError);
        }
        function updateWoTitle () {
            WoHead.updateWoTitle({
                id: vm.woHead.id,
                woTitle: vm.woHead.woTitle
            }, function(){
                layer.msg($translate.instant('global.save_success'));
            }, onSaveError);
        }
        $scope.picFilter = function(file){
            return $rootScope.IS_PIC_FILE(file.filename);
        }
        $(document).keydown(function(event){
            if(event.keyCode == 27){
                layer.closeAll();
            }
        });

        function getCopyPicWoId () {
            if(!vm.woHead.woBodys || vm.woHead.woBodys.length == 0){
                return null;
            }
            for(var i = vm.woHead.woBodys.length - 1; i >= 0; i--){
                if(vm.woHead.woBodys[i].cusReply && $rootScope.account.companyId == 1){
                    return i;
                }
                if(!vm.woHead.woBodys[i].cusReply && $rootScope.account.companyId != 1){
                    return i;
                }
            }
            return null;

        }

        document.addEventListener('paste', function (event) {
            if(!$rootScope.CURRENT_WO_ID || $rootScope.CURRENT_WO_ID != vm.woHead.id || document.hidden){
                return;
            }
            // console.log(event);
            if (event.clipboardData || event.originalEvent) {
                var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
                if(clipboardData.items && clipboardData.items.length == 1){
                    var  items = clipboardData.items,
                        len = items.length,
                        blob = null;
                    for (var i = 0; i < len; i++) {
                        // console.log(items[i]);
                        if (items[i].type.indexOf("image") !== -1) {
                            var picIdx = getCopyPicWoId();
                            if(null == picIdx){
                                layer.msg("请先发布消息，再上传文件！");
                                return;
                            }
                            //getAsFile() 此方法只是living standard firefox ie11 并不支持
                            blob = items[i].getAsFile();
                            $rootScope.UPLOADFILE(blob, '/woBody/' + vm.woHead.woBodys[picIdx].id + '/',
                                vm.woHead.woBodys[picIdx].files, vm.woHead.companyId);
                        }
                    }
                }
            }
        })
        window.onblur = function(e){
            $rootScope.CURRENT_WO_ID = null;
        }
        // window.onfocus = function(e){
        //     $rootScope.CURRENT_WO_ID = vm.woHead.id;
        // }
        $scope.onblur = function(e){
            $rootScope.CURRENT_WO_ID = null;
        }
        $scope.onfocus = function(e){
            $rootScope.CURRENT_WO_ID = vm.woHead.id;
        }
        $scope.onClerkSelected = function(item, fd) {
            vm.woHead[fd] = item.id;
            $scope.save();
        };
        function endWo() {
            // vm.woHead.endTag = !vm.woHead.endTag;
            if(vm.woHead.endTag){
                vm.woHead.endTagUser = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            $scope.save();
        };
        $scope.save = function() {
            WoHead.update(vm.woHead, onSaveSuccess, onSaveError);
        };

        function evaluationSubmit () {
            vm.isSaving = true;
            var content = "问题解决: " + (vm.woBody.solved?"已解决":"未解决");
            if(vm.woBody.serviceRating){
                content += "\n服务评级: " + vm.woBody.serviceRating + "星";
            }
            if(vm.woBody.comments){
                content += "\n评价内容: " + vm.woBody.comments;
            }
            vm.isSaving = true;
            vm.woBody.content = content;
            vm.woBody.headId = vm.woHead.id;
            vm.woBody.userName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.woBody.time = new Date();
            vm.woBody.cusReply = $rootScope.account.companyId == 1;
            WoHead.closeWo({serviceRating: vm.woBody.serviceRating}, vm.woBody, onSaveSuccess, onSaveError);
        }
        var unsubscribeWoUpdate = $rootScope.$on('autopsApp:woUpdated', function(event, result) {
            if(result.id == vm.woHead.id){
                WoHead.get({id : vm.woHead.id}, function(result){
                    vm.woHead = result;
                    loadPics();
                });
            }
        });
        $scope.$on('$destroy', unsubscribeWoUpdate);

        $scope.woFilter = function(actual) {
            return $rootScope.account.companyId == 1 || !actual.innerLog;
        }

        function delWoBody (woBody) {
            layer.confirm("删除确认！", {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                WoBody.delete({id: woBody.id}, function () {
                    var idx = vm.woHead.woBodys.indexOf(woBody);
                    vm.woHead.woBodys.splice(idx, 1);
                    layer.msg("删除成功");
                });
                $translate.refresh();
            }, function() {
                return;
            });
        }

    }
})();
