(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClerkAuthoritiesController', ClerkAuthoritiesController);

    ClerkAuthoritiesController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$translate',
    'entity', 'User', 'OpsCompany', 'Clerks'];

    function ClerkAuthoritiesController($scope, $rootScope, $uibModalInstance, $timeout, $translate,
        entity, User, OpsCompany, Clerks) {

        $scope.clerks = entity;
        var zTreeObj;
        var userSetting = {
            check : {
                enable : true
            },
            callback: {
                onCheck: userOnCheck
            }
        };
        var authsSet;
        var usersAuthsDTO = {};
        usersAuthsDTO.users = [];
        usersAuthsDTO.authoritys = [];
        var userAuths = [];
        $scope.radioMode = true;

        $scope.authorities = null;
        $scope.getAuthStrs = function() {
            if($scope.authorities){
                return;
            }
            User.getAuthStrs(function(result){
                $scope.authorities = result;
                initAuthorities();
            })
        }

        $scope.filterClerk = function(item) {
            Clerks.query({authStr: item.key}, function(results){
                $scope.clerks = results;
                userNodes = [];
                initClerks(true);
                initAuths();
            });
        }


        $scope.filterAuth = "";
        $scope.filterAuthReset = function() {
            $scope.filterAuth = "";
            $scope.clerks = entity;
            userNodes = [];
            initClerks(false);
            initAuths();
        }

        var initAuthorities = function() {
            angular.forEach($scope.authorities, function(data){
                if(!data.value){
                    data.value = $translate.instant('user-management.' + data.key);
                }
            })
        }
        $scope.radioModeChange = function() {
            zTreeObj.checkAllNodes(false);
        };
        function userOnCheck(event, treeId, treeNode) {
            if($scope.radioMode){
                var checkCopy = treeNode.checked;
                zTreeObj.checkAllNodes(false);
                if(checkCopy){
                    zTreeObj.checkNode(treeNode, true, true);
                }
            }

            var checkedUsers = zTreeObj.getCheckedNodes(true);
            usersAuthsDTO.users = [];
            angular.forEach(checkedUsers, function(user){
                if(user.id > 0){
                    usersAuthsDTO.users.push(user);
                }
            })
            if(usersAuthsDTO.users.length == 1){
                User.getAuths({id:usersAuthsDTO.users[0].id}, function(result){
                    userAuths = result;
                    initAuths();
                })
            }
            else {
                userAuths = [];
                initAuths();
            }
        };
        $scope.copyType = "add";
        $scope.setCopyType = function(type) {
            $scope.copyType = type;
        };

        $scope.copyAuth = function(user) {
            if(usersAuthsDTO.users.length == 0){
                layer.msg($translate.instant('user-management.chooseClerksAlert'));
                return;
            }
            User.copyAuth({
                oldId: user.id,
                newId: usersAuthsDTO.users[0].id,
                isAdd: $scope.copyType == "add"
            }, function (result) {
                userAuths = result;
                initAuths();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (result) {
                if(result.status == 403){
                    $rootScope.OPS_ALERT($translate.instant('user-management.externalSalesAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
            })
        };

        function authOnCheck(event, treeId, treeNode) {
            authOnCheckDo(event, treeId, treeNode, false);
        };
        function authOnCheckDo(event, treeId, treeNode, isAuthStr) {
            if(usersAuthsDTO.users.length == 0){
                layer.msg($translate.instant('user-management.chooseClerksAlert'));
                return;
            }
            usersAuthsDTO.isAuthStr = isAuthStr;
            usersAuthsDTO.isAdd = treeNode.checked;
            usersAuthsDTO.authoritys = [];
            if(authNodes[treeNode.id]){
                angular.forEach(authNodes[treeNode.id][0].children, function(data){
                    usersAuthsDTO.authoritys.push(data.id);
                })
            }
            else {
                usersAuthsDTO.authoritys.push(treeNode.id);
            }
            if(usersAuthsDTO.users.length > 1){
                layer.confirm($translate.instant('user-management.multiUpdateAlert', {param: usersAuthsDTO.users.length}),
                {   icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        updateUsersAuths();
                        layer.close(index);
                    }, function() {
                });
            }
            else {
                updateUsersAuths();
            }
        };
        function updateUsersAuths() {
            User.updateUsersAuths(usersAuthsDTO, function (result) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (result) {
                if(result.status == 403){
                    $rootScope.OPS_ALERT($translate.instant('user-management.externalSalesAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
            })
        }

        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var userNodes = [];
        function getNodes(clerks) {
            var teams = new Set();
            var branchs = new Set();
            angular.forEach(clerks, function(data){
                branchs.add(data.branch);
                teams.add(data.team);
            })
            branchs.forEach(function(branch){
                var newBranch = {
                    name: branch,
                    open: true,
                    id: -1,
                    children:[]
                };
                teams.forEach(function(team){
                    var newTeam = {
                        name: team,
                        open: true,
                        id: 0,
                        children:[]
                    };
                    angular.forEach(clerks, function(clerk){
                        if(clerk.branch == branch && clerk.team == team){
                            var newClert = {
                                name:clerk.name,
                                id: clerk.id
                            };
                            newTeam.children.push(newClert);
                        }
                    })
                    if(newTeam.children.length > 0){
                        newBranch.children.push(newTeam);
                    }
                })
                if(newBranch.children.length > 0){
                    userNodes.push(newBranch);
                }
            })

        };

        $timeout(function (){
            initClerks(false);
            initAuths();
            getAuthStrAllData();
        });

        function initClerks(expandAll) {
            getNodes($scope.clerks);
            zTreeObj = $.fn.zTree.init($("#userTree"), userSetting, userNodes);
            fuzzySearch('userTree','#field_search',null,true);

            $scope.expandAllTag = !expandAll;
            $scope.expandAll();
        }
        $scope.expandAllTag = false;
        $scope.expandAll = function() {
            $scope.expandAllTag = !$scope.expandAllTag;
            zTreeObj.expandAll($scope.expandAllTag);
            if(!$scope.expandAllTag){
                var nodes = zTreeObj.getNodes();
                if (nodes.length>0) {
                    for(var i=0;i<nodes.length;i++){
                        zTreeObj.expandNode(nodes[i], true, false, false);//默认展开第一级节点
                    }
                }
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        var authSetting = {
            check : {
                enable : true
            },
            callback: {
                onCheck: authOnCheck
            }
        };


        var hasAuth = function(authority) {
            if(!userAuths){
                return false;
            }
            return userAuths.indexOf(authority) !== -1;
        };
        var authNodes = {};
        function initAuths() {
            /*
            **基本角色
            */
            authNodes.role = [{name: $translate.instant('user-management.role'), open: true, id: "role", children:[]}];
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_SALES'), id: 'ROLE_SALES', checked:hasAuth('ROLE_SALES')});
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_CS'), id: 'ROLE_CS', checked:hasAuth('ROLE_CS')});
            if($rootScope.HAVEAUTH("WMS") || $rootScope.HAVEAUTH("TMS")){
                authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_DISPATCHER'), id: 'ROLE_DISPATCHER', checked:hasAuth('ROLE_DISPATCHER')});
            }
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_MARKET'), id: 'ROLE_MARKET', checked:hasAuth('ROLE_MARKET')});
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_OPERATOR'), id: 'ROLE_OPERATOR', checked:hasAuth('ROLE_OPERATOR')});
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_DOCUMENT'), id: 'ROLE_DOCUMENT', checked:hasAuth('ROLE_DOCUMENT')});
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_WAREHOUSE'), id: 'ROLE_WAREHOUSE', checked:hasAuth('ROLE_WAREHOUSE')});
            if($rootScope.HAVEAUTH("CUSTOMS")){
                authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_BROKER'), id: 'ROLE_BROKER', checked:hasAuth('ROLE_BROKER')});
            }
            if($rootScope.HAVEAUTH("CMIS")){
                authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_CASHIER'), id: 'ROLE_CASHIER', checked:hasAuth('ROLE_CASHIER')});
            }
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_ACCOUNT'), id: 'ROLE_ACCOUNT', checked:hasAuth('ROLE_ACCOUNT')});
            if($rootScope.HAVEAUTH("FMS")){
                authNodes.role[0].children.push({name:$translate.instant('user-management.BOOKING_BY'), id: 'BOOKING_BY', checked:hasAuth('BOOKING_BY')});
                authNodes.role[0].children.push({name:$translate.instant('user-management.TRUCK_BY'), id: 'TRUCK_BY', checked:hasAuth('TRUCK_BY')});
                authNodes.role[0].children.push({name:$translate.instant('user-management.CUSTOM_BY'), id: 'CUSTOM_BY', checked:hasAuth('CUSTOM_BY')});
                authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_COURIER'), id: 'ROLE_COURIER', checked:hasAuth('ROLE_COURIER')});
            }
            authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_OVERSEAS_CS'), id: 'ROLE_OVERSEAS_CS', checked:hasAuth('ROLE_OVERSEAS_CS')});
            if($rootScope.HAVEAUTH("PO")){
                authNodes.role[0].children.push({name:$translate.instant('user-management.ROLE_PURCHASER'), id: 'ROLE_PURCHASER', checked:hasAuth('ROLE_PURCHASER')});
            }
            $.fn.zTree.init($("#authTree1"), authSetting, authNodes.role);

            /*
            **级别
            */
            authNodes.jobLevel = [{name: $translate.instant('user-management.jobLevel'), open: true, id: "jobLevel", children:[]}];
            authNodes.jobLevel[0].children.push({name:$translate.instant('user-management.ROLE_BOSS'), id: 'ROLE_BOSS', checked:hasAuth('ROLE_BOSS')});
            authNodes.jobLevel[0].children.push({name:$translate.instant('user-management.ROLE_BRANCH'), id: 'ROLE_BRANCH', checked: hasAuth('ROLE_BRANCH')});
            authNodes.jobLevel[0].children.push({name:$translate.instant('user-management.ROLE_DEPARTMENT'), id: 'ROLE_DEPARTMENT', checked: hasAuth('ROLE_DEPARTMENT')});
            $.fn.zTree.init($("#authTree2"), authSetting, authNodes.jobLevel);


            //禁止类授权
            authNodes.fbAuth = [{name: $translate.instant('user-management.fbAuth'), open: true, id: "fbAuth", children:[]}];
            authsSet = ["FB_VIEW_OTH_RATE", "FB_EDIT_OTH_RATE", "FB_VIEW_OWN_WMS", "FB_VIEW_OWN_EMS",
                "FB_VIEW_OWN_TMS", "FB_OWN_CLIENT", "FB_ONE_YEAR", "FB_WF_VIEW", "AU_COMPLETE_ORDER", "FB_VIEW_LIST_GP", "FB_PUB_CLIENT",
                "FB_VIEW_IMPORTER", "FB_EXPORT_GRID_EXCEL", "DELIVERY_AGENT_LIMITED","DISABLE_WORK_TABLE","DISABLE_CLOSED_ORDER",
                "FB_EDI_SEND"];
            angular.forEach(authsSet, function (auth) {
                authNodes.fbAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#fbTree1"), authSetting, authNodes.fbAuth);


            /*
            **资料管理
            */
            authNodes.datumAuth = [{name: $translate.instant('user-management.datumAuth'), open: true, id: "datumAuth", children:[]}];
            authsSet = ["CREATE_STMT_OBJ", "DELETE_STMT_OBJ", "VISIT_STMT_OBJ","FUN_CLIENT", "FUN_COLOADER", "FUN_AGENT", "FUN_VENDER", "FUN_REPORT_TEMP", "FUN_TRACK_STATUS",
                "FUN_COST_MGMT", "FUN_SELLING_MGMT", "FUN_ITEM_MGMT", "FUN_PORT_MGMT", "FUN_ANNOUNCEMENT", "FUN_CARRIER", "FUN_ADDRESS_BOOK", "AU_OBJ_AUDIT",
                "AU_OBJ_LOCK", "AU_GROUP_CLIENT", "EDIT_COMM_DATABASE", "AUDI_COMM_DATABASE", "AU_COMPANY_CONFIGURATION",
                "FUN_CLIENT_EXPORT", "AU_EXCEL_SC", "AU_OPS_LOG", "AU_ORDER_RESTRICTION", "AU_OPS_IMPORTER", "TRANSFER_STMT_OBJ","BATCH_UPDATE_STMT_OBJ"];
            angular.forEach(authsSet, function (auth) {
                authNodes.datumAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree3"), authSetting, authNodes.datumAuth);

            /*
            **费用相关
            */
            authNodes.hidenAuth = [{name: $translate.instant('user-management.hidenAuth'), open: true, id: "hidenAuth", children:[]}];
            authsSet = ["AU_VIEW_CHARGES", "FUN_VIEW_ONE_HIDDEN", "FUN_VIEWCOST", "FUN_VIEWSELLING", "AU_VIEW_SUBTOTAL", "AU_AMEND_EXCHANGE",
                "AU_VIEW_REV", "AU_VIEW_EXP", "AU_VIEW_PROFIT", "AU_EDIT_PAYAMT", "AU_RECHARGE_MGMT", "AU_AMEND_CHARGES", "AU_DEL_MUST_CHARGE",
                "AU_VIEW_ALL_BILL", "AU_AMEND_OWN_BILL", "AU_AMEND_OTHER_BILL", "AU_DEL_OWN_BILL", "AU_DEL_OTHER_BILL", "AU_VIEW_ASSIST_CHARGE", "AU_ASSIST_CHARGE",
                "AU_ASSIST_CHARGES_CONFIRM", "AU_CHARGES_SHARE", "AU_DEL_RATES_BATCH", "AU_DEDUCT_FEE", "AU_REFUND_FEE", "AU_FEE_FORCED_MODIFICATION",
                "AU_DISPUTE_FEE"];
            if($rootScope.account && $rootScope.account.companyId == 388){
                authsSet.push("AU_COMMISSION");
            }
            angular.forEach(authsSet, function (auth) {
                authNodes.hidenAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree4"), authSetting, authNodes.hidenAuth);

            /*
            **查看业务授权
            */
            authNodes.hidenBiz = [{name: $translate.instant('user-management.hidenBiz'), open: true, id: "hidenBiz", children:[]}];
            authsSet = ["FUN_VIEW_TEAM_BIZ", "FUN_VIEW_BRANCH_BIZ", "FUN_VIEW_ALL_BIZ", "AU_AMEND_SHIPMENT", "SYN_EDI_DATA"];
//            if($rootScope.HAVEAUTH("WMS")){
//                authsSet.push("FUN_VIEW_ALLWMS");
//            }
            if($rootScope.HAVEAUTH("CUSTOMS")){
                authsSet.push("AU_VIEW_ALL_CUSTOM");
            }
            if($rootScope.IS_YHSP()){
                authsSet.push("AU_CUSTOMS_REQUIRED");
            }

            angular.forEach(authsSet, function (auth) {
                authNodes.hidenBiz[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree5"), authSetting, authNodes.hidenBiz);

            /*
            **统计业务授权
            */
            authNodes.statBiz = [{name: $translate.instant('user-management.statBiz'), open: true, id: "statBiz", children:[]}];
            authsSet = ["AU_STAT_SALES", "AU_STAT_OP", "AU_STAT_DOC", "AU_STAT_CS", "AU_STAT_FIN", "AU_STAT_OSCS", "AU_STAT_MARKET", "AU_STAT_PARCEL",
                "AU_STAT_TMS", "AU_STAT_WMS", "AU_STAT_TEAM", "AU_STAT_BRANCH", "AU_STAT_ALL"];
            angular.forEach(authsSet, function (auth) {
                authNodes.statBiz[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree12"), authSetting, authNodes.statBiz);

            if($rootScope.HAVEAUTH("AU_GROUP_AUTHORIY")){
                authNodes.groupAuth = [{name: $translate.instant('user-management.groupAuth'), open: true, id: "groupAuth", children:[]}];
                authsSet = ["FUN_VIEW_GROUP_BIZ", "GROUP_SHARE", "AU_GROUP_AUTHORIY"];
                angular.forEach(authsSet, function (auth) {
                    authNodes.groupAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
                })
                OpsCompany.getGroupCompanys(function(result){
                    $scope.companys = result;
                    angular.forEach(result, function(data){
                        authNodes.groupAuth[0].children.push({name:((data.companyAbb?data.companyAbb:data.companyName) + "(Branch)"), id: data.id + '', checked: hasAuth(data.id+ '')});
                    })
                    $.fn.zTree.init($("#groupAuthTree"), authSetting, authNodes.groupAuth);
                })
            }

            /*
            **查看对象
            */
            authNodes.hidenClient = [{name: $translate.instant('user-management.hidenClient'), open: true, id: "hidenClient", children:[]}];
            authsSet = ["FUN_VIEW_CLIENT", "FUN_VIEW_SHIPPER", "FUN_VIEW_COLOADER", "FUN_VIEW_AGENT", "FUN_VIEW_PAYER",
            "FUN_VIEW_PAYEE", "FUN_VIEW_ALL_CLIENTS", "AU_VIEW_GROUP_CLIENTS", "AU_ALL_APPLICATOIN", "AU_VIEW_CHANGELOG",
            "AU_VIEW_BL", "AU_VIEW_INNER_REMARKS", "AU_VIEW_ALL_ENQUIRY", "AU_VIEW_ALL_QUOTATION", "AU_VIEW_ALL_DS", "AU_WH_DATA", "AU_VIEW_MHLIST",
            "AU_VIEW_GUIDE"];
            angular.forEach(authsSet, function (auth) {
                authNodes.hidenClient[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree6"), authSetting, authNodes.hidenClient);

            /*
            **结算授权
            */
            authNodes.finAuth = [{name: $translate.instant('user-management.finAuth'), open: true, id: "finAuth", children:[]}];
            authsSet = ["FUN_WRITE_OFF", "FUN_INVOICE", "FUN_BL_RELEASE", "FUN_MONTHLY_PAYMENT", "FUN_ACCOUNT_INVOICE", "FUN_ACCOUNT_PAYMENT", "AU_DCN_COMBINE", "AU_DCN_DISMISS",
                "AU_ANTI_WRITEOFF", "AU_VOID_DN", "AU_VOID_CN", "AU_CLOUD_INVOICE"];
            angular.forEach(authsSet, function (auth) {
                authNodes.finAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree7"), authSetting, authNodes.finAuth);

            /*
            **财务系统授权
            */
            if($rootScope.HAVEAUTH("FMIS")){
                authNodes.finReport = [{name: $translate.instant('user-management.finReport'), open: true, id: "finReport", children:[]}];
                authsSet = ["REPORT_BALANCE_STATEMENT", "REPORT_PROFIT_STATEMENT", "REPORT_CASHFLOW_STATEMENT", "REPORT_ACCOUNT_BALANCE", "REPORT_TRIAL_BALANCE",
                    "REPORT_LEDGER_ENTRIES", "REPORT_GENERAL_LEDGER"];
                angular.forEach(authsSet, function (auth) {
                    authNodes.finReport[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
                })
                $.fn.zTree.init($("#authTree8"), authSetting, authNodes.finReport);
            }

            /*
            **统计报表
            */
            authNodes.statsReport = [{name: $translate.instant('user-management.statsReport'), open: true, id: "statsReport", children:[]}];
            authsSet = ["REPORT_PROFIT_STATS", "REPORT_CONCISE_STATS", "REPORT_CHART_STATS", "REPORT_CHART_AGING", "REPORT_BIZ_ANALYSIS", "REPORT_PERFORMANCE", "REPORT_AGING", "REPORT_CHARGES_STATS", "REPORT_CHARGES_COMPARE",
                "REPORT_RECEIPT_PAYMENT", "REPORT_INCOME_LIST", "AU_TRACKING_REPORT"];

            angular.forEach(authsSet, function (auth) {
                authNodes.statsReport[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree9"), authSetting, authNodes.statsReport);

            /*
            **管理授权
            */
            authNodes.mgmtAuth = [{name: $translate.instant('user-management.mgmtAuth'), open: true, id: "mgmtAuth", children:[]}];
            authsSet = ["FUN_EXCHANGE_RATES", "FUN_COMPANY_SETUP", "FUN_CLERKS_MGMT", "AU_PAYMENT_AUDIT", "FUN_PAYMENT_APPROVAL", "AU_REVLOCK", "AU_EXPLOCK", "AU_RATE_LOCK",
                "AU_RATE_UNLOCK", "AU_BILL_CLOSE", "AU_BILL_OPEN", "AU_CONSOLIDATE_UNLOCK", "FUN_AUDIT_VOUCHER", "AU_AMEND_ACCOUNTDATE", "AU_AMEND_ETD",
                "AU_AMEND_HBL", "AU_DEL_BIZ", "AU_AMEND_TIMESTAMP", "AU_CREDIT_SET", "FUN_AMEND_JOBNUM", "AU_CHANGE_SALES", "AU_DEL_FILE", "AU_ALLOT_SPACE",
                "AU_WAREHOUSING_DATA", "AU_CHARGE_DATA", "AU_IGNORE_CREDIT", "AU_DEL_OA", "AU_ORDER_TRANSFER", "AU_AM_MGMT", "AU_VOID_ORDER", "AU_SALES_LOCK"];
            if($rootScope.isYuyue($rootScope.account.companyId)){
                authsSet.push("AU_AMEND_RDONYFD");
                authsSet.push("AU_ATD_LOCK_IGNORE");
            }
            if($rootScope.isZb($rootScope.account.companyId)){
                authsSet.push("AMD_CLIENT_SOURCE");
            }

            angular.forEach(authsSet, function (auth) {
                authNodes.mgmtAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree10"), authSetting, authNodes.mgmtAuth);

            /*
            **仓库授权
            */
            if($rootScope.HAVEAUTH("WMS")){
                authNodes.wmsAuth = [{name: $translate.instant('user-management.wmsAuth'), open: true, id: "wmsAuth", children:[]}];
                authsSet = ["FUN_WMS_WAREHOUSE", "FUN_WMS_GOODS", "AU_WMS_AUDIT", "AU_WMS_AUDIT_OUT", "AU_WMS_AMENDMENT", "AU_WMS_ANTI", "FUN_WMS_LOGS",
                    "FUN_WMS_INVENTORY", "FUN_WMS_MOVING", "AU_VIEW_ALL_WH"];
                angular.forEach(authsSet, function (auth) {
                    authNodes.wmsAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
                })
                $.fn.zTree.init($("#authTree11"), authSetting, authNodes.wmsAuth);
            }

            /*
            **EDI授权
            */
            authNodes.ediAuth = [{name: $translate.instant('user-management.ediAuth'), open: true, id: "ediAuth", children:[]}];
            authsSet = ["AU_API", "FUN_LABEL_EDI", "AU_LABEL_ACCOUNT", "AU_DATA_FEED", "AU_EDI_INSURANCE", "AU_VIEW_ALL_INSUR", "AU_EDI_AMS", "AU_EDI_ISF",
            "AU_EDI_ACI", "AU_EDI_AFR", "AU_VGM_SAVE", "AU_VGM_SEND", "AU_PLATEFORM_EDI", "AU_EDI_SH"];
            if($rootScope.account && $rootScope.isYuyue($rootScope.account.companyId)){
                authsSet.push("AU_AMASS_VOID_TRACKING_ID");
                authsSet.push("AU_AMASS_UPSERT_TRANSPORTATION_INFO");
            }
            angular.forEach(authsSet, function (auth) {
                authNodes.ediAuth[0].children.push({name:$translate.instant('user-management.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#ediAuTree"), authSetting, authNodes.ediAuth);
            getAuthStrNodes();
        };

        var authStrAllData = {};
        function getAuthStrAllData() {
            User.getAuthStrAllData(function (result) {
                authStrAllData = result;
                getAuthStrNodes();
            });
        }

        var authStrSetting = {
            check : {
                enable : true
            },
            callback: {
                onCheck: authStrOnCheck
            }
        };
        function authStrOnCheck(event, treeId, treeNode) {
            authOnCheckDo(event, treeId, treeNode, true);
        };

        var authTypes = ["wh", "br", "dp"];
        function getAuthStrNodes() {
            authTypes.forEach(function(authType){
                authNodes[authType]  = [{
                    name: $translate.instant('user-management.authType.' + authType),
                    open: true,
                    id: authType,
                    children:[]
                }];
                var authStrData = [];
                if(authStrAllData[authType]){
                    authStrData = angular.copy(authStrAllData[authType]);
                }
                // angular.forEach(userAuths, function(data){
                //     if(data.indexOf(authType) == 0){
                //         if(authStrData.indexOf(data.substring(2)) == -1){
                //             authStrData.push(data.substring(2));
                //         }
                //     }
                // })
                if(authStrData && authStrData.length > 0){
                    angular.forEach(authStrData, function(data){
                        var newAuth = {
                            name: data,
                            id: authType + data,
                            checked: hasAuth(authType + data)
                        };
                        authNodes[authType][0].children.push(newAuth);
                    })
                }
                $.fn.zTree.init($("#authStrTree" + authType), authStrSetting, authNodes[authType]);
            })
        };
}
})();
