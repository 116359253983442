(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EmailDialogController', EmailDialogController);

    EmailDialogController.$inject = ['$scope', '$rootScope', '$uibModal', '$uibModalInstance', '$translate', '$timeout', '$http',
    'entity', 'OpsReport', 'OpsFiles', 'Email', 'OpsVender', 'DateUtils', 'Guide', 'GridState'];

    function EmailDialogController($scope, $rootScope, $uibModal, $uibModalInstance, $translate, $timeout, $http,
        entity, OpsReport, OpsFiles, Email, OpsVender, DateUtils, Guide, GridState) {
        $scope.email = entity.email;
        $scope.guide = entity.guide;
        $scope.files = entity.files;//已上传文件
        $scope.emailType = entity.emailType;//邮件类型
        var code = entity.code;//报关code
        var fileResult = entity.fileResult;// 生成的附件
        var bookingTruck = entity.bookingTruck; //派车单
        var venderId = entity.venderId; //收件人vid
        var saVenderId = entity.saVenderId;//代理vid
        var isExpress = entity.isExpress;//快递小包
        var isWms = entity.isWms;//仓储
        var saCnCode = entity.saCnCode;//sa选中的cnCode
        var contactId = entity.contactId;//联系人
        var isAutoCheck = entity.isAutoCheck;//月结对账
        var multiAttachments = entity.multiAttachments;//多个附件

        $timeout(function (){
            loadFiles();
            if(code){
                generateMultiAttachment(code);
            }
            if(multiAttachments){
                angular.forEach(multiAttachments, function(data){
                    reloadAttachment(data);
                })
            }
            else if(fileResult){
                reloadAttachment(fileResult);
            }
            if(bookingTruck){
                generateTruckAttachment(bookingTruck);
            }
            if(venderId){
                getContacts();
            }
            if(saVenderId){
                checkGuideRates();
            }
        });

        $scope.contacts = [];
        var contentCopy = $scope.email.content;
        var getContacts = function() {
            OpsVender.getDTO({id: venderId}, function(result) {
                $scope.email.head = result.companyName;
                if(result.contactList && result.contactList.length > 0){
                    $scope.contacts = result.contactList;
                    var choosedContact = $scope.contacts[0];
                    angular.forEach($scope.contacts, function(data){
                        if(data.defaultPic){
                            choosedContact = data;
                        }
                    })
                    angular.forEach($scope.contacts, function(data){
                        if(data.id == contactId){
                            choosedContact = data;
                        }
                    })
                    $scope.email.content = "Dear " + choosedContact.attn + ",\n\n" + contentCopy;
                    $scope.email.to = choosedContact.email+ "; " + $rootScope.account.email + "; ";
                    $scope.email.cc = choosedContact.cc;
                }
            });
        }
        $scope.chooseContact = function(contact) {
            $scope.email.content = "Dear " + contact.attn + ",\n\n" + contentCopy;
            $scope.email.to = contact.email+ "; " + $rootScope.account.email + "; ";
            $scope.email.cc = contact.cc;
        }

        $scope.chooseClerk = function(contact) {
            if(!$scope.email.cc){
                $scope.email.cc = contact.email;
            }
            else {
                if($scope.email.cc.indexOf(contact.email) !== -1){
                    layer.msg($translate.instant('global.messages.repeated'));
                    return;
                }
                if($scope.email.cc.length > 5 && $scope.email.cc.lastIndexOf(";") > ($scope.email.cc.length -3)){
                    $scope.email.cc = $scope.email.cc + contact.email + "; ";
                }
                else {
                    $scope.email.cc = $scope.email.cc + "; " + contact.email + "; ";
                }
            }
        }
        var loadFiles = function() {
            if($scope.files && $scope.files.length > 0){
                getDefaultAttachments();
                return;
            }
            $scope.files = [];
            if ($scope.guide && $scope.guide.id) {
                var filePath = 'REPORTS/GUIDE/' + $scope.guide.id + '/';
                if(isExpress || isAutoCheck || isWms){
                    filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
                    if(isAutoCheck){
                        filePath = "/" + "autoCheck/" + $scope.guide.id + "/";
                    }
                    else if (isWms){
                        filePath = "/" + "wmsIn/" + $scope.guide.id + "/";
                    }
                }
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: $scope.guide.companyId
                },function (data){
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            $scope.files.push(temp);
                        }
                    }
                    getDefaultAttachments();
                });
            }
        }
        var getDefaultAttachments = function() {
            if ($scope.emailType == "So"){
                if ($scope.files.length > 0) {
                    angular.forEach($scope.files, function(file, index) {
                        if (file['filepath'].indexOf("SO-") != -1) {
                            reloadAttachment(file);
                        }
                    });
                }
            }
            else if ($scope.emailType == "POD"){
                if ($scope.files.length > 0) {
                    angular.forEach($scope.files, function(file, index) {
                        if (file['filepath'].indexOf("POD") != -1) {
                            reloadAttachment(file);
                        }
                    });
                }
            }
            else if ($scope.emailType == "saSend"){
                if ($scope.files.length > 0) {
                    angular.forEach($scope.files, function(file, index) {
                        if (file['filepath'].indexOf("MBL-") != -1 || file['filepath'].indexOf("MB-") != -1) {
                            reloadAttachment(file);
                        }
                    });
                }
            }
            else if ($scope.emailType == "Do"){
                if ($scope.files.length > 0) {
                    angular.forEach($scope.files, function(file, index) {
                        if (file['filepath'].indexOf("DO-") != -1) {
                            reloadAttachment(file);
                        }
                    });
                }
            }
            else if ($scope.emailType == "demandSI"){
                OpsFiles.getClientFileUrl({
                    fileType: 'si'
                }, function(result) {
                    result.filename = "AU-SI.xlsx";
                    reloadAttachment(result);
                });
            }
        }
        var checkGuideRates = function() {
            if(!$scope.guide.guideRates || $scope.guide.guideRates.length == 0){
                Guide.getGuideRates({
                    type: "guide",
                    id: $scope.guide.id
                }, function(guideRates) {
                    $scope.guide.guideRates = guideRates;
                    generateCnAttachment();
                })
            }
            else {
                generateCnAttachment();
            }
        }

        var generateCnAttachment = function() {
            var guideReportDTO = angular.copy($scope.guide);
            if(!guideReportDTO.guideRates || guideReportDTO.guideRates.length == 0){
                return;
            }
            for (var i = guideReportDTO.guideRates.length - 1; i >= 0; i--) {
                if (guideReportDTO.guideRates[i].isHidden || guideReportDTO.guideRates[i].venderId != saVenderId) {
                    guideReportDTO.guideRates.splice(i, 1);
                }
            }
            if(guideReportDTO.guideRates.length == 0){
                return;
            }
            guideReportDTO.isEn = true;
            OpsReport.getMultiAttachments({
                reportTypes: saCnCode
            }, guideReportDTO, function(result) {
                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        reloadAttachment(result[i]);
                    }
                }
            });
        }

        var generateMultiAttachment = function(reportTypes) {
            if($scope.emailType == "warehousingPaper"){
                var wmsInDTO = {};
                wmsInDTO.guideId = $scope.guide.id;
                wmsInDTO.companyId = $scope.guide.companyId;
                wmsInDTO.warehousingNumber = $scope.guide.jobNum;
                wmsInDTO.isOut = false;

                OpsReport.getWmsAttachments({
                    reportTypes: reportTypes
                }, wmsInDTO, function(result) {
                    if (result.length > 0) {
                        for (var i = 0; i < result.length; i++) {
                            reloadAttachment(result[i]);
                        }
                    }
                });
            }
            else {
                OpsReport.getMultiAttachments({
                    reportTypes: reportTypes
                }, $scope.guide, function(result) {
                    if (result.length > 0) {
                        for (var i = 0; i < result.length; i++) {
                            reloadAttachment(result[i]);
                        }
                    }
                });
            }
        }

        var reloadAttachment = function(file) {
            if(!file.filepath){
                return;
            }
            reloadFiles(file);
            $scope.chooseAttachment(file);
        }

        var generateTruckAttachment = function(truck) {
            var reportTypeArray = new Array();
            if (truck.isExport){
                reportTypeArray.push(140);
            }
            else {
                reportTypeArray.push(160);
            }

            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.bookingTruck = angular.copy(truck);

            OpsReport.getMultiAttachments({
                reportTypes: reportTypeArray
            }, guideReportDTO, function(result) {
                if (result.length > 0) {
                    for (var i = 0; i < result.length; i++) {
                        reloadAttachment(result[i]);
                    }
                }
            });
        }


        var reloadFiles = function(file) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }
            var exist = false;
            if ($scope.files.length > 0) {
                for (var i = 0; i < $scope.files.length; i++) {
                    if ($scope.files[i]['filename'] == file['filename']) {
                        $scope.files[i]['filepath'] = file['filepath'];
                        $scope.files[i]['filename'] = file['filename'];
                        $scope.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                $scope.files.push(temp);
            }
        }

        //选择附件
        $scope.chooseAttachment = function(file) {
            if ($scope.email.attachments.length > 0) {
                var exist = false;
                angular.forEach($scope.email.attachments, function(attachment, index) {
                    if (attachment['filename'] == file['filename']) {
                        exist = true;
                    }
                });
                if (!exist) {
                    $scope.email.attachments.push(file);
                }
            } else {
                $scope.email.attachments.push(file);
            }
        }

        $scope.uploadAttachment = function(file) {
            if (!file || !$scope.guide.companyId) {
                return;
            }
            var fd = new FormData();
            var filePath = "REPORTS/GUIDE/" + $scope.guide.id + "/";

            if(isExpress){
                filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
            }
            else if (isWms){
                filePath = "/" + "wmsIn/" + $scope.guide.id + "/";
            }
            else if (isAutoCheck){
                filePath = "/" + "autoCheck/" + $scope.guide.id + "/";
            }
            else {
                if($scope.emailType == "custom"){
                    filePath = "REPORTS/GUIDE/" + $scope.guide.id + "/DCD-";
                }
                else if($scope.emailType == "warehousingPaper"){
                    filePath = "REPORTS/GUIDE/" + $scope.guide.id + "/SO-";
                }
            }
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $scope.guide.companyId);

            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadAttachment(data);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        }

        $scope.delAttachment = function(index) {
            $scope.email.attachments.splice(index, 1);
        }
        $scope.getArray = function(address) {
            $scope.addressArray = new Array();
            if (angular.isUndefined(address) || null == address) {
                return $scope.addressArray;
            }
            $scope.addressArray = address.split(";");
            angular.forEach($scope.addressArray, function(to, index) {
                if (to.trim() == "" || null == to) {
                    $scope.addressArray.splice(index, 1);
                }
            });
            return $scope.addressArray;
        }

        $scope.chooseSignature = function(sign) {
            $scope.email.content = $scope.email.content + "\n\n" + sign;
        }
        function getAttachments() {
            var attaments = new Array();
            angular.forEach($scope.email.attachments, function(attachment, index) {
                attaments.push(attachment.filepath);
            });
            return attaments;
        }
        $scope.sendEmail = function() {
            $scope.toArray = $scope.getArray($scope.email.to);
            $scope.ccArray = $scope.getArray($scope.email.cc);
            $scope.bccArray = $scope.getArray($scope.email.bcc);

            var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            for (var i = 0; i < $scope.toArray.length; i++) {
                $scope.toArray[i] = $scope.toArray[i].replace(/\s+/g, "");
                if (!myreg.test($scope.toArray[i]) && $scope.toArray[i] != "") {
                    var alertContent = $scope.toArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                    layer.msg(alertContent);
                    return;
                }
            }
            for (var i = 0; i < $scope.ccArray.length; i++) {
                $scope.ccArray[i] = $scope.ccArray[i].replace(/\s+/g, "");
                if (!myreg.test($scope.ccArray[i]) && $scope.ccArray[i] != "") {
                    var alertContent = $scope.ccArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                    layer.msg(alertContent);
                    return;
                }
            }
            for (var i = 0; i < $scope.bccArray.length; i++) {
                $scope.bccArray[i] = $scope.bccArray[i].replace(/\s+/g, "");
                if (!myreg.test($scope.bccArray[i]) && $scope.bccArray[i] != "") {
                    var alertContent = $scope.bccArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                    layer.msg(alertContent);
                    return;
                }
            }
            $scope.isSaving = true;
            Email.sendEmail({
                to: $scope.toArray,
                cc: $scope.ccArray,
                bcc: $scope.bccArray,
                subject: $scope.email.subject,
                content: $scope.email.content,
                attachments: getAttachments()
            }, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.email_send_success'));
                if(!$scope.guide){
                    $scope.guide = {};
                }
                if(!$scope.guide.booking){
                    $scope.guide.booking = {};
                }
                if ($scope.emailType == "saSend") {
                    $scope.guide.saSentTime = new Date();
                    $scope.guide.status = "SaSent";
                } else if ($scope.emailType == "dnSend") {
                    $scope.guide.status = "DnSent";
                    $scope.guide.booking.status = "DnSent";
                    $scope.guide.booking.lastModifiedTime = new Date();
                } else if ($scope.emailType == "booking") {
                    $scope.guide.bookingTime = new Date();
                    $scope.guide.status = "Booked";
                } else if ($scope.emailType == "custom") {
                    if(null == $scope.guide.customArrangeTime){
                        $rootScope.SETSTATUS($scope.guide.id, "CustomArranged");
                    }
                } else if ($scope.emailType == "So") {
                    $scope.guide.soReleaseTime = new Date();
                    $scope.guide.status = "SoReleased";
                    $scope.guide.booking.status = "Soreleased";
                    $scope.guide.booking.lastModifiedTime = new Date();
                } else if ($scope.emailType == "submitSI" || $scope.emailType == "submitSIAMS") {
                    $scope.guide.status = "SiSubmitted";
                    $scope.guide.siSubmitedTime = new Date();
                } else if ($scope.emailType == "blSend") {
                    // $scope.guide.hblConfirmedTime = new Date();
                    // $scope.guide.status = "HblConfirmed";
                    // $scope.guide.booking.status = "HblConfirmed";
                    // $scope.guide.booking.lastModifiedTime = new Date();
                } else if ($scope.emailType == "sendTruckorder") {
                    $rootScope.SETSTATUS($scope.guide.id, "TruckArranged");
                }
                $uibModalInstance.close($scope.guide);
            }, function(error) {
                $scope.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.send_mail_fail'));
                }
            });
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        $scope.addRef = function(ref, isDate) {
            if(isDate){
                ref = DateUtils.convertLocalDateToServer(ref);
            }

            if(!$scope.email.subject){
                $scope.email.subject = "";
            }
            $scope.email.subject += "/ " +ref;
        };

        $scope.zipFiles = function() {
            if(!$scope.email.attachments || $scope.email.attachments.length == 0 ){
                return;
            }
            OpsFiles.zipFiles($scope.email.attachments, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $scope.guide.jobNum + ".zip");
            })
        };
        $scope.emailTemplates = [];
        $scope.loadEmailTemplate = function() {
            if(!$scope.emailType){
                return;
            }
            if($scope.emailTemplates && $scope.emailTemplates.length){
                return;
            }
            GridState.getCustomEmailTemplateByEmailType({types: $scope.emailType}, function(result) {
                $scope.emailTemplates = result;
            }, $rootScope.ALERT_ERROR);
        }

        $scope.parseEmailTemplate = function(emailTemplateContent) {
            GridState.parseEmailTemplate ({template: emailTemplateContent, guideId: $scope.guide.id}, function(result) {
                if (result) {
                    $scope.email.content = result.description;
                    if (result.digest != null && result.digest.toString().trim().length != 0){
                        $scope.email.subject = result.digest;
                    }
                }
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
