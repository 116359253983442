(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$rootScope', '$translate', 'DateUtils', 'WinAES'];

    function DataUtils($window, $rootScope, $translate, DateUtils, WinAES) {
        var GET_LABEL_TYPES = ["bizType", "internalType", "cargoType"];
        var expressParcelsOtherField = ["shipperName", "bookingToName", "mblNum", "soNum", "labelBindType", "updateTask",
            "sku", "length", "width", "height", "fba", "quantity", "usdValuePerUnit", "usdValueTtl", "packageType", "shipfromCode", "shiptoCode",
            "shiptoCompanyName", "shiptoAttentionName", "shiptoPhoneNumber", "shiptoCity", "shiptoPostalCode", "shiptoProvinceCode", "shiptoCountryCode", "shiptoAddressLineOne", "shiptoAddressLineTwo",
            "shiptoAddressLineThree", "shiptoEmailAddress", "cargoValueUsd", "shipperExt", "shiptoExt", "collectCur", "sellCur", "poNumber", "shiptoDoorplate", "ownImporterCode",
            "accountCode", "dutyPayPartyCode", "updateTime", "otherTimeOne", "otherTimeTwo", "offShelfTime", "otherTimeThree", "goodsDiscription", "ctnNum", "lbs", "cargoIsOut"];
        var expressParcelsOtherField2 = ["salesName", "opName", "cusName", "dispatcher", "storekeeper"];

        var filterTimer;
        var service = {
            getNumFromStr: getNumFromStr,
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            round: round,
            getPalletAmt: getPalletAmt,
            getNextCode: getNextCode,
            translate: translate,
            getDuty: getDuty,
            clearCopyGuide: clearCopyGuide,
            clearCopyRate: clearCopyRate,
            formatTwoDigits: formatTwoDigits,
            randomWord: randomWord,
            generateShowLedger: generateShowLedger,
            getUnitCount: getUnitCount,
            getRatePayer: getRatePayer,
            sortShipprBl: sortShipprBl,
            updateStatParams: updateStatParams,
            addWarehousingDataTag: addWarehousingDataTag,
            updateGuidePl: updateGuidePl,
            updateByFclCtn: updateByFclCtn,
            updateGuideCw: updateGuideCw,
            isPdf: isPdf,
            isPic: isPic,
            sortList: sortList,
            fillGuideFilter: fillGuideFilter,
            addGuideTag: addGuideTag,
            addParcelTag: addParcelTag,
            addVenderTag: addVenderTag,
            getGuideNodeName: getGuideNodeName,
            getGuideNodeTime: getGuideNodeTime,
            removeTag: removeTag,
            updateGuideByProduct: updateGuideByProduct,
            getSelectedFields: getSelectedFields,
            gridSort: gridSort,
            beforeParcelSave: beforeParcelSave,
            getGwPrecision: getGwPrecision,
            getVolPrecision: getVolPrecision,
            checkStrLen: checkStrLen,
            fillFdLabel: fillFdLabel,
            rateAmountChange: rateAmountChange,
            ctnTypeVarInput: ctnTypeVarInput,
            rateMinMaxCheck: rateMinMaxCheck,
            getLangNameAir: getLangNameAir,
            getLangNameAirOut: getLangNameAirOut,
            getLangNameFba: getLangNameFba,
            getLangNameFms: getLangNameFms,
            afterSaveWmsCargo: afterSaveWmsCargo,
            getLangNameRef: getLangNameRef,
            updateWmsWorkLog: updateWmsWorkLog,
            onGuideAddressChoosed: onGuideAddressChoosed,
            maxLen: maxLen,
            onCommSelected: onCommSelected,
            getBoxNo: getBoxNo,
            fillGridDisplayName: fillGridDisplayName,
            fillGuideShowStatus: fillGuideShowStatus,
            getFilterRequired: getFilterRequired,
            guideCtnChange: guideCtnChange,
            getLangNameExpressParel: getLangNameExpressParel,
            updateGuideWmsCw: updateGuideWmsCw,
            isTkJobNum: isTkJobNum,
            isTdJobNum: isTdJobNum,
            getTeusTotal: getTeusTotal,
            getTmsPcNodeField: getTmsPcNodeField,
            getLabelFromKvList: getLabelFromKvList,
            checkBookingTruck: checkBookingTruck
        };
        return service;
        function checkBookingTruck(truck) {
            if (truck.truckerVid && !truck.truckerName) {
                truck.truckerVid = null;
                truck.truckerName = null;
                truck.truckerAddress = null;
                truck.truckerAttn = null;
                truck.truckerTel = null;
                truck.truckerFax = null;
                truck.truckerMob = null;
                truck.truckerEmail = null;
                truck.truckerCc = null;
            }
        }

        function getLabelFromKvList(key, list) {
            var result;
            angular.forEach(list, function(data){
                if(data.value == key){
                    result = data.label;
                }

            });
            return result;
        }

        function isTkJobNum(jobNum) {
            if(jobNum.length == 12 && jobNum.indexOf("TK") == 0){
                return true;
            }
            return false;
        }
        function isTdJobNum(jobNum) {
            if(jobNum.length == 12 && jobNum.indexOf("TD") == 0){
                return true;
            }
            return false;
        }
        function getTeusTotal(dataList) {
            var teusTotal = 0;
            angular.forEach(dataList, function(data){
                if(!data.isVoid && !data.localTimeTwo && data.volumes){
                    var volumes = data.volumes.split(' ');
                    angular.forEach(volumes, function(volume){
                        if (volume){
                            var teus = volume.split('X');
                            if(teus.length == 2 && !isNaN(teus[0])){
                                if (teus[1].substring(0,2) == "20"){
                                    teusTotal = teusTotal + teus[0] * 1;
                                }
                                else {
                                    teusTotal = teusTotal + teus[0] * 2;
                                }
                            }
                        }
                    })
                }
                addGuideTag(data);
            })
            return teusTotal;
        }


        function guideCtnChange(guide, fd) {
            if(guide.dontSynTtl || guide.plConfirmed || !guide.guideCtns || !guide.guideCtns.length){
                return;
            }
            if((fd == 'ctnType' || fd == 'pkgType') && guide.shipmentType != "FCL"){
                return;
            }
            var volTotal = 0;
            var gwTotal = 0;
            var pkgsTotal = 0;

            var ctnTypes = new Set();
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach(guide.guideCtns, function(gctn){
                ctnTypes.add(gctn.ctnType);
                if (gctn.vol) {
                    // gctn.vol = gctn.vol.toFixed(getVolPrecision(guide)) - 0;
                    volTotal = volTotal + gctn.vol;
                }
                if (gctn.gw) {
                    // gctn.gw = gctn.gw.toFixed(getGwPrecision(guide)) - 0;
                    gwTotal = gwTotal + gctn.gw;
                }
                if (gctn.pkgs) {
                    pkgsTotal = pkgsTotal + gctn.pkgs;
                }
                if (pkgsCopy) {
                    if (pkgsCopy != gctn.pkgType) {
                        isSamePkg = false;
                    }
                } else {
                    pkgsCopy = gctn.pkgType;
                }
            })

            if(guide.masterId != 0 && !guide.parcelMaster){
                if(!guide.cwLoked){
                    guide.vol = volTotal.toFixed(getVolPrecision(guide)) - 0;
                    guide.gw = gwTotal.toFixed(getGwPrecision(guide)) - 0;
                }
                guide.pkgNum = pkgsTotal;
                if (isSamePkg) {
                    guide.booking.pkgType = pkgsCopy;
                } else {
                    guide.booking.pkgType = "PKGS";
                }
            }
            ctnTypes.forEach(function(ctnType){
                volTotal = 0;
                gwTotal = 0;
                pkgsTotal = 0;
                pkgsCopy = null;
                isSamePkg = true;
                angular.forEach(guide.guideCtns, function(gctn){
                    if(gctn.ctnType == ctnType){
                        if (gctn.vol) {
                            volTotal = volTotal + gctn.vol;
                        }
                        if (gctn.gw) {
                            gwTotal = gwTotal + gctn.gw;
                        }
                        if (gctn.pkgs) {
                            pkgsTotal = pkgsTotal + gctn.pkgs;
                        }
                        if (pkgsCopy) {
                            if (pkgsCopy != gctn.pkgType) {
                                isSamePkg = false;
                            }
                        } else {
                            pkgsCopy = gctn.pkgType;
                        }
                    }
                })
                angular.forEach(guide.guideFclCtns, function(bctn){
                    if(bctn.ctnType == ctnType){
                        bctn.vol = volTotal.toFixed(getVolPrecision(guide)) - 0;
                        bctn.gw = gwTotal.toFixed(getGwPrecision(guide)) - 0;
                        bctn.pkgNum = pkgsTotal;
                        if (isSamePkg) {
                            bctn.pkgType = pkgsCopy;
                        } else {
                            bctn.pkgType = "PKGS";
                        }
                    }
                })
            })
            updateGuideCw(guide);
        }



        function getFilterRequired(gridCopy) {
            if(!gridCopy){
                return;
            }
            var filterRequired = false;
            angular.forEach(gridCopy.columns, function(column){
                angular.forEach(column.filters, function(cf){
                    if(cf.term || cf.term == false){
                        filterRequired = true;
                    }
                })
            })
            return filterRequired;
        }
        function fillGuideShowStatus(guide) {
            if(guide.status){
                var showStatus = $translate.instant('autopsApp.guide.sts.' + guide.status);
                if(showStatus.startsWith("autopsApp.")){
                    guide.showStatus = guide.status;
                }
                else {
                    guide.showStatus = showStatus;
                }
            }
        }
        function fillGridDisplayName(gridState, columnDefs, allCols) {
            var i18nNames = {};
            var fieldType = {};
            angular.forEach(columnDefs, function(columnDef) {
                i18nNames[columnDef.field] = columnDef.displayName;
                fieldType[columnDef.field] = columnDef.type;
            })
            angular.forEach(gridState.columns, function(column) {
                column.displayName = i18nNames[column.name];
                column.type = fieldType[column.name];
                if(allCols && column.visible == false){
                    column.visible = true;
                }
            })
        }

        function getBoxNo(inStr, strSize) {
            if (null == inStr) {
                inStr = "";
            }
            var str;
            if (inStr.length > strSize) {
                str = inStr.substring(inStr.length - strSize);
            } else {
                str = inStr;
                for (var i = inStr.length; i < strSize; i++) {
                    str = "0" + str;
                }
            }
            return str;
        }


        function onCommSelected(item, btn) {
            // angular.forEach(item.commodityCountrys, function(data){
            //     if(btn.importCountryCode == data.importCountryCode){
            //         btn.auditor = item.auditor;
            //         btn.dutyAudited = item.auditor?true:false;
            //         if(data.dutyRate){
            //             btn.dutyRate = data.dutyRate;
            //         }
            //     }
            // })
            btn.commodityId = item.id;
            if(item.adStr){
                if(!btn.remarks){
                    btn.remarks = item.adStr;
                }
                else {
                    btn.remarks += "; " + item.adStr;
                }
            }
            if(item.idCode){
                btn.idCode = item.idCode;
            }
            if(item.sku){
                btn.sku = item.sku;
            }
            // if(item.impHsCode){
            //     btn.hsCode = item.impHsCode;
            // }
            // if(item.cargoNameEn){
            //     btn.cargoNameEn = item.cargoNameEn;
            // }
            // if(item.commodity){
            //     btn.cargoName = item.commodity;
            // }
            // else if(item.cargoName){
            //     btn.cargoName = item.cargoName;
            // }
            // if(item.model){
            //     btn.model = item.model;
            // }
            // if(item.brand){
            //     btn.brand = item.brand;
            // }
            // if(item.material){
            //     btn.material = item.material;
            // }
            // if(item.materialEn){
            //     btn.materialEn = item.materialEn;
            // }
            // if(item.useAge){
            //     btn.useAge = item.useAge;
            // }
            // if(item.useAgeEn){
            //     btn.useAgeEn = item.useAgeEn;
            // }
            // if(item.productUrl){
            //     btn.productUrl = item.productUrl;
            // }
            // if(item.unitPrice){
            //     btn.unitPrice = item.unitPrice;
            // }
        }

        function onGuideAddressChoosed(guide, address) {
            if(address.address){
                guide.booking.cneeBl = address.address;
            }
            if (address.code) {
                guide.booking.deliveryTo = address.code;
            }
            guide.booking.cneeName = address.companyName;
            guide.shipToId = address.id;
            guide.shiptoCode = address.code;
            guide.shiptoPostcode = address.postCode;
            guide.destCountryCode = address.countryCode;
            guide.booking.bookingToAttn = address.attn;
            guide.booking.hblNum = address.companyName;
            guide.booking.bookingToTel = address.tel;
            guide.booking.bookingToAddress = address.addressOne;
            guide.booking.bookingToMob = address.addressTwo;
            guide.booking.bookingToFax = address.province;
            guide.booking.bookingToEmail = address.city;
            guide.isResidential = address.isResidential;
            $rootScope.getCountryName(guide.destCountryCode);
        }

        function maxLen(str, len) {
            if(!str || str.length <= len){
                return str;
            }
            return str.substring(0, len);
        }
        function getNumFromStr(str) {
            if(!str){
                return null;
            }
            return str.replace(/[^\d.\d]/g,'');
        }
        function updateWmsWorkLog(workLog, wmsIn, cargo, opsItem) {
            workLog.work = opsItem.wmsService;
            workLog.item = opsItem.nameCn;
            workLog.itemId = opsItem.id;
            workLog.currency = opsItem.currency;
            workLog.work = opsItem.wmsService;
            workLog.remarks = opsItem.wmsService;
            workLog.price = opsItem.price;
            workLog.unit = opsItem.unit;
            if(!workLog.remarks){
                workLog.remarks = "";
            }
            if (cargo) {
                workLog.remarks += "(" + (cargo.sku ? cargo.sku : cargo.cargoName) + ") X " + (opsItem.unit ? "(" + opsItem.unit +")": "");
                wmsIn = {};
                wmsIn.gw = cargo.gw;
                wmsIn.vol = cargo.vol;
                wmsIn.applicationNum = cargo.pkgs;
                wmsIn.pallets = cargo.palletAmt;
                wmsIn.pieces = cargo.piece;
                workLog.count = getUnitCount(workLog, 'wmsIn', wmsIn);
            } else if (wmsIn) {
                workLog.remarks += "(" + wmsIn.warehousingNumber + ") X " + (opsItem.unit ? "(" + opsItem.unit +")": "");
                workLog.count = getUnitCount(workLog, 'wmsIn', wmsIn);
            }
            else {
                return;
            }
            workLog.remarks += workLog.count;
            if (workLog.count && opsItem.numOfFree) {
                workLog.count -= opsItem.numOfFree;
                workLog.remarks = workLog.remarks + " - free amt: " + opsItem.numOfFree + " = " + workLog.count;
            }
            if (workLog.count < 0) {
                workLog.count = 0;
            }
            if (workLog.count && workLog.price) {
                workLog.total = round(workLog.count * workLog.price, 2);
                if (opsItem.min && workLog.total < opsItem.min) {
                    workLog.price = workLog.total = opsItem.min;
                    workLog.unit = "SHIPMENT";
                    workLog.count = 1;
                    workLog.remarks = workLog.remarks + " **Minimum Charge";
                } else if (opsItem.max && workLog.total > opsItem.max) {
                    workLog.price = workLog.total = opsItem.max;
                    workLog.unit = "SHIPMENT";
                    workLog.count = 1;
                    workLog.remarks = workLog.remarks + " **Maximum Charge";
                }
            }
        }

        function addWarehousingDataTag(resultList) {
            angular.forEach(resultList, function(data) {
                data.encodeId = WinAES.encode(data.id.toString());
                data.guide.otherTimeOneTAG = data.guide.otherTimeOne != null;
                data.guide.otherTimeTwoTAG = data.guide.otherTimeTwo != null;
                if($rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')){
                    data.guide.customerName = "********";
                }
                if(data.length != null && data.height != null && data.width != null){
                    var sizes = [data.length, data.width, data.height];
                    sizes.sort(function(a,b){return a < b?1:-1;});
                    data.girth = (sizes[1] + sizes[2]) * 2 + sizes[0];
                    data.girth = round(data.girth);
                }
            })
        }
        function getLangNameComm(data) {
            if (data.name == "finName") {
                data.langName = "guide.account";
            } else if (data.name == "truckBy") {
                data.langName = "guide.table.truckBy";
            } else if (data.name == "customBy") {
                data.langName = "guide.table.customBy";
            } else if (data.name == "BKpkgType") {
                data.langName = "booking.packType";
            } else if (data.name == "BKbookingCtns") {
                data.langName = "guide.packingList";
            } else if (data.name == "vw") {
                data.langName = "booking.vw";
            } else if (data.name == "shipperName") {
                data.langName = "guide.home.shipper";
            } else if (data.name == "cargoReadyDate") {
                data.langName = "guide.wmsInDate";
            } else if (data.name == "BKetd") {
                data.langName = "guide.bookingEtd";
            } else if (data.name == "poNum") {
                data.langName = "booking.poNum";
            } else if (data.name == "whName") {
                data.langName = "wmsIn.warehouseName";
            } else if (data.name == "BKpkgNum") {
                data.langName = "guide.BKpkgNum";
            } else if (data.name == "BKgw") {
                data.langName = "guide.BKgw";
            } else if (data.name == "BKvol") {
                data.langName = "guide.BKvol";
            } else if (data.name == "pieces") {
                data.langName = "booking.bookingCtns.pieces";
            } else if (data.name == "polRegion") {
                data.langName = "guide.polAreaAir";
            } else if (data.name == "podRegion") {
                data.langName = "guide.podAreaAir";
            } else if (data.name == "poNum") {
                data.langName = "booking.poNum";
            } else if (data.name == "BKsoNum") {
                data.langName = "guide.clientWmsInNum";
            } else if (data.name == "BKfreightTerm") {
                data.langName = "guide.freightTerm";
            } else if (data.name == "chargeWeight") {
                data.langName = "booking.chargeWeight";
            } else if (data.name == "BKsiReceivedTime") {
                data.langName = "guide.siReceivedTime";
            } else if (data.name == "localTimeTwo") {
                data.langName = "guide.cancelTakeback";
            } else if (data.name == "otherTimeOne") {
                data.langName = "guide.wmsInTime";
            } else if (data.name == "otherTimeTwo") {
                data.langName = "guide.wmsOutTime";
            } else if (data.name == "otherTimeThree") {
                data.langName = "guide.transferOutTime";
            } else if (data.name.indexOf("BK") == 0) {
                data.langName = "booking." + data.name.substring(2);
            } else if (data.name.indexOf("RT") == 0) {
                data.langName = "customNode.guideRates." + data.name.substring(2);
            } else if (data.name =="firstDayWeek") {
                data.langName = "customNode.firstDayWeek";
            }
            else if (data.name =="firstDayMonth") {
                data.langName = "customNode.firstDayMonth";
            } else {
                data.langName = "guide." + data.name;
            }

        }

        function afterSaveWmsCargo(cargo, newOne) {
            cargo.locationType = newOne.locationType;
            cargo.code = newOne.code;
            cargo.fnSku = newOne.fnSku;
            cargo.upc = newOne.upc;
            cargo.asin = newOne.asin;
            cargo.oldOneName = newOne.oldOneName;
            cargo.batchOne = newOne.batchOne;
            cargo.batchTwo = newOne.batchTwo;
            cargo.batchThree = newOne.batchThree;
            cargo.batchFour = newOne.batchFour;
            cargo.packageType = newOne.packageType;
            cargo.gw = newOne.gw;
            cargo.vol = newOne.vol;
            cargo.length = newOne.length;
            cargo.width = newOne.width;
            cargo.height = newOne.height;
            cargo.palletizedAt = newOne.palletizedAt;
            cargo.mark = newOne.mark;
            cargo.status = newOne.status;
            cargo.location = newOne.location;
            cargo.warehouseId = newOne.warehouseId;
            cargo.locationId = newOne.locationId;
            cargo.areaId = newOne.areaId;
            cargo.locationType = newOne.locationType;
        }

        function getLangNameRef(data) {
            if (data.name == "otherTimeThree") {
                data.langName = "guide.transferOutTime";
            } else if (data.name == "ctnNum") {
                data.langName = "booking.bookingCtns.ctnCode";
            } else if (data.name == "pol") {
                data.langName = "booking.polNameE";
            } else if (data.name == "pod") {
                data.langName = "booking.podNameE";
            } else if (data.name == "dest") {
                data.langName = "booking.destNameE";
            } else if (data.name == "bookingAgent") {
                data.langName = "guide.bookingToName";
            } else if (data.name == "overseasAgent") {
                data.langName = "guide.deliveryName";
            } else if (data.name == "importerName") {
                data.langName = "booking.importerName";
            } else {
                getLangNameComm(data);
            }
            data.langName = $translate.instant("autopsApp." + data.langName);
            if (data.name == "atd") {
                data.langName = "ATD";
            } else if (data.name == "etd") {
                data.langName = "ETD";
            } else if (data.name == "vslVoy") {
                data.langName = "VSL/ VOY";
            } else if (data.name == "ata") {
                data.langName = "ATA";
            } else if (data.name == "dock") {
                data.langName = "guide.areaOfCall";
            } else if (data.name == "ctnNum"){
                data.langName = data.langName + " /MAWB#";
            } else if (data.name == "eta"){
                data.langName = "ETA";
            } else if (data.name == "mblNum"){
                data.langName = "MBL#";
            } else if (data.name == "soNum"){
                data.langName = "SO#";
            }
        }
        function getLangNameAir(data) {
            if (data.name == "carrier") {
                data.langName = "guide.airline";
            } else if (data.name == "etd") {
                data.langName = "guide.airEtd";
            } else if (data.name == "eta") {
                data.langName = "guide.airEta";
            } else if (data.name == "dock") {
                data.langName = "guide.opPlace";
            } else if (data.name == "voy") {
                data.langName = "guide.flight";
            } else if (data.name == "lane") {
                data.langName = "guide.laneAir";
            } else if (data.name == "hblConfirmedTime" || data.name == "mblConfirmedTime") {
                data.langName = "guide.confirmedTime";
            } else if (data.name == "hblReleasedTime" || data.name == "mblReleasedTime") {
                data.langName = "guide.releasedTime";
            } else if (data.name == "BKpodName" || data.name == "podName") {
                data.langName = "guide.home.podPlace";
            } else if (data.name == "BKdestName" || data.name == "destName") {
                data.langName = "guide.home.destPlace";
            } else if (data.name == "BKpolName" || data.name == "polName") {
                data.langName = "guide.home.polPlace";
            } else {
                getLangNameComm(data);
            }

            data.langName = $translate.instant("autopsApp." + data.langName);
            if (data.name == 'BKporName' || data.name == 'BKpolName' || data.name == 'BKpodName' ||
                data.name == 'BKdestName' || data.name == 'BKdeliveryTo' ||
                data.name == 'BKfreightTerm' || data.name == 'BKserviceTerm' || data.name == 'hblConfirmedTime' || data.name == 'hblReleasedTime') {
                data.langName = data.langName + "(HAWB)";
            }
            else if (data.name == 'mblConfirmedTime' || data.name == 'mblReleasedTime') {
                data.langName = data.langName + "(MAWB)";
            } else if (data.name == 'hblNum') {
                data.langName = "HAWB#";
            } else if (data.name == 'mblNum') {
                data.langName = "MAWB#";
            } else if (data.name == 'atd') {
                data.langName = "ATD";
            } else if (data.langName.startsWith("autopsApp.")){
                data.langName = data.name.substring(3);
            }

        }

        function getLangNameAirOut(data) {
            if (data.name == "carrier") {
                data.langName = "guide.airline";
            } else if (data.name == "etd") {
                data.langName = "guide.airEtd";
            } else if (data.name == "eta") {
                data.langName = "guide.airEta";
            } else if (data.name == "dock") {
                data.langName = "guide.opPlace";
            } else if (data.name == "voy") {
                data.langName = "guide.flight";
            } else if (data.name == "lane") {
                data.langName = "guide.laneAir";
            } else if (data.name == "hblConfirmedTime" || data.name == "mblConfirmedTime") {
                data.langName = "guide.confirmedTime";
            } else if (data.name == "hblReleasedTime" || data.name == "mblReleasedTime") {
                data.langName = "guide.releasedTime";
            } else if (data.name == "BKpodName" || data.name == "podName") {
                data.langName = "guide.home.podPlace";
            } else if (data.name == "BKdestName" || data.name == "destName") {
                data.langName = "guide.home.destPlace";
            } else if (data.name == "BKpolName" || data.name == "polName") {
                data.langName = "guide.home.polPlace";
            }
            else if (data.name =='divVolBy'){
                data.langName = "booking.divVolBy";
            }  else if (data.name =='importerTaxId'){
                data.langName = "booking.importerTaxId";
            } else if (data.name =='importerAddress'){
                data.langName = "booking.importerAddress";
            }
            else {
                getLangNameComm(data);
            }

            data.langName = $translate.instant("autopsApp." + data.langName);
            if (data.name == 'BKporName' || data.name == 'BKpolName' || data.name == 'BKpodName' ||
                data.name == 'BKdestName' || data.name == 'BKdeliveryTo' ||
                data.name == 'BKfreightTerm' || data.name == 'BKserviceTerm' || data.name == 'hblConfirmedTime' || data.name == 'hblReleasedTime') {
                data.langName = data.langName + "(HAWB)";
            }
            else if (data.name == 'mblConfirmedTime' || data.name == 'mblReleasedTime') {
                data.langName = data.langName + "(MAWB)";
            } else if (data.name == 'hblNum') {
                data.langName = "HAWB#";
            } else if (data.name == 'mblNum') {
                data.langName = "MAWB#";
            } else if (data.name == 'atd') {
                data.langName = "ATD";
            } else if (data.langName.startsWith("autopsApp.")){
                data.langName = data.name.substring(3);
            }

        }



        function getLangNameFba(data) {
            if (data.name == "soNum") {
                data.langName = "guide.soWmsInNum";
            } else if (data.name == "mblNum") {
                data.langName = "guide.expressMbl";
            } else if (data.name == "BKcarrierFullName") {
                data.langName = "guide.trackingChannel";
            } else if (data.name == "tkState") {
                data.langName = "guide.deliveryState";
            } else if (data.name == "chargeByCbm") {
                data.langName = "guide.chargeMode";
            } else if (data.name == "BKshipperBl") {
                data.langName = "booking.shipperName";
            } else if (data.name == "BKcneeBl") {
                data.langName = "booking.cneeName";
            } else if (data.name == "BKnotifyBl") {
                data.langName = "booking.notifyName";
            } else if (data.name == "BKdeliveryBl") {
                data.langName = "guide.deliveryName";
            } else if (data.name == "deliveryBl") {
                data.langName = "guide.deliveryAddress";
            } else if (data.name == "shipfromCode") {
                data.langName = "guide.shipFromParty";
            } else if (data.name == "shiptoCode") {
                data.langName = "guide.shipToParty";
            } else if (data.name == "BKbookingToAttn") {
                data.langName = "expressParcel.attentionName";
            } else if (data.name == "BKhblNum") {
                data.langName = "expressParcel.companyOrName";
            } else if (data.name == "shiptoPostcode") {
                data.langName = "guide.destPostCode";
            } else if (data.name == "BKbookingToEmail") {
                data.langName = "expressParcel.city";
            } else if (data.name == "BKbookingToFax") {
                data.langName = "expressParcel.province";
            } else if (data.name == "BKbookingToAddress") {
                data.langName = "expressParcel.addressLine";
            } else if (data.name == "BKbookingToMob") {
                data.langName = "expressParcel.addressLine";
            } else if (data.name == "BKisLocked") {
                data.langName = "guide.lockSI";
            } else if (data.name == "BKbeneficiary") {
                data.langName = "booking.beneficiary";
            } else {
                getLangNameComm(data);
            }
            data.langName = $translate.instant("autopsApp." + data.langName);
            if (data.name == 'BKshipperBl' || data.name == 'BKcneeBl' ||
                data.name == 'BKnotifyBl' || data.name == 'BKdeliveryBl' || data.name == 'BKmark' || data.name == 'BKgoodsDiscription') {
                data.langName = data.langName + "(HBL)";
            } else if (data.name == 'BKbookingToAddress') {
                data.langName = data.langName + "(1)";
            } else if (data.name == 'BKbookingToMob') {
                data.langName = data.langName + "(2)";
            }

            if (data.name == 'fbaRef') {
                data.langName = "Reference Id";
            } else if (data.name == 'shipmentId') {
                data.langName = "Shipment Id";
            } else if (data.name == 'sayCtns') {
                data.langName = "CTN/MAWB#";
            } else if (data.name == 'atd') {
                data.langName = "ATD";
            } else if (data.langName.startsWith("autopsApp.")){
                data.langName = data.name.substring(3);
            }
        }

        function getLangNameFms(data) {
            if (data.name == "hblRlsWay") {
                data.langName = "guide.releaseWay";
            } else if (data.name == "awbCharges") {
                data.langName = "guide.freightAmt";
            } else if (data.name == "dock") {
                data.langName = "guide.areaOfCall";
            } else {
                getLangNameComm(data);
            }

            data.langName = $translate.instant("autopsApp." + data.langName);
            if (data.name == 'BKporName' || data.name == 'BKpolName' || data.name == 'BKpodName' ||
                data.name == 'BKdestName' || data.name == 'BKdeliveryTo' || data.name == 'hblRlsWay' ||
                data.name == 'BKfreightTerm' || data.name == 'BKserviceTerm') {
                data.langName = data.langName + "(HBL)";
            }
            else if (data.name == 'atd') {
                data.langName = "ATD";
            } else if (data.langName.startsWith("autopsApp.")){
                data.langName = data.name.substring(3);
            }
        }

        function getLangNameExpressParel(data, isTms) {
            if (data.name.indexOf("RT") == 0) {
                data.langName = $translate.instant("autopsApp.customNode.guideRates." + data.name.substring(2));
            }
            else if(isTms && data.name == "otherTimeOne"){
                data.langName = $translate.instant('autopsApp.expressParcel.confirmTime');
            }
            else if(isTms && data.name == "otherTimeTwo"){
                data.langName = $translate.instant('autopsApp.expressParcel.pickupTime');
            }
            else if(isTms && data.name == "otherTimeThree"){
                data.langName = $translate.instant('autopsApp.guide.wmsInTime');
            }
            else if(isTms && data.name == "customReleaseTime"){
                data.langName = $translate.instant('autopsApp.expressParcel.checkOutTime');
            }
            else if(isTms && data.name == "deliveryTime"){
                data.langName = $translate.instant('autopsApp.expressParcel.deliveryTimeTms');
            }
            else if(!isTms && data.name == "otherTimeOne"){
                data.langName = $translate.instant('autopsApp.expressParcel.checkInTime');
            }
            else if(!isTms && data.name == "otherTimeTwo"){
                data.langName = $translate.instant('autopsApp.expressParcel.docmentTime');
            }
            else if(!isTms && data.name == "otherTimeThree"){
                data.langName = $translate.instant('autopsApp.expressParcel.checkOutTime');
            }
            else if (expressParcelsOtherField.indexOf(data.name) != -1) {
                data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.' + data.name);
            } else if (expressParcelsOtherField2.indexOf(data.name) != -1) {
                data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel2.' + data.name);
            }else {
                data.langName = $translate.instant('autopsApp.expressParcel.' + data.name);
            }
            if (data.langName.startsWith("autopsApp")) {
                data.langName = $translate.instant('autopsApp.guide.' + data.name);
            }
            if (data.langName.startsWith("autopsApp")) {
                data.langName = $translate.instant('autopsApp.booking.' + data.name);
            }
            if (data.langName.startsWith("autopsApp")) {
                data.langName = $translate.instant('autopsApp.booking.bookingCtns.' + data.name);
            }
        }
        function checkStrLen(inStr, len) {
            if (!inStr) {
                return null;
            }
            var strs = inStr.split("\n");
            if(strs.length > 1000){
                return inStr;
            }
            var outStr = "";
            for (var i = 0; i < strs.length; i++) {
                if (!strs[i]) {
                    continue;
                }
                if (strs[i].length > len) {
                    var spaceStrs = strs[i].split(/\s+/);
                    var oStr = "";
                    var mStr = "";
                    for (var j = 0; j < spaceStrs.length; j++) {
                        if (oStr.length < len && ((oStr + spaceStrs[j] + " ").length < len || spaceStrs[j].length >= (len - 1))) {
                            oStr = oStr + spaceStrs[j] + " ";
                        } else {
                            mStr = mStr + spaceStrs[j] + " ";
                        }
                    }
                    outStr = outStr + oStr + "\n";
                    if (mStr.length > 0) {
                        if (i == (strs.length - 1)) {
                            strs.push("");
                        }
                        strs[i + 1] = mStr + " " + strs[i + 1];
                    }
                } else {
                    outStr = outStr + strs[i] + "\n";
                }
            }
            outStr = outStr.substring(0, outStr.length - 1);
            return outStr;
        }

        function beforeParcelSave(expressParcel) {
            if (expressParcel.shipperCountryCode && expressParcel.shipperCountryCode.length > 25) {
                expressParcel.shipperCountryCode = expressParcel.shipperCountryCode.substring(0, 25);
            }
            if (expressParcel.shiptoCountryCode && expressParcel.shiptoCountryCode.length > 25) {
                expressParcel.shiptoCountryCode = expressParcel.shiptoCountryCode.substring(0, 25);
            }
            if (expressParcel.shipperPostalCode && expressParcel.shipperPostalCode.length > 10) {
                expressParcel.shipperPostalCode = expressParcel.shipperPostalCode.substring(0, 10);
            }
            if (expressParcel.shipperProvinceCode && expressParcel.shipperProvinceCode.length > 25) {
                expressParcel.shipperProvinceCode = expressParcel.shipperProvinceCode.substring(0, 25);
            }
            if (expressParcel.shiptoPostalCode && expressParcel.shiptoPostalCode.length > 10) {
                expressParcel.shiptoPostalCode = expressParcel.shiptoPostalCode.substring(0, 10);
            }
            if (expressParcel.shiptoProvinceCode && expressParcel.shiptoProvinceCode.length > 25) {
                expressParcel.shiptoProvinceCode = expressParcel.shiptoProvinceCode.substring(0, 25);
            }
            if (!expressParcel.salesName) {
                expressParcel.salesId = null;
            }
            if (!expressParcel.opName) {
                expressParcel.opId = null;
            }
            if (!expressParcel.cusName) {
                expressParcel.cusId = null;
            }
            if (!expressParcel.finName) {
                expressParcel.finId = null;
            }
            if (!expressParcel.shipperName) {
                expressParcel.shipperId = null;
            }
            if (!expressParcel.bookingToName) {
                expressParcel.bookingToId = null;
            }
        }

        function gridSort(a, b, fd, direction) {
            if ("asc" == direction) {
                if (!a[fd]) {
                    a[fd] = "";
                }
                if (isNaN(a[fd])) {
                    return a[fd].localeCompare(b[fd]);
                } else {
                    return a[fd] > b[fd];
                }
            } else {
                if (!b[fd]) {
                    b[fd] = "";
                }
                if (isNaN(a[fd])) {
                    return b[fd].localeCompare(a[fd]);
                } else {
                    return b[fd] > a[fd];
                }
            }
        }

        function clearCopyRate(rate) {
            rate.id = null;
            rate.lfd = null;
            rate.inputTime = null;
            rate.timestamp= null;
            rate.lastModifiedBy = null;
            rate.isHidden = false;
            rate.locked = false;
            rate.allPaid = false;
            rate.clientChecked = false;
            rate.dnNum = null;
            rate.exchangeRate = null;
            rate.actualExchangeRate = null;
            rate.firstWeight = null;
            rate.invoiceNum = null;
            rate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            rate.actual = null;
            rate.voucherId = null;
            rate.billNum = null;
            rate.mcNum = null;
            rate.paidTime = null;
            rate.sharedOn = null;
            rate.audited = null;
            rate.etd = null;
            rate.guideId = null;
            rate.companyId = $rootScope.account.companyId;
            rate.writeOffCode = null;
            rate.accountDate = null;
            rate.deductBillId = null;
            rate.refundBillId = null;
            rate.refType = null;
            rate.wmsCargoId = null;
        }

        function updateGuideByProduct(guide, data, isMath) {
            if(isMath){
                 if (data.costMath) {
                     guide.costMath = data.costMath;
                 }
            }

            if (data.productName) {
                guide.productName = data.productName;
            }
            if (data.deliveryWay) {
                guide.deliveryWay = data.deliveryWay;
            }
            if (data.shipmentType && $rootScope.fms_fba_all_types.indexOf(data.shipmentType) != -1
                && (!guide.shipmentType || !guide.jobNum)) {
                guide.shipmentType = data.shipmentType;
            }
            if (data.volumeShare) {
                guide.booking.volumeShare = data.volumeShare;
            }
            if (data.deliveryChannel) {
                guide.deliveryChannel = data.deliveryChannel;
            }
            if (data.channel) {
                guide.deliveryChannel = data.channel;
            }
            if (data.channelName) {
                guide.deliveryChannel = data.channelName;
            }
            if (data.polChannel) {
                guide.polChannel = data.polChannel;
            }
            if (data.carrier) {
                guide.carrier = data.carrier;
            }
            if (data.volCoe) {
                guide.booking.divVolBy = data.volCoe;
            }
            if (data.carrierCode) {
                guide.booking.carrierCode = data.carrierCode;
            }
            guide.chargeByCbm = null;
            if (data.priceUnit && (data.priceUnit.indexOf("KG") == 0
                || data.priceUnit.indexOf("CW") == 0
                || data.priceUnit.indexOf("LB") == 0
                || data.priceUnit.indexOf("VW") == 0)) {
                guide.chargeByCbm = false;
            } else if (data.priceUnit == "RT" || data.priceUnit == "CBM" || data.priceUnit == "TON" || data.priceUnit == "MT") {
                guide.chargeByCbm = true;
            }
            if(data.type){
                data.opsCostType = data.type;
            }
            if(!guide.shipmentType && data.opsCostType){
                if(data.opsCostType == 24){
                    guide.shipmentType = "EXPRESS";
                    if(guide.chargeByCbm == null){
                        guide.chargeByCbm = false;
                    }
                }
                else if(data.opsCostType == 26){
                    guide.shipmentType = "FBASEA";
                    if(guide.chargeByCbm == null){
                        guide.chargeByCbm = true;
                    }
                }
                else if(data.opsCostType == 28){
                    guide.shipmentType = "FBAAIR";
                    if(guide.chargeByCbm == null){
                        guide.chargeByCbm = false;
                    }
                }
                else if(data.opsCostType == 16){
                    guide.shipmentType = "FCL";
                }
                else if(data.opsCostType == 32){
                    guide.shipmentType = "LCL";
                    if(guide.chargeByCbm == null){
                        guide.chargeByCbm = true;
                    }
                }
                else if(data.opsCostType == 30){
                    guide.shipmentType = "AIR";
                    if(guide.chargeByCbm == null){
                        guide.chargeByCbm = false;
                    }
                }
            }
            updateGuideCw(guide);
        }


        function getGuideNodeTime(guide) {
            var lastestTime = guide.createdTime == null? new Date():guide.createdTime;
            if(guide.deliveryGuideTime && guide.deliveryGuideTime > lastestTime){
                guide.status = "Requested";
                lastestTime = guide.deliveryGuideTime;
            }
            if(guide.bookingTime && guide.bookingTime >= lastestTime){
                guide.status = "Booked";
                lastestTime = guide.bookingTime;
            }
            if(guide.soReleaseTime && guide.soReleaseTime >= lastestTime){
                guide.status =guide.isExport?"SoReleased":"DoReleased";
                lastestTime = guide.soReleaseTime;
            }
            if(guide.localTimeOne && guide.localTimeOne >= lastestTime){
                guide.status = "stowageDone";
                lastestTime = guide.localTimeOne;
            }
            if(guide.truckArrangeTime && guide.truckArrangeTime >= lastestTime){
                guide.status = "TruckArranged";
                lastestTime = guide.truckArrangeTime;
            }
            if(guide.otherTimeOne && guide.otherTimeOne >= lastestTime){
                guide.status = "wmsIn";
                lastestTime = guide.otherTimeOne;
            }
            if(guide.otherTimeTwo && guide.otherTimeTwo >= lastestTime){
                guide.status = "wmsOut";
                lastestTime = guide.otherTimeTwo;
            }
            if(guide.ctnsLoadedTime && guide.ctnsLoadedTime >= lastestTime){
                guide.status = (guide.isExport)?"CtnStuffed":"CtnUnloaded";
                lastestTime = guide.ctnsLoadedTime;
            }
            if(guide.customArrangeTime && guide.customArrangeTime >= lastestTime){
                guide.status = "CustomArranged";
                lastestTime = guide.customArrangeTime;
            }
            if(guide.vgmTime && guide.vgmTime >= lastestTime){
                guide.status = "vgmSent";
                lastestTime = guide.vgmTime;
            }
            if(guide.customsInspectionExp){
                guide.status = "customsInspectionExp";
            }
            if(guide.customReleaseTime && guide.customReleaseTime >= lastestTime){
                guide.status = "CustomReleased";
                lastestTime = guide.customReleaseTime;
            }
            if(guide.atd && guide.atd >= lastestTime){
                guide.status = "atd";
                lastestTime = guide.atd;
            }
            if(guide.plConfirmTime && guide.plConfirmTime >= lastestTime){
                guide.status = "plConfirmed";
                lastestTime = guide.plConfirmTime;
            }
            if(guide.siSubmitedTime && guide.siSubmitedTime >= lastestTime){
                guide.status = "SiSubmitted";
                lastestTime = guide.siSubmitedTime;
            }
            if(guide.hblConfirmedTime && guide.hblConfirmedTime >= lastestTime){
                guide.status = "HblConfirmed";
                lastestTime = guide.hblConfirmedTime;
            }
            if(guide.mblConfirmedTime && guide.mblConfirmedTime >= lastestTime){
                guide.status = "MblConfirmed";
                lastestTime = guide.mblConfirmedTime;
            }
            if(guide.dnSentTime && guide.dnSentTime >= lastestTime){
                guide.status = "DnSent";
                lastestTime = guide.dnSentTime;
            }
            if(guide.saSentTime && guide.saSentTime >= lastestTime){
                guide.status = (guide.isExport)?"SaSent":"anSent";
                lastestTime = guide.saSentTime;
            }
            if(guide.customsInspection){
                guide.status = "customsInspection";
            }
            if(guide.customClearTime && guide.customClearTime >= lastestTime){
                guide.status = "customCleared";
                lastestTime = guide.customClearTime;
            }
            if(guide.ataTime && guide.ataTime >= lastestTime){
                guide.status = "arrivedPod";
                lastestTime = guide.ataTime;
            }
            if(guide.ctnAvailableTime && guide.ctnAvailableTime >= lastestTime){
                guide.status = "ctnAvailable";
                lastestTime = guide.ctnAvailableTime;
            }
            if(guide.otherTimeThree && guide.otherTimeThree >= lastestTime){
                guide.status = "transferOut";
                lastestTime = guide.otherTimeThree;
            }
            if(guide.deliveryAppointmentTime && guide.deliveryAppointmentTime >= lastestTime){
                guide.status = "deliveryAppointed";
                lastestTime = guide.deliveryAppointmentTime;
            }
            if(guide.orderNodes){
                angular.forEach(guide.orderNodes, function(orderNode){
                    if(orderNode.nodeTime && new Date(orderNode.nodeTime) >= new Date(lastestTime)){
                        guide.status = orderNode.nodeName;
                        lastestTime = orderNode.nodeTime;
                    }
                });
            }
            if(guide.deliveryTime){
                guide.status = "Deliveried";
                lastestTime = guide.deliveryTime;
            }
            if(guide.ctnReturnTime){
                guide.status = "ctnReturned";
                lastestTime = guide.ctnReturnTime;
            }
            if(guide.localTimeTwo){
                guide.status = "Cancelled";
                lastestTime = guide.lastModifiedTime;
            }
            if((guide.isVoid)){
                guide.status = "voided";
                lastestTime = guide.lastModifiedTime;
            }
            if((guide.isClosed)){
                guide.status = "isClosed";
                lastestTime = guide.lastModifiedTime;
            }
            if (!lastestTime || lastestTime == true) {
                lastestTime = guide.lastModifiedTime;
            }
            return lastestTime;
        }


        function getTmsPcNodeField(shipment, node) {
            if ($rootScope.IS_TMS(shipment.shipmentType)) {
                switch (node) {
                    case "submited":
                        return null;
                    case "confirm":
                        return "otherTimeOne";
                    case "sendDn":
                        return "dnSentTime";
                    case "pickup":
                        return "otherTimeTwo";
                    case "offShelf":
                        return "offShelfTime";
                    case "wmsIn":
                        return "otherTimeThree";
                    case "deliveried":
                    case "Deliveried":
                    case "delivery":
                        return "deliveryTime";
                    case "wmsOut":
                        return "customReleaseTime";
                    case "costInput":
                        return "costInputTime";
                    case "podConfirm":
                        return "podTime";
                    default:
                        return null;
                }
            } else {
                switch (node) {
                    case "submited":
                        return null;
                    case "checkIn":
                        return "otherTimeOne";
                    case "docment":
                        return "otherTimeTwo";
                    case "offShelf":
                        return "offShelfTime";
                    case "checkOut":
                        return "otherTimeThree";
                    case "customRls":
                        return "customReleaseTime";
                    case "deliveried":
                    case "delivery":
                    case "Deliveried":
                        return "deliveryTime";
                    case "dnDone":
                        return "dnSentTime";
                    default:
                        return null;
                }
            }
        }

        function getGuideNodeName(tag) {
            if (tag == "Booked") {
                return "bookingTime";
            } else if (tag == "SoReleased" || tag == "DoReleased") {
                return "soReleaseTime";
            } else if (tag == "stowageDone") {
                return "localTimeOne";
            } else if (tag == "vgmSent") {
                return "vgmTime";
            } else if (tag == "TruckArranged") {
                return "truckArrangeTime";
            } else if (tag == "CtnStuffed" || tag == "CtnUnloaded") {
                return "ctnsLoadedTime";
            } else if (tag == "CustomArranged") {
                return "customArrangeTime";
            } else if (tag == "CustomReleased") {
                return "customReleaseTime";
            } else if (tag == "plConfirmed") {
                return "plConfirmTime";
            } else if (tag == "customCleared") {
                return "customClearTime";
            } else if (tag == "SiReceived") {
                return "siReceivedTime";
            } else if (tag == "SiSubmitted") {
                return "siSubmitedTime";
            } else if (tag == "HblConfirmed" || tag == "HawbConfirmed") {
                return "hblConfirmedTime";
            } else if (tag == "customsInspection") {
                return "customsInspection";
            } else if (tag == "customsInspectionExp") {
                return "customsInspectionExp";
            } else if (tag == "MblConfirmed" || tag == "MawbConfirmed") {
                return "mblConfirmedTime";
            } else if (tag == "SaSent") {
                return "saSentTime";
            } else if (tag == "Cancelled") {
                return "localTimeTwo";
            } else if (tag == "anSent") {
                return "saSentTime";
            } else if (tag == "DnSent") {
                return "dnSentTime";
            } else if (tag == "wmsIn") {
                return "otherTimeOne";
            } else if (tag == "atd") {
                return "atd";
            } else if (tag == "wmsOut") {
                return "otherTimeTwo";
            } else if (tag == "transferOut") {
                return "otherTimeThree";
            } else if (tag == "Delivering") {
                return "isDelivering";
            } else if (tag == "Deliveried") {
                return "deliveryTime";
            } else if (tag == "arrivedPod") {
                return "ataTime";
            } else if (tag == "deliveryAppointed") {
                return "deliveryAppointmentTime";
            } else if (tag == "ctnAvailable") {
                return "ctnAvailableTime";
            } else if (tag == "isClosed") {
                return "closeTime";
            } else if (tag == "voided") {
                return "isVoid";
            } else if (tag == "paymentReceived") {
                return "paymentReceivedTime";
            } else if (tag == "FreightPaid") {
                return "paymentPaidTime";
            } else if (tag == "InvoiceReceived") {
                return "invoiceReceivedTime";
            } else if (tag == "InvoiceIssued") {
                return "invoiceIssuedTime";
            } else if (tag == "MblReleased" || tag == "MawbReleased") {
                return "mblReleasedTime";
            } else if (tag == "HblReleased" || tag == "HawbReleased") {
                return "hblReleasedTime";
            } else if (tag == "ctnReturned") {
                return "ctnReturnTime";
            }
            return tag;
        }

        function fillGuideFilter(gridCopy, filter) {
            if (!gridCopy) {
                return;
            }
            angular.forEach(gridCopy.columns, function(column) {
                angular.forEach(column.filters, function(cf) {
                    if (cf.term != null && angular.isDefined(cf.term)) {
                        if (cf.condition) {
                            var fd = column.field;
                            if (fd.endWith("Str")) {
                                fd = fd.replace("Str", "");
                            }
                            if (cf.condition == 128 || cf.condition == 256) { //" < ",
                                if (column.field == "etd" || column.field == "eta" || column.field == "deliveryDate" || column.field == "cargoReadyDate" || column.field == "bookingEtd" ||
                                    column.field == "feederEtd" || column.field == "opDate" || column.field == "atd" || column.field == "billDate") {
                                    filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                } else {
                                    filter[fd + "TO"] = cf.term;
                                }
                            } else if (cf.condition == 32 || cf.condition == 64) { //" > ",
                                if (column.field == "etd" || column.field == "eta" || column.field == "deliveryDate" || column.field == "cargoReadyDate" || column.field == "bookingEtd" ||
                                    column.field == "feederEtd" || column.field == "opDate" || column.field == "atd" || column.field == "billDate") {
                                    filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                } else {
                                    filter[fd + "FM"] = cf.term;
                                }
                            }
                        } else {
                            filter[column.field] = cf.term;
                        }
                    }
                })
            })
        }

        function removeTag(data) {
            if (data.endWith('TAG')) {
                return data.replace('TAG', '');
            }
            return data;
        }

        function addParcelTag(data) {
            data.otherTimeOneTAG = data.otherTimeOne != null;
            data.otherTimeThreeTAG = data.otherTimeThree != null;
            data.otherTimeTwoTAG = data.otherTimeTwo != null;
            data.pickingNumTAG = data.pickingNum != null;
            data.pickupTimeTAG = data.pickupTime != null;
            if ($rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')) {
                data.shipperName = "******";
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_COLOADER')) {
                data.bookingToName = "******";
                data.bookingToCode = "******";
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_AGENT')) {
                data.deliveryName = "******";
            }
            if ($rootScope.HAVENOAUTH('AU_VIEW_INNER_REMARKS')) {
                data.innerRemarks = "******";
            }
            fillFdLabel(data);
            if($rootScope.account.companyId && data.companyId
                && $rootScope.account.companyId != data.companyId){
                angular.copy({}, data);
                layer.msg($translate.instant('global.invalidRecord'));
            }
        }

        function addVenderTag(resultList) {
            angular.forEach(resultList, function(data) {
                data.userIdTAG = data.userId != null;
            })
        }

        function addGuideTag(data) {
            data.ataTimeTAG = data.ataTime != null;
            data.bookingTimeTAG = data.bookingTime != null;
            data.ctnsLoadedTimeTAG = data.ctnsLoadedTime != null;
            data.customArrangeTimeTAG = data.customArrangeTime != null;
            data.customClearTimeTAG = data.customClearTime != null;
            data.customReleaseTimeTAG = data.customReleaseTime != null;
            data.deliveryAppointmentTimeTAG = data.deliveryAppointmentTime != null;
            data.deliveryTimeTAG = data.deliveryTime != null;
            data.pickupTimeTAG = data.pickupTime != null;
            data.dnSentTimeTAG = data.dnSentTime != null;
            data.hblConfirmedTimeTAG = data.hblConfirmedTime != null;
            data.hblReleasedTimeTAG = data.hblReleasedTime != null;
            data.localTimeOneTAG = data.localTimeOne != null;
            data.localTimeTwoTAG = data.localTimeTwo != null;
            data.mblConfirmedTimeTAG = data.mblConfirmedTime != null;
            data.mblReleasedTimeTAG = data.mblReleasedTime != null;
            data.otherTimeOneTAG = data.otherTimeOne != null;
            data.otherTimeThreeTAG = data.otherTimeThree != null;
            data.otherTimeTwoTAG = data.otherTimeTwo != null;
            data.paymentPaidTimeTAG = data.paymentPaidTime != null;
            data.paymentReceivedTimeTAG = data.paymentReceivedTime != null;
            data.plConfirmTimeTAG = data.plConfirmTime != null;
            data.saSentTimeTAG = data.saSentTime != null;
            data.siSubmitedTimeTAG = data.siSubmitedTime != null;
            data.soReleaseTimeTAG = data.soReleaseTime != null;
            data.truckArrangeTimeTAG = data.truckArrangeTime != null;
            data.vgmTimeTAG = data.vgmTime != null;
            data.invoiceIssuedTimeTAG = data.invoiceIssuedTime != null;
            data.invoiceReceivedTimeTAG = data.invoiceReceivedTime != null;
            data.ctnAvailableTimeTAG = data.ctnAvailableTime != null;
            data.branchGuideIdTAG = data.branchGuideId != null;
            data.hmMark = null;
            if((data.parcelMaster || data.masterId == 0) && (data.parcelId || data.masterId)){
                data.hmMark = "M&H";
            }
            else if (data.parcelMaster || data.masterId == 0) {
                data.hmMark = "M";
            } else if (data.parcelId || data.masterId) {
                data.hmMark = "H";
            }

            if ($rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')) {
                data.customerName = "******";
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')) {
                data.shipperName = "******";
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_COLOADER')) {
                data.bookingToName = "******";
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_AGENT')) {
                data.deliveryName = "******";
            }
            if ($rootScope.HAVENOAUTH('AU_VIEW_INNER_REMARKS')) {
                data.remarks = "******";
            }
            if ($rootScope.HAVENOAUTH('AU_VIEW_PROFIT') || $rootScope.HAVEAUTH('FB_VIEW_LIST_GP')) {
                data.profit = null;
                data.gp = null;
            }
            if (!data.checkStatus) {
                data.checkStatus = "notChecked";
            }
            if (!data.isApplied) {
                data.isApplied = false;
            }
            if (!data.mblOnly) {
                data.mblOnly = false;
            }
            data.encodeId = WinAES.encode(data.id.toString());
            data.nodeTime = getGuideNodeTime(data);
            var names = Object.getOwnPropertyNames(data);
            angular.forEach(names, function(fd) {
                if (new Date(data[fd])) {
                    data[fd + "Str"] = DateUtils.convertDateTimeToString(data[fd]);
                }
            })
            fillFdLabel(data);
            fillGuideShowStatus(data);
            if($rootScope.account.companyId && data.companyId
                && $rootScope.account.companyId != data.companyId){
                angular.copy({}, data);
                layer.msg($translate.instant('global.invalidRecord'));
            }
            fillCtnNums(data);
        }

        function fillCtnNums(data) {
            if(!data || !data.ctnsList) {
                return;
            }
            var ctnNums = "";
            angular.forEach(data.ctnsList, function(ctnNum) {
                if (ctnNum) {
                    ctnNums += ctnNum + " ";
                }
            });
            data.ctnNums = ctnNums;
        }

        function fillFdLabel(data) {
            angular.forEach(GET_LABEL_TYPES, function(fd) {
                angular.forEach($rootScope[angular.uppercase(fd) + "S"], function(opt) {
                    if (opt.value == data[fd]) {
                        data[fd + "Label"] = opt.label;
                    }
                })
            });
        }

        function isPdf(fileName) {
            if (!fileName) {
                return false;
            }
            var index = fileName.lastIndexOf(".");
            return ".pdf" == angular.lowercase(fileName.substring(index));
        }

        function isPic(fileName) {
            if (!fileName) {
                return false;
            }
            var index = fileName.lastIndexOf(".");
            return ".png" == angular.lowercase(fileName.substring(index)) || ".jpg" == angular.lowercase(fileName.substring(index)) ||
                ".jpeg" == angular.lowercase(fileName.substring(index)) || ".bmp" == angular.lowercase(fileName.substring(index));
        }

        function getSelectedFields(obj, fdList) {
            var outList = [];
            angular.forEach(fdList, function(fd) {
                if (obj[fd.value] && fd.value) {
                    outList.push(fd.value);
                }
            })
            return outList;
        }

        function sortList(inList, fd, isReverse) {
            var nullValList = [];
            var sortList = [];
            angular.forEach(inList, function(data) {
                if (!data[fd] && data[fd] != 0) {
                    nullValList.push(data);
                } else {
                    sortList.push(data);
                }
            })
            sortList.sort(function(a, b) {
                if (isReverse) {
                    if (isNaN(a[fd]) && isNaN(b[fd])) {
                        return a[fd] < b[fd] ? 1 : -1;
                    }
                    return b[fd] - a[fd];
                } else {
                    if (isNaN(a[fd]) && isNaN(b[fd])) {
                        return a[fd] > b[fd] ? 1 : -1;
                    }
                    return a[fd] - b[fd];
                }
            });
            angular.forEach(nullValList, function(data) {
                sortList.push(data);
            })
            return sortList;
        }

        function preRound(temp, fixed) {
            var pos = temp.toString().indexOf('.'),
                decimal_places = temp.toString().length - pos - 1,
                _int = temp * Math.pow(10, decimal_places),
                divisor_1 = Math.pow(10, decimal_places - fixed),
                divisor_2 = Math.pow(10, fixed);
            _int = _int.toFixed(0) - 0;
            return Math.round(_int / divisor_1) / divisor_2;
        }

        function round(num, fixed) {
            if((num + "") == "-"){
                return num;
            }
            if (null == num || isNaN(num)) {
                return null;
            }
            if((num + "").endsWith(".")){
                return num;
            }
            if(!fixed && fixed != 0 ){
                fixed = 2;
            }
            var temp = Math.abs(num);
            var pos = temp.toString().indexOf('.');
            if (temp.toString().length > (pos + 6 + fixed) &&
                temp.toString().substring(pos + 2 + fixed).indexOf("999999") == 0) {
                temp = preRound(temp, fixed + 1);
                var posNew = temp.toString().indexOf('.');
                if (posNew == -1 || temp.toString().length <= (posNew + 1 + fixed)) {
                    if (num < 0) {
                        temp = -temp;
                    }
                    return temp;
                }
            }
            var decimal_places = temp.toString().length - pos - 1,
                _int = temp * Math.pow(10, decimal_places),
                divisor_1 = Math.pow(10, decimal_places - fixed),
                divisor_2 = Math.pow(10, fixed);
            _int = _int.toFixed(0) - 0;
            temp = Math.round(_int / divisor_1) / divisor_2;
            if (num < 0) {
                temp = -temp;
            }
            return temp - 0;
        }

        function updateByFclCtn(guide) {
            var newGwTotal = 0;
            var newVolTotal = 0;
            var newPkgsTotal = 0;
            var isSamePkg = true;
            var pkgsCopy = null;

            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.gw) {
                    newGwTotal = newGwTotal + data.gw;
                }
                if (data.vol) {
                    newVolTotal = newVolTotal + data.vol;
                }
                if (data.pkgNum) {
                    newPkgsTotal = newPkgsTotal + data.pkgNum;
                }
                if (data.pkgType) {
                    if (pkgsCopy) {
                        if (pkgsCopy != data.pkgType) {
                            isSamePkg = false;
                        }
                    } else {
                        pkgsCopy = data.pkgType;
                    }
                }
            });
            if(!guide.cwLoked){
                guide.gw = newGwTotal.toFixed(getGwPrecision(guide)) - 0;
                guide.vol = newVolTotal.toFixed(getVolPrecision(guide)) - 0;
            }
            guide.pkgNum = newPkgsTotal.toFixed(0) - 0;
            if (!isSamePkg) {
                guide.booking.pkgType = "PKGS";
            } else {
                guide.booking.pkgType = pkgsCopy;
            }
            updateGuideCw(guide);
        }

        function getGwPrecision(guide) {
            var threePrecisionComIds = [308, 54, 388, 647, 162];
            if (threePrecisionComIds.indexOf(guide.companyId) !== -1) {
                return 3;
            }
            return 2;
        }

        function getVolPrecision(guide) {
            var fbaWmsTypes = ["EXPRESS", "FBASEA", "FBAAIR", "FBARAIL", "WMS", "LOCAL", "LOCALKG"];
            var threePrecisionComIds = [270, 271, 276, 297, 376, 406, 412, 416];
            if (fbaWmsTypes.indexOf(guide.shipmentType) == -1) {
                return 3;
            }
            if (threePrecisionComIds.indexOf(guide.companyId) !== -1) {
                return 3;
            }
            return 4;
        }

        function updateGuidePl(guide, isForce) {
            var newGwTotal = 0;
            var newVolTotal = 0;
            var newPkgsTotal = 0;
            var newPltTotal = 0;
            var isSamePkg = true;
            var pkgsCopy = null;

            angular.forEach(guide.booking.bookingCtns, function(data) {
                if (!data.vol && data.length && data.width && data.height) {
                    data.vol = data.length * data.width * data.height / 1000000;
                    data.vol = round(data.vol, 5);
                }
                if (data.vol && data.pkgNum && !data.volTtl) {
                    data.volTtl = round(data.vol * data.pkgNum, 5);
                }
                if (data.gw && data.pkgNum && !data.gwTtl) {
                    data.gwTtl = round(data.gw * data.pkgNum, 2);
                }
                if (data.volTtl) {
                    newVolTotal = newVolTotal + data.volTtl;
                }
                if (data.gwTtl) {
                    newGwTotal = newGwTotal + data.gwTtl;
                }
                if (data.nw && data.pkgNum != null) {
                    data.nwTtl = data.nw * data.pkgNum;
                }
                if (data.pkgNum) {
                    newPkgsTotal = newPkgsTotal + data.pkgNum;
                }
                if (data.pallets) {
                    newPltTotal += data.pallets;
                }
                if (data.pkgType) {
                    if (pkgsCopy) {
                        if (pkgsCopy != data.pkgType) {
                            isSamePkg = false;
                        }
                    } else {
                        pkgsCopy = data.pkgType;
                    }
                }
                data.dutyAmt = getDuty(data).dutyAmt;
            })

            if (guide.dontSynTtl && !isForce) {
                return;
            }
            guide.booking.gw = newGwTotal.toFixed(getGwPrecision(guide)) - 0;
            guide.booking.vol = newVolTotal.toFixed(getVolPrecision(guide)) - 0;
            guide.booking.pkgNum = newPkgsTotal.toFixed(0) - 0;
            if (!guide.dontSynTtl) {
                guide.pkgNum = newPkgsTotal.toFixed(0) - 0;
                guide.palletNum = newPltTotal.toFixed(0) - 0;
                if (!isSamePkg) {
                    guide.booking.pkgType = "PKGS";
                } else {
                    guide.booking.pkgType = pkgsCopy;
                }
                if(!guide.cwLoked){
                    guide.gw = newGwTotal.toFixed(getGwPrecision(guide)) - 0;
                    guide.vol = newVolTotal.toFixed(getVolPrecision(guide)) - 0;
                    updateGuideCw(guide);
                }
            }
        }
        function updateGuideWmsCw(guide) {
            var wmsGw = guide.wmsGw;
            var wmsVol = guide.wmsVol;
            if (wmsGw == null) { wmsGw = 0; }
            if (wmsVol == null) { wmsVol = 0; }
            if (guide.chargeByCbm) {
                guide.wmsCw = wmsGw / guide.booking.divVolBy;
                if (guide.wmsCw < wmsVol) {
                    guide.wmsCw = wmsVol;
                } else if (guide.booking.volumeShare) {
                    guide.wmsCw = round(guide.wmsCw - (guide.wmsCw - wmsVol) * guide.booking.volumeShare / 100, 3);
                } else {
                    guide.wmsCw = round(guide.wmsCw, 3);
                }
            } else {
                guide.wmsCw = wmsVol * 1000000 / guide.booking.divVolBy;
                if (guide.wmsCw < wmsGw) {
                    guide.wmsCw = round(wmsGw, 3);
                } else if (guide.booking.volumeShare) {
                    guide.wmsCw = round(guide.wmsCw - (guide.wmsCw - wmsGw) * guide.booking.volumeShare / 100, 3);
                } else {
                    guide.wmsCw = round(guide.wmsCw, 3);
                }
            }
        }

        function updateGuideCw(guide) {
            var gw = guide.gw;
            var vol = guide.vol;

            if (gw == null) { gw = 0; }
            if (vol == null) { vol = 0; }


            if (!guide.mblConfirmedTime) {
                if(guide.shipmentType != "AIR"){
                    guide.gwVol = gw.toFixed(getGwPrecision(guide)) + "KGS" + "\n" + vol.toFixed(getVolPrecision(guide)) + "CBM";
                }
                else {
                    guide.gwVol = 'VOL: '+ vol.toFixed(getVolPrecision(guide)) +" CBM";
                    guide.airGw = gw;
                }
                if(guide.pkgNum){
                    guide.pkgsAndType = guide.pkgNum + " " + guide.booking.pkgType;
                }
            }

            if (!guide.hblConfirmedTime) {
                if(guide.shipmentType != "AIR"){
                    guide.booking.gwVol = gw.toFixed(getGwPrecision(guide)) + "KGS" + "\n" + vol.toFixed(getVolPrecision(guide)) + "CBM";
                }
                else {
                    guide.booking.gwVol = 'VOL: '+ vol.toFixed(getVolPrecision(guide)) +" CBM";
                    guide.booking.airGw = gw;
                }
                if(guide.pkgNum){
                    guide.booking.pkgsAndType = guide.pkgNum + " " + guide.booking.pkgType;
                }
            }

            if (guide.booking.divVolBy == null) {
                if (guide.chargeByCbm) {
                    guide.booking.divVolBy = 1000;
                } else {
                    guide.booking.divVolBy = 6000;
                }
            }

            if(guide.cwLoked){
                return;
            }
            if (guide.chargeByCbm) {
                guide.chargeWeight = gw / guide.booking.divVolBy;
                guide.vw = guide.chargeWeight;
                if (guide.chargeWeight < vol) {
                    guide.chargeWeight = vol;
                } else if (guide.booking.volumeShare) {
                    guide.chargeWeight = round(guide.vw - (guide.vw - vol) * guide.booking.volumeShare / 100, 3);
                } else {
                    guide.chargeWeight = round(guide.vw, 3);
                }
            } else {
                guide.chargeWeight = vol * 1000000 / guide.booking.divVolBy;
                guide.vw = guide.chargeWeight;
                if (guide.chargeWeight < gw) {
                    guide.chargeWeight = round(gw, 3);
                } else if (guide.booking.volumeShare) {
                    guide.chargeWeight = round(guide.vw - (guide.vw - gw) * guide.booking.volumeShare / 100, 3);
                } else {
                    guide.chargeWeight = round(guide.vw, 3);
                }
            }

            if (guide.chargeWeight) {
                guide.chargeWeight = guide.chargeWeight.toFixed(3) - 0;
                guide.awbWeight = guide.chargeWeight.toFixed(3) - 0;
                guide.booking.awbWeight = guide.chargeWeight.toFixed(3) - 0;
            }
            if (guide.vw) {
                guide.vw = guide.vw.toFixed(3) - 0;
            }
            if(guide.deliveryChannel){
                clearTimeout(filterTimer);
                filterTimer = setTimeout(function(){
                   $rootScope.GET_CHARGE_WEIGHT(guide);
                }, 1000);//查询延时时间，单位ms
            }
        }

        function updateStatParams(inParams) {
            if (inParams.statType != "irregular") {
                inParams.etdFM = null;
                inParams.etdTO = null;
            }
            if (inParams.statType == "year" && DateUtils.isValidDate(inParams.year)) {
                var year = inParams.year.getFullYear(); //取当前的年份
                inParams.etdFM = new Date(year, 0, 1);
                inParams.etdTO = new Date(year, 11, 31);
            } else if (inParams.statType == "month" && DateUtils.isValidDate(inParams.month)) {
                var year = inParams.month.getFullYear(); //取当前的年份
                var month = inParams.month.getMonth();
                if (month == 11) {
                    month = month - 12;
                    year++;
                }
                inParams.etdFM = new Date(year, month, 1);
                inParams.etdTO = new Date(year, month + 1, 0);
            } else if (inParams.statType == "day" && DateUtils.isValidDate(inParams.day)) {
                inParams.etdFM = inParams.day;
                inParams.etdTO = inParams.day;
            }
            if (!inParams.deliveryToPh) {
                inParams.deliveryId = null;
            }
            if (!inParams.coloaderNamePh) {
                inParams.bookingToId = null;
            }
            if (!inParams.clientNamePh) {
                inParams.shipperId = null;
            }
            if (!inParams.customerNamePh) {
                inParams.customerId = null;
            }
            if (!inParams.clearanceAgentPH) {
                inParams.clearanceAgentId = null;
            }
            if (!inParams.trucker) {
                inParams.truckerId = null;
            }
            if (!inParams.broker) {
                inParams.brokerId = null;
            }
            if (!inParams.payer) {
                inParams.payerVid = null;
            }
            if (!inParams.warehouseName) {
                inParams.warehouseId = null;
            }
            if (inParams.marketList && inParams.marketList.length > 0) {
                inParams.marketIds = [];
                angular.forEach(inParams.marketList, function(data) {
                    inParams.marketIds.push(data.id);
                })
            } else {
                inParams.marketIds = null;
            }

            if (inParams.finList && inParams.finList.length > 0) {
                inParams.finIds = [];
                angular.forEach(inParams.finList, function(data) {
                    inParams.finIds.push(data.id);
                })
            } else {
                inParams.finIds = null;
            }
            if (inParams.overseasCsList && inParams.overseasCsList.length > 0) {
                inParams.overseasCsIds = [];
                angular.forEach(inParams.overseasCsList, function(data) {
                    inParams.overseasCsIds.push(data.id);
                })
            } else {
                inParams.overseasCsIds = null;
            }

            if (inParams.docList && inParams.docList.length > 0) {
                inParams.docIds = [];
                angular.forEach(inParams.docList, function(data) {
                    inParams.docIds.push(data.id);
                })
            } else {
                inParams.docIds = null;
            }

            if (inParams.opList && inParams.opList.length > 0) {
                inParams.opIds = [];
                angular.forEach(inParams.opList, function(data) {
                    inParams.opIds.push(data.id);
                })
            } else {
                inParams.opIds = null;
            }

            if (inParams.csList && inParams.csList.length > 0) {
                inParams.cusIds = [];
                angular.forEach(inParams.csList, function(data) {
                    inParams.cusIds.push(data.id);
                })
            } else {
                inParams.cusIds = null;
            }

            if (inParams.salesList && inParams.salesList.length > 0) {
                inParams.salesIds = [];
                angular.forEach(inParams.salesList, function(data) {
                    inParams.salesIds.push(data.id);
                })
            } else {
                inParams.salesIds = null;
            }
            if (inParams.customByList && inParams.customByList.length > 0) {
                inParams.customByIds = [];
                angular.forEach(inParams.customByList, function(data) {
                    inParams.customByIds.push(data.id);
                })
            } else {
                inParams.customByIds = null;
            }
            if (inParams.truckByList && inParams.truckByList.length > 0) {
                inParams.truckByIds = [];
                angular.forEach(inParams.truckByList, function(data) {
                    inParams.truckByIds.push(data.id);
                })
            } else {
                inParams.truckByIds = null;
            }

            if (inParams.bookingByList && inParams.bookingByList.length > 0) {
                inParams.bookingByIds = [];
                angular.forEach(inParams.bookingByList, function(data) {
                    inParams.bookingByIds.push(data.id);
                })
            } else {
                inParams.bookingByIds = null;
            }
            if (inParams.polAreas && inParams.polAreas.length > 0) {
                inParams.polRegions = [];
                angular.forEach(inParams.polAreas, function(data) {
                    inParams.polRegions.push(data.id);
                })
            } else {
                inParams.podRegions = null;
            }
            if (inParams.podAreas && inParams.podAreas.length > 0) {
                inParams.podRegions = [];
                angular.forEach(inParams.podAreas, function(data) {
                    inParams.podRegions.push(data.id);
                })
            } else {
                inParams.podRegions = null;
            }
            if ($rootScope.HAVEAUTH('WMS_VIRTUAL')) {
                inParams.haveVirtualWms = true;
            }
        }

        function sortShipprBl(a, b, filterStr) {
            filterStr = angular.lowercase(filterStr);
            if (a.companyName && angular.lowercase(a.companyName).indexOf(filterStr) != -1) {
                return -1;
            }
            if (b.companyName && angular.lowercase(b.companyName).indexOf(filterStr) != -1) {
                return 1;
            }
            if (a.blShow && angular.lowercase(a.blShow).indexOf(filterStr) != -1) {
                return -1;
            }
            if (b.blShow && angular.lowercase(b.blShow).indexOf(filterStr) != -1) {
                return 1;
            }
            if (a.einNum && angular.lowercase(a.einNum).indexOf(filterStr) != -1) {
                return -1;
            }
            if (b.einNum && angular.lowercase(b.einNum).indexOf(filterStr) != -1) {
                return 1;
            }
            if (a.bondNum && angular.lowercase(a.bondNum).indexOf(filterStr) != -1) {
                return -1;
            }
            if (b.bondNum && angular.lowercase(b.bondNum).indexOf(filterStr) != -1) {
                return 1;
            }
            return 1;
        }

        function getRatePayer(rate, shipmentType, guide) {
            if (rate.venderId == -1) {
                rate.venderId = guide.customerId;
                rate.payer = guide.customerName;
            } else if (rate.venderId == -2) {
                rate.venderId = guide.shipperId;
                rate.payer = guide.shipperName;
                if (shipmentType != "guide" &&
                    guide.isVirtual && guide.medCompanyId != $rootScope.account.companyId) {
                    rate.venderId = guide.medVenderId;
                    rate.payer = guide.medCompany;
                }
            } else if (rate.venderId == -3) {
                rate.venderId = guide.bookingToId;
                rate.payer = guide.bookingToName;
            } else if (rate.venderId == -4) {
                rate.venderId = guide.deliveryId;
                rate.payer = guide.deliveryName;
            } else if (rate.venderId == -5) {
                if (guide.booking && guide.booking.bookingTrucks && guide.booking.bookingTrucks.length > 0) {
                    rate.venderId = guide.booking.bookingTrucks[0].truckerVid;
                    rate.payer = guide.booking.bookingTrucks[0].truckerName;
                }
            } else if (rate.venderId == -6) {
                if (guide.bookingCustoms && guide.bookingCustoms.length > 0) {
                    rate.venderId = guide.bookingCustoms[0].brokerVid;
                    rate.payer = guide.bookingCustoms[0].brokerName;
                }
            } else if (rate.venderId == -7) {
                rate.venderId = guide.warehouseVid;
                rate.payer = guide.warehouseName;
            } else if (rate.venderId == -8) {
                rate.venderId = guide.traderVid;
                rate.payer = guide.traderName;
            } else if (rate.venderId == -9) {
                rate.venderId = guide.insuranceVid;
                rate.payer = guide.insuranceName;
            } else if (rate.venderId == -10) {
                rate.venderId = guide.fumigationVid;
                rate.payer = guide.fumigationName;
            } else if (rate.venderId == -11) {
                rate.venderId = guide.clearanceAgentId;
                rate.payer = guide.clearanceAgent;
            }
            return rate;
        }

        function getUnitCount(rate, shipmentType, guide) {
            var outNum;
            var gw = guide.gw;
            var vol = guide.vol;
            var chargeWeight = shipmentType == 'guide' ? guide.chargeWeight : guide.vw;
            if(rate.isPay && guide.costByWmsdata){
                gw = guide.wmsGw;
                vol = guide.wmsVol;
                chargeWeight = guide.wmsCw;
            }
            var pkgNum = shipmentType == 'guide' ? guide.pkgNum : guide.applicationNum;
            var awbWeight = shipmentType == 'guide' ? guide.awbWeight : guide.vw;
            var vw = shipmentType == 'guide' ? guide.vw : guide.vw;
            var pallets = shipmentType == 'guide' ? guide.palletNum : guide.pallets;
            var pieces = shipmentType == 'guide' ? guide.pieces : guide.pieces;

            if (rate.unit == "BL" || rate.unit == "JOB" || rate.unit == "SHIPMENT") {
                outNum = 1;
            } else if (rate.unit == "VALUE") {
                if (guide.booking && guide.cargoValue) {
                    outNum = guide.cargoValue;
                }
            } else if (rate.unit == "QTY") {
                if (guide.booking && guide.pieces) {
                    outNum = guide.pieces;
                }
            } else if (rate.unit == "KG") {
                outNum = gw;
            } else if (rate.unit == "KG05") {
                if (!gw) {
                    outNum = 0;
                } else if (gw <= 1) {
                    outNum = 1;
                } else {
                    var num = gw - parseInt(gw);
                    if (num) {
                        if (num <= 0.5) {
                            outNum = parseInt(gw) + 0.5;
                        } else {
                            outNum = parseInt(gw) + 1;
                        }
                    } else {
                        outNum = gw;
                    }
                }
            } else if (rate.unit == "KG01") {
                if (!gw) {
                    outNum = 0;
                } else if (gw <= 1) {
                    outNum = 1;
                } else {
                    var num = gw - parseInt(gw);
                    if (num) {
                        outNum = parseInt(gw) + 1;
                    } else {
                        outNum = gw;
                    }
                }
            } else if (rate.unit == "TON") {
                outNum = gw / 1000;
            } else if (rate.unit == "CBM") {
                outNum = vol;
            } else if (rate.unit == "VW") {
                outNum = vw;
            } else if (rate.unit == "PIECE") {
                outNum = pieces;
            } else if (rate.unit == "PALLET") {
                outNum = pallets;
            } else if (rate.unit == "CW") {
                outNum = chargeWeight;
            }
            else if (rate.unit == "VW1") {
                if (!vw) {
                    outNum = 0;
                } else if (vw <= 1) {
                    outNum = 1;
                } else {
                    var num = vw - parseInt(vw);
                    if (num) {
                        if (num < 0.5) {
                            outNum = parseInt(vw);
                        } else {
                            outNum = parseInt(vw) + 1;
                        }
                    } else {
                        outNum = vw;
                    }
                }
            } else if (rate.unit == "VW05") {
                if (!vw) {
                    outNum = 0;
                } else if (vw <= 1) {
                    outNum = 1;
                } else {
                    var num = vw - parseInt(vw);
                    if (num) {
                        if (num <= 0.5) {
                            outNum = parseInt(vw) + 0.5;
                        } else {
                            outNum = parseInt(vw) + 1;
                        }
                    } else {
                        outNum = vw;
                    }
                }
            } else if (rate.unit == "VW01") {
                if (!vw) {
                    outNum = 0;
                } else if (vw <= 1) {
                    outNum = 1;
                } else {
                    var num = vw - parseInt(vw);
                    if (num) {
                        outNum = parseInt(vw) + 1;
                    } else {
                        outNum = vw;
                    }
                }
            }
            else if (rate.unit == "CW1") {
                if (!chargeWeight) {
                    outNum = 0;
                } else if (chargeWeight <= 1) {
                    outNum = 1;
                } else {
                    var num = chargeWeight - parseInt(chargeWeight);
                    if (num) {
                        if (num < 0.5) {
                            outNum = parseInt(chargeWeight);
                        } else {
                            outNum = parseInt(chargeWeight) + 1;
                        }
                    } else {
                        outNum = chargeWeight;
                    }
                }
            } else if (rate.unit == "CW05") {
                if (!chargeWeight) {
                    outNum = 0;
                } else if (chargeWeight <= 1) {
                    outNum = 1;
                } else {
                    var num = chargeWeight - parseInt(chargeWeight);
                    if (num) {
                        if (num <= 0.5) {
                            outNum = parseInt(chargeWeight) + 0.5;
                        } else {
                            outNum = parseInt(chargeWeight) + 1;
                        }
                    } else {
                        outNum = chargeWeight;
                    }
                }
            } else if (rate.unit == "CW01") {
                if (!chargeWeight) {
                    outNum = 0;
                } else if (chargeWeight <= 1) {
                    outNum = 1;
                } else {
                    var num = chargeWeight - parseInt(chargeWeight);
                    if (num) {
                        outNum = parseInt(chargeWeight) + 1;
                    } else {
                        outNum = chargeWeight;
                    }
                }
            }
            else if (rate.unit == "AWB WEIGHT") {
                outNum = awbWeight;
            } else if (rate.unit == "PKGS") {
                outNum = pkgNum;
            } else if (rate.unit == "MT") {
                if (gw) {
                    outNum = gw / 1000;
                    if (vol && outNum < vol) {
                        outNum = vol;
                    }
                }
            } else if (rate.unit == "RT") {
                if (gw) {
                    outNum = gw / 363;
                    if (vol && outNum < vol) {
                        outNum = vol;
                    }
                }
            } else if (rate.unit == "CONTAINER" || rate.unit == "CTNR") {
                if(shipmentType.indexOf("wms") != -1 && guide.ctnType){
                    return 1;
                }
                outNum = getCtnNumTtl(guide);
                if (!outNum) {
                    return null;
                }
            } else if (rate.unit == "CTNTYPE VAR") {
                ctnTypeVarInput(rate, null, guide, shipmentType);
                return rate.count;
            } else {
                if(shipmentType.indexOf("wms") != -1 && guide.ctnType == rate.unit){
                    return 1;
                }
                outNum = getCtnNum(rate.unit, guide);
                if (!outNum) {
                    return rate.count;
                }
            }

            if (!outNum) {
                outNum = 1;
            } else if (rate.firstWeight) {
                outNum = outNum - rate.firstWeight;
            }
            return round(outNum, 4);
        }

        function rateAmountChange(rate, guide) {
            if (rate.isHidden || rate.locked) {
                return;
            }
            if($rootScope.isDLX(guide.companyId)){
                guide.salesChecked = false;
            }
            if(rate.refType && !rate.autoUpdate){
                rate.autoUpdate = true;
                $rootScope.OPS_ALERT($translate.instant('global.autoChargeDisabled'))
            }
            if (rate.price == null) {
                rate.payAmount = null;
                rate.total = null;
                return;
            }
            // rate.price = round(rate.price, 4);
            if (null == rate.count || rate.count < 0) {
                rate.count = 0.00;
            }
            rate.total = rate.count * rate.price;
            if (rate.days) {
                rate.total = rate.total * rate.days;
            }
            if ((rate.total.toFixed(4) - 0) == (rate.total.toFixed(6) - 0)) {
                rate.total = rate.total.toFixed(4) - 0;
            }
            rate.total = round(rate.total, 2);

            if (!rate.payCurrency) {
                rate.payCurrency = rate.currency;
                rate.payExchange = 1.00;
            }
            if (null == rate.payExchange) {
                rate.payExchange = 1.00;
            }

            var payAmount = rate.payAmount = rate.payExchange * rate.total;
            if (rate.taxInPrice == null) {
                rate.taxInPrice = false;
            }

            var vatRate = 0,
                whtRate = 0;
            if (rate.vat) {
                vatRate = rate.vat;
            }
            if (rate.incomeTax) {
                whtRate = rate.incomeTax;
            }

            if (rate.taxInPrice) {
                if (guide && guide.vat) {
                    var netAmt = payAmount / (1 + vatRate / 100);
                    rate.vatAmt = round(netAmt * vatRate / 100, 2);
                }
                rate.payAmount = payAmount;
            } else {
                if (guide && guide.vat) {
                    rate.vatAmt = round(payAmount * vatRate / 100, 2);
                    rate.payAmount = payAmount + rate.vatAmt;
                }
            }
            if (guide && guide.incomeTax) {
                rate.incomeTaxAmt = round(payAmount * whtRate / 100, 2);
                rate.payAmount = rate.payAmount - rate.incomeTaxAmt;
            }
            if ((rate.payAmount.toFixed(4) - 0) == (rate.payAmount.toFixed(6) - 0)) {
                rate.payAmount = rate.payAmount.toFixed(3) - 0;
            }
            rate.payAmount = round(rate.payAmount, 2);
        }

        function rateMinMaxCheck(rate, item, guide) {
            if (!item) {
                return;
            }
            if (item.min && rate.payAmount < item.min) {
                rate.price = rate.total = rate.payAmount = item.min;
                rate.unit = "SHIPMENT";
                rate.count = 1;
                rate.remarks = "Minimum Charge";
                rateAmountChange(rate, guide);
            } else if (item.max && rate.payAmount > item.max) {
                rate.price = rate.total = rate.payAmount = item.max;
                rate.unit = "SHIPMENT";
                rate.count = 1;
                rate.remarks = "Maximum Charge";
                rateAmountChange(rate, guide);
            }
        }

        function ctnTypeVarInput(rate, item, guide, shipmentType) {
            var ctnTypeSet = new Set();
            if(shipmentType.indexOf("wms") != -1 && guide.ctnType){
                ctnTypeSet.add(guide.ctnType);
            }
            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.ctnType) {
                    ctnTypeSet.add(data.ctnType);
                }
            })
            var isFirst = true;
            ctnTypeSet.forEach(function(ctnType) {
                if (isFirst) {
                    rate.unit = ctnType;
                    rate.count = round(getUnitCount(rate, shipmentType, guide), 4);
                    rateAmountChange(rate, guide);
                    rateMinMaxCheck(rate, item, guide);
                    isFirst = false;
                } else {
                    var typeExist = false;
                    angular.forEach(guide.guideRates, function(data) {
                        if (data.isPay == rate.isPay &&
                            data.unit == ctnType &&
                            data.itemId == rate.itemId &&
                            data.venderId == rate.venderId) {
                            typeExist = true;
                        }
                    })
                    if (!typeExist) {
                        var tempRate = angular.copy(rate);
                        tempRate.id = null;
                        tempRate.actual = null;
                        tempRate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        tempRate.invoiceNum = null;
                        tempRate.dnNum = null;
                        tempRate.exchangeRate = null;
                        tempRate.remarks = null;
                        tempRate.locked = null;
                        tempRate.voucherId = null;
                        tempRate.isHidden = false;
                        tempRate.writeOffCode = null;
                        tempRate.inputTime = null;
                        tempRate.lastModifiedBy = null;
                        tempRate.audited = null;
                        tempRate.timestamp = null;
                        tempRate.billNum = null;
                        tempRate.sharedOn = null;
                        tempRate.accountDate = null;
                        tempRate.unit = ctnType;
                        tempRate.count = round(getUnitCount(tempRate, shipmentType, guide), 4);
                        rateAmountChange(tempRate, guide);
                        rateMinMaxCheck(tempRate, item, guide);
                        guide.guideRates.push(tempRate);
                    }
                }
            })
        }

        function getCtnNumTtl(guide) {
            var outNum = 0;
            if (!guide.guideFclCtns) {
                return outNum;
            }
            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.ctnType && data.num) {
                    outNum = outNum + data.num;
                }
            })
            return outNum;
        }

        function getCtnNum(type, guide) {
            var outNum = 0;
            if (!guide.guideFclCtns) {
                return outNum;
            }
            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.ctnType == type && data.num) {
                    outNum = outNum + data.num;
                }
            })
            return outNum;
        }

        function generateShowLedger(inputledgers, lastOnly, lang) {
            var ledgers = inputledgers;
            for (var i = 0; i < ledgers.length; i++) {
                var titleTemp = "";
                if (ledgers[i].level > 1) {
                    var subLen = 0;
                    for (var j = 1; j < ledgers[i].level; j++) {
                        if (j == 2) {
                            subLen = subLen + 2;
                        } else {
                            subLen = subLen + 4;
                        }
                        var ledger = ledgers[i].code.substr(0, subLen);

                        for (var ii = 0; ii < ledgers.length; ii++) {
                            if (ledgers[ii].code == ledger) {
                                if (lang == "en") {
                                    titleTemp = titleTemp + ledgers[ii].enName + "/ ";
                                } else {
                                    titleTemp = titleTemp + ledgers[ii].cnName + "/ ";
                                }
                            }
                        }
                    }
                }
                if (lang == "en") {
                    ledgers[i].showLedger = titleTemp + ledgers[i].enName;
                } else {
                    ledgers[i].showLedger = titleTemp + ledgers[i].cnName;
                }
            }
            if (lastOnly) {
                for (var j = ledgers.length - 1; j >= 0; j--) {
                    if (!ledgers[j].isLast) {
                        ledgers.splice(j, 1);
                    }
                }
            }
            return ledgers;
        }

        /*
         ** randomWord 产生任意长度随机字母数字组合
         ** randomFlag 是否任意长度 min 任意长度最小位[固定位数] max 任意长度最大位
         ** yuejingge 2017/11/8
         */
        function randomWord(randomFlag, min, max) {
            var str = "",
                pos, range = min,
                arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l',
                    'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
                    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
                    '~', '@', '#', '$', '^', '_'
                ];

            if (randomFlag) {
                range = Math.round(Math.random() * (max - min)) + min; // 任意长度
            }
            for (var i = 0; i < range; i++) {
                pos = Math.round(Math.random() * (arr.length - 1));
                str += arr[pos];
            }
            return str;
        }

        function formatTwoDigits(x) {
            var f_x = parseFloat(x);
            if (isNaN(f_x)) {
                return null;
            }
            f_x = Math.round(f_x * 100) / 100;
            var s_x = f_x.toString();
            var pos_decimal = s_x.indexOf('.');
            if (pos_decimal < 0) {
                pos_decimal = s_x.length;
                s_x += '.';
            }
            while (s_x.length <= pos_decimal + 2) {
                s_x += '0';
            }
            return s_x;
        }

        function clearCopyGuide(guide) {
            guide.id = null;
            guide.timestampVersion = null;
            guide.appliedBy = null;
            guide.cwLoked = null;
            guide.revLock = null;
            guide.expLock = null;
            guide.cbMark = null;
            guide.cbNo = null;
            guide.wmsGw = null;
            guide.wmsVol = null;
            guide.wmsCw = null;
            guide.createdBy = null;
            guide.labelUrl = null;
            guide.labelRefNum = null;
            guide.autoTracking = null;
            guide.trackingId = null;
            guide.autoInput = false;
            guide.headTrackFinished = null;
            guide.agentGuideId = null;
            guide.jobNum = null;
            guide.hblNum = null;
            guide.refNum = null;
            guide.mblNum = null;
            guide.soNum = null;
            guide.atd = null;
            guide.cargoReadyDate = null;
            guide.poNum = null;
            guide.consolidated = false;
            guide.submited = false;
            guide.plConfirmed = false;
            guide.salesChecked = false;
            guide.opDate = null;
            guide.billDate = null;
            guide.opNode = null;
            guide.feederEtdConfirmed = false;
            guide.deliveryTime = null;
            guide.revDeadLine = null;
            guide.deliveryDate = null;
            guide.transferDate = null;
            guide.tkState = null;
            guide.isTemplate = false;
            guide.parcelMaster = false;
            guide.isLocked = false;
            guide.ratesLocked = false;
            guide.etdLocked = false;
            guide.isApplied = false;
            guide.isClosed = false;
            guide.isVoid = false;
            guide.allBilled = false;
            guide.plConfirmed = false;
            guide.trackingFinished = false;
            guide.cargoReadyDate = null;
            guide.booking.isTemplate = false;
            guide.booking.bookingNum = null;
            guide.booking.id = null;
            guide.booking.remarks = null;
            guide.asWmsIn = null;
            guide.asWmsOut = null;
            guide.booking.bookingCustom = null;
            guide.booking.siReceivedTime = null;
            guide.booking.etdConfirmed = null;
            guide.booking.issueDate = null;
            guide.fbaRef = null;
            guide.shipmentId = null;
            guide.booking.guideId = null;
            guide.booking.isLocked = null;
            guide.booking.hblPrinted = false;
            guide.poNum = null;
            guide.plConfirmed = false;
            guide.saSentTime = null;
            guide.soReleaseTime = null;
            guide.truckArrangeTime = null;
            guide.customArrangeTime = null;
            guide.localTimeOne = null;
            guide.localTimeTwo = null;
            guide.invoiceIssuedTime = null;
            guide.invoiceReceivedTime = null;
            guide.paymentPaidTime = null;
            guide.paymentReceivedTime = null;
            guide.deliveryGuideTime = null;
            guide.bookingTime = null;
            guide.siSubmitedTime = null;
            guide.hblConfirmedTime = null;
            guide.mblConfirmedTime = null;
            guide.dnSentTime = null;
            guide.hblReleasedTime = null;
            guide.mblReleasedTime = null;
            guide.plConfirmTime = null;
            guide.applyRlsTime = null;
            guide.soExpectedTime = null;
            guide.deliveryAppointmentTime = null;
            guide.ataTime = null;
            guide.customClearTime = null;
            guide.otherTimeOne = null;
            guide.otherTimeTwo = null;
            guide.otherTimeThree = null;
            guide.vgmTime = null;
            guide.ctnsLoadedTime = null;
            guide.customReleaseTime = null;
            guide.checkRemarks = null;
            guide.checkStatus = "notChecked";
            guide.status = "Uncommitted";
            guide.branchGuideId = null;
            guide.refGuideId = null;
            guide.amsNum = null;
            guide.masterId = null;
            guide.byVol = null;
            guide.parcelId = null;
            guide.thirdpartyPlatform = null;
            guide.thirdpartyPlatformRef = null;
            guide.thirdpartyPlatformId = null;
            guide.orderPushTag = null;
            guide.bookingEtd = null;
            guide.lastestTkStatus = null;
            guide.bookingMemo = null;
            guide.assistChargeReviewer = null;
            guide.assistChargesConfirmed = false
            guide.ctnAvailableTime = null;
            guide.closeTime = null;
            guide.ctnReturnTime = null;
            guide.quoteNum = null;
            guide.profit = null;
            guide.gp = null;
            guide.autoSyn = null;
            guide.creater = null;
            guide.dontSynTtl = null;
            guide.companyId = null;

            if (guide.guideCtns) {
                guide.guideCtns = [];
            }
            if (guide.guideDocs) {
                guide.guideDocs = [];
            }

            angular.forEach(guide.guideFclCtns, function(data) {
                data.id = null;
                data.companyId = null;
            })

            angular.forEach(guide.bookingCustoms, function(data) {
                data.id = null;
                data.docsReceived = null;
                data.manifestNum = null;
                data.continueNum = null;
                data.declareDate = null;
                data.customNum = null;
                data.arrived = null;
                data.shipManifest = null;
                data.released = null;
                data.declared = null;
                data.docsChecked = null;
                data.needFileService = null;
                data.fileServiceIsSend = null;
                data.status = "Uncommitted";
                data.companyId = null;
            })

            angular.forEach(guide.booking.bookingTrucks, function(data) {
                data.id = null;
                data.loadTime = null;
                data.soNum = null;
                data.status = "new";
                data.orderTime = null;
                data.isLocked = false;
                data.companyId = null;
            })

            angular.forEach(guide.booking.bookingCtns, function(pl) {
                // pl.id = null;
                pl.soc = null;
                pl.wmsCargoId = null;
                pl.location = null;
                pl.locked = false;
                pl.dutyAudited = false;
                pl.auditor = null;
                pl.fbaRef = null;
                pl.shipmentId = null;
                pl.bookingCustomId = null;
                pl.refGuideId = null;
                pl.refParcelId = null;
                pl.companyId = null;
                if (pl.mixedPls) {
                    angular.forEach(pl.mixedPls, function(mixPl) {
                        // mixPl.id = null;
                        mixPl.dutyAudited = false;
                        mixPl.auditor = null;
                        mixPl.companyId = null;
                    })
                }
            })

            if (guide.booking.bookingPrices) {
                for (var i = 0; i < guide.booking.bookingPrices.length; i++) {
                    guide.booking.bookingPrices[i].id = null;
                    guide.booking.bookingPrices[i].companyId = null;
                }
            }
            angular.forEach(guide.orderNodes, function(data) {
                data.id = null;
                data.guideId = null;
            })
            return guide;
        }

        function getDuty(bCtn) {
            bCtn = angular.copy(bCtn);
            if (!bCtn.dutyRate) {
                bCtn.dutyRate = 0;
            }
            if (!bCtn.totalPrice) {
                bCtn.totalPrice = 0;
            }
            bCtn.duty = round(bCtn.dutyRate * bCtn.totalPrice / 100, 2);
            bCtn.hmf = bCtn.mpf = 0;
            if (bCtn.importCountryCode == 'US') {
                bCtn.hmf = round(bCtn.totalPrice * 0.125 / 100, 2);
                bCtn.mpf = round(bCtn.totalPrice * 0.3464 / 100, 2);
                if(bCtn.mpf < 31.67){
                    bCtn.mpf = 31.67;
                }
                else if(bCtn.mpf > 614.35){
                    bCtn.mpf = 614.35;
                }
            } else if (bCtn.importCountryCode == 'CA') {
                bCtn.hmf = round((bCtn.duty + bCtn.totalPrice) * 5 / 100, 2);
                bCtn.mpf = 0;
            } else if (bCtn.importCountryCode == 'GB') {
                bCtn.vw = 0;
                if (bCtn.gwTtl) {
                    bCtn.vw = round(bCtn.gwTtl / 1000, 2);
                }
                if (bCtn.vw < 1) {
                    bCtn.vw = 1;
                }
                bCtn.mpf = round((80 * 1.27) + (90 * 1.27 * bCtn.vw), 2);

                bCtn.hmf = round((bCtn.duty + bCtn.totalPrice + bCtn.mpf) * 20 / 100, 2);
            } else if (bCtn.importCountryCode == 'DE') {
                bCtn.hmf = round((bCtn.duty + bCtn.totalPrice) * 21 / 100, 2);
                bCtn.mpf = 0;
            } else if (bCtn.importCountryCode == 'AU') {
                bCtn.hmf = round((bCtn.duty + bCtn.totalPrice) * 10 / 100, 2);

                bCtn.vw = 0;
                if (bCtn.gwTtl) {
                    bCtn.vw = round(bCtn.gwTtl / 500, 2);
                }
                if (bCtn.volTtl && bCtn.vw < bCtn.volTtl) {
                    bCtn.vw = bCtn.volTtl;
                }
                bCtn.mpf = round(10 * bCtn.vw, 2);
            }

            bCtn.dutyAmt = round(bCtn.hmf + bCtn.mpf + bCtn.duty, 2);
            return bCtn;
        }

        function translate(num) {
            var arr1 = new Array("", " THOUSAND", " MILLION", " BILLION");
            var len = num.length,
                i, j = 0,
                strRet = "";
            var cols = Math.ceil(len / 3);
            var first = len - cols * 3;
            var num3 = "";
            var strEng = "";
            for (i = first; i < len; i += 3) {
                ++j;
                if (i >= 0)
                    num3 = num.substring(i, i + 3)
                else
                    num3 = num.substring(0, first + 3)
                strEng = English(num3)
                if (strEng != "") {
                    if (strRet != "") strRet += ","
                    strRet += English(num3) + arr1[cols - j]
                }
            }
            return strRet;
        }

        function English(num) {
            var strRet = "";
            var arr2 = new Array("ZERO", "TEN", "TWENTY", "THIRTY", "FORTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY");
            var arr3 = new Array("ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE");
            var arr4 = new Array("TEN", "ELEVEN", "TWELVE", "THIRTEEN", "FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN", "NINETEEN");
            if ((num.length == 3) && (num.substr(0, 3) != "000")) {
                if ((num.substr(0, 1) != "0")) {
                    strRet += arr3[num.substr(0, 1)] + " HUNDRED"
                    if (num.substr(1, 2) != "00") strRet += " AND "
                }
                num = num.substring(1);
            }
            if ((num.length == 2)) {
                if ((num.substr(0, 1) == "0")) {
                    num = num.substring(1)
                } else if ((num.substr(0, 1) == "1")) {
                            strRet += arr4[num.substr(1, 2)]
                } else {
                    strRet += arr2[num.substr(0, 1)]
                    if (num.substr(1, 1) != "0") strRet += "-"
                    num = num.substring(1)
                }
            }
            if ((num.length == 1) && (num.substr(0, 1) != "0")) {
                strRet += arr3[num.substr(0, 1)]
            }
            return strRet;
        }

        function getPalletAmt(pkgs, capacity) {
            var outNum = 0;
            if (null != pkgs && null != capacity) {
                var palletAmt = pkgs / capacity;
                outNum = parseInt(palletAmt);
                if (outNum != palletAmt) {
                    outNum++;
                }
            }
            return outNum;
        }

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }


        function getNextCode(n) { //递增编号　如:0X100X99 递增后0X101X00．0X101X00递增后：0X101X01
            if (!n) {
                return "";
            }
            var v = [];
            for (var i = 0; i < n.length; i++) { // 将字符串撤分放入数组
                v.push(isNaN(n[i]) ? n[i] : parseInt(n[i]));
            }

            var fistNIndex = null; //第一个数字的索引位置
            for (var i = 0; i < v.length; i++) {
                if (!isNaN(v[i])) {
                    fistNIndex = i;
                    break;
                }
            }

            if (fistNIndex == null) { //如果一个数字都没有 就在字符串后面加数字0
                return n + '0';
            }
            //自动编号
            for (var i = v.length - 1; i >= 0; i--) {
                if (!isNaN(v[i])) {
                    var x = v[i] + 1;
                    if (x > 9) {
                        if (fistNIndex == i) {
                            v[i] = 10;
                            break;
                        } else {
                            v[i] = 0;
                        }
                    } else { // 到达这里已累加不用再循环
                        v[i] = x;
                        break;
                    }
                }
            }
            return v.join('');
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ERateUtils', ERateUtils);

    ERateUtils.$inject = ['$translate', '$rootScope'];

    function ERateUtils($translate, $rootScope) {
        var service = {
            getSelling: getSelling,
            getCost: getCost
        };

        return service;

        function getSelling(guide) {
            var guideRates = [];
            var pTerm = "";
            var payerId = 0;
            var payerName = "";
            if (!guide.booking.freightTerm || guide.booking.freightTerm == "PPD") {
                pTerm = "PPD";
                payerId = guide.shipperId;
                payerName = guide.shipperName;
            } else {
                pTerm = "CCT";
                payerId = guide.deliveryId;
                payerName = guide.deliveryName;
            }

            if (guide.shipmentType == null) {
                layer.msg($translate.instant('autopsApp.booking.home.completeInfoNotice'));
            } else if (guide.shipmentType == "FCL" || guide.shipmentType == "ITCT" || guide.shipmentType == "RAIL") {
                if (guide.guideFclCtns && guide.guideFclCtns.length != 0) {
                    for (var i = 0; i < guide.guideFclCtns.length; i++) {
                        if (guide.guideFclCtns[i].ctnType != null) {
                            guideRates.push({
                                "itemId": "1",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                                "currency": "USD",
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": pTerm,
                                "isPay": false,
                                "venderId": payerId,
                                "payer": payerName
                            });

                            guideRates.push({
                                "itemId": "2",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.mtf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD",
                                "isPay": false,
                                "venderId": guide.shipperId,
                                "payer": guide.shipperName
                            });
                            guideRates.push({
                                "itemId": "3",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.gzf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD",
                                "isPay": false,
                                "venderId": guide.shipperId,
                                "payer": guide.shipperName
                            });
                            if (guide.truckRequired) {
                                guideRates.push({
                                    "itemId": "4",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD",
                                    "isPay": false,
                                    "venderId": guide.shipperId,
                                    "payer": guide.shipperName
                                });
                            };
                            if (guide.fumigationRequired) {
                                guideRates.push({
                                    "itemId": "5",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD",
                                    "isPay": false,
                                    "venderId": guide.shipperId,
                                    "payer": guide.shipperName
                                });
                            };
                            if (guide.warehouseRequired) {
                                guideRates.push({
                                    "itemId": "6",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ckf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD",
                                    "isPay": false,
                                    "venderId": guide.shipperId,
                                    "payer": guide.shipperName
                                });
                            };
                        };
                    };
                };
                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                if (guide.customRequired) {
                    guideRates.push({
                        "itemId": "13",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    if (guide.guideFclCtns && guide.guideFclCtns.length != 0) {
                        for (var i = 0; i < guide.guideFclCtns.length; i++) {
                            if (guide.guideFclCtns[i].ctnType != null) {
                                guideRates.push({
                                    "itemId": "17",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dmtf'),
                                    "currency": "USD",
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": pTerm,
                                    "isPay": false,
                                    "venderId": payerId,
                                    "payer": payerName
                                });
                                guideRates.push({
                                    "itemId": "18",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                                    "currency": "USD",
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": pTerm,
                                    "isPay": false,
                                    "venderId": payerId,
                                    "payer": payerName
                                });

                            };
                        };
                    };
                };
            } else if (guide.shipmentType == "AIR" || guide.shipmentType == "EXPRESS") {
                guideRates.push({
                    "itemId": "10",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.kyf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "KG",
                    "count": guide.chargeWeight,
                    "paymentTerm": pTerm,
                    "isPay": false,
                    "venderId": payerId,
                    "payer": payerName
                });
                guideRates.push({
                    "itemId": "11",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.zkf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                if (guide.truckRequired) {
                    guideRates.push({
                        "itemId": "4",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.fumigationRequired) {
                    guideRates.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                };

            } else if (guide.shipmentType == "LCL" || guide.shipmentType == "BULK") {
                guideRates.push({
                    "itemId": "1",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                    "currency": "USD",
                    "unit": "MT",
                    "count": guide.chargeWeight,
                    "paymentTerm": pTerm,
                    "isPay": false,
                    "venderId": payerId,
                    "payer": payerName
                });
                if (guide.gw * 1000 > guide.gw) {
                    guideRates.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": guide.gw,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                } else {
                    guideRates.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": guide.gw / 1000,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": false,
                    "venderId": guide.shipperId,
                    "payer": guide.shipperName
                });
                if (guide.truckRequired) {
                    guideRates.push({
                        "itemId": "4",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.fumigationRequired) {
                    guideRates.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": false,
                        "venderId": guide.shipperId,
                        "payer": guide.shipperName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": pTerm,
                        "isPay": false,
                        "venderId": payerId,
                        "payer": payerName
                    });
                    if (guide.gw * 1000 > guide.gw) {
                        guideRates.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": guide.gw,
                            "paymentTerm": pTerm,
                            "isPay": false,
                            "venderId": payerId,
                            "payer": payerName
                        });
                    } else {
                        guideRates.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": guide.gw / 1000,
                            "paymentTerm": pTerm,
                            "isPay": false,
                            "venderId": payerId,
                            "payer": payerName
                        });
                    };
                };
            };
            angular.forEach(guideRates, function(rate) {
                if (rate.payer == null) {
                    rate.payer = "";
                }
            })
            return guideRates;
        };

        function getCost(guide) {
            var guideRates = [];
            var pTerm = "";
            var payerId = 0;
            var payerName = "";
            if (!guide.freightTerm || guide.freightTerm == "PPD") {
                pTerm = "PPD";
                payerId = guide.bookingToId;
                payerName = guide.bookingToName;
            } else {
                pTerm = "CCT";
                payerId = guide.deliveryId;
                payerName = guide.deliveryName;
            }

            if (guide.shipmentType == null) {
                // $('#myTab a[href="#guide"]').tab('show');
                layer.msg($translate.instant('autopsApp.booking.home.completeInfoNotice'));
            } else if (guide.shipmentType == "FCL" || guide.shipmentType == "ITCT" || guide.shipmentType == "RAIL") {
                if (guide.guideFclCtns && guide.guideFclCtns.length != 0) {
                    for (var i = 0; i < guide.guideFclCtns.length; i++) {
                        if (guide.guideFclCtns[i].ctnType != null) {
                            guideRates.push({
                                "itemId": "1",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                                "currency": "USD",
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": pTerm,
                                "isPay": true,
                                "venderId": payerId,
                                "payer": payerName
                            });
                            guideRates.push({
                                "itemId": "2",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.mtf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD",
                                "isPay": true,
                                "venderId": guide.bookingToId,
                                "payer": guide.bookingToName
                            });
                            guideRates.push({
                                "itemId": "3",
                                "item": $translate.instant('autopsApp.booking.bookingPrice.home.gzf'),
                                "currency": $rootScope.account.baseCurrency,
                                "unit": guide.guideFclCtns[i].ctnType,
                                "count": guide.guideFclCtns[i].num,
                                "paymentTerm": "PPD",
                                "isPay": true,
                                "venderId": guide.bookingToId,
                                "payer": guide.bookingToName
                            });
                            if (guide.truckRequired) {
                                angular.forEach(guide.booking.bookingTrucks, function(data) {
                                    guideRates.push({
                                        "itemId": "4",
                                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                                        "currency": $rootScope.account.baseCurrency,
                                        "unit": guide.guideFclCtns[i].ctnType,
                                        "count": guide.guideFclCtns[i].num,
                                        "paymentTerm": "PPD",
                                        "isPay": true,
                                        "venderId": data.truckerVid,
                                        "payer": data.truckerName
                                    });
                                });
                            };
                            if (guide.fumigationRequired) {
                                guideRates.push({
                                    "itemId": "5",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD",
                                    "isPay": true,
                                    "venderId": guide.fumigationVid,
                                    "payer": guide.fumigationName
                                });
                            };
                            if (guide.warehouseRequired) {
                                guideRates.push({
                                    "itemId": "6",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ckf'),
                                    "currency": $rootScope.account.baseCurrency,
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "PPD",
                                    "isPay": true,
                                    "venderId": guide.warehouseVid,
                                    "payer": guide.warehouseName
                                });
                            }
                        }
                    }
                }

                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                if (guide.customRequired) {
                    angular.forEach(guide.bookingCustoms, function(data) {
                        guideRates.push({
                            "itemId": "13",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                            "currency": $rootScope.account.baseCurrency,
                            "unit": "BL",
                            "count": 1,
                            "paymentTerm": "PPD",
                            "isPay": true,
                            "venderId": data.brokerVid,
                            "payer": data.brokerName
                        });
                    })

                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.insuranceVid,
                        "payer": guide.insuranceName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.traderVid,
                        "payer": guide.traderName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    if (guide.guideFclCtns && guide.guideFclCtns.length != 0) {
                        for (var i = 0; i < guide.guideFclCtns.length; i++) {
                            if (guide.guideFclCtns[i].ctnType != null) {
                                guideRates.push({
                                    "itemId": "17",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dmtf'),
                                    "currency": "USD",
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "CCT",
                                    "isPay": true,
                                    "venderId": guide.deliveryId,
                                    "payer": guide.deliveryName
                                });
                                guideRates.push({
                                    "itemId": "18",
                                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                                    "currency": "USD",
                                    "unit": guide.guideFclCtns[i].ctnType,
                                    "count": guide.guideFclCtns[i].num,
                                    "paymentTerm": "CCT",
                                    "isPay": true,
                                    "venderId": guide.deliveryId,
                                    "payer": guide.deliveryName
                                });

                            };
                        };
                    };
                };
            } else if (guide.shipmentType == "AIR" || guide.shipmentType == "EXPRESS") {
                guideRates.push({
                    "itemId": "10",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.kyf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "KG",
                    "count": guide.chargeWeight,
                    "paymentTerm": pTerm,
                    "isPay": true,
                    "venderId": payerId,
                    "payer": payerName
                });
                guideRates.push({
                    "itemId": "11",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.zkf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                if (guide.truckRequired) {
                    angular.forEach(guide.booking.bookingTrucks, function(data) {
                        guideRates.push({
                            "itemId": "4",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                            "currency": $rootScope.account.baseCurrency,
                            "unit": "BL",
                            "count": 1,
                            "paymentTerm": "PPD",
                            "isPay": true,
                            "venderId": data.truckerVid,
                            "payer": data.truckerName
                        });
                    })
                };
                if (guide.fumigationRequired) {
                    guideRates.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.fumigationVid,
                        "payer": guide.fumigationName
                    });
                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.insuranceVid,
                        "payer": guide.insuranceName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.traderVid,
                        "payer": guide.traderName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                };
            } else if (guide.shipmentType == "LCL" || guide.shipmentType == "BULK") {
                guideRates.push({
                    "itemId": "1",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.hyf'),
                    "currency": "USD",
                    "unit": "MT",
                    "count": guide.chargeWeight,
                    "paymentTerm": pTerm,
                    "isPay": true,
                    "venderId": payerId,
                    "payer": payerName
                });
                if (guide.gw * 1000 > guide.gw) {
                    guideRates.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": guide.gw,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.bookingToId,
                        "payer": guide.bookingToName
                    });
                } else {
                    guideRates.push({
                        "itemId": "12",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.pxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "MT",
                        "count": guide.gw / 1000,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.bookingToId,
                        "payer": guide.bookingToName
                    });
                };
                guideRates.push({
                    "itemId": "7",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.wjf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "13",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.bgf'),
                    "currency": $rootScope.account.baseCurrency,
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                guideRates.push({
                    "itemId": "14",
                    "item": $translate.instant('autopsApp.booking.bookingPrice.home.ams'),
                    "currency": "USD",
                    "unit": "BL",
                    "count": 1,
                    "paymentTerm": "PPD",
                    "isPay": true,
                    "venderId": guide.bookingToId,
                    "payer": guide.bookingToName
                });
                if (guide.truckRequired) {
                    angular.forEach(guide.booking.bookingTrucks, function(data) {
                        guideRates.push({
                            "itemId": "4",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.lyf'),
                            "currency": $rootScope.account.baseCurrency,
                            "unit": "BL",
                            "count": 1,
                            "paymentTerm": "PPD",
                            "isPay": true,
                            "venderId": data.truckerVid,
                            "payer": data.truckerName
                        });
                    });
                };
                if (guide.fumigationRequired) {
                    guideRates.push({
                        "itemId": "5",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.xzf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.fumigationVid,
                        "payer": guide.fumigationName
                    });
                };
                if (guide.insuranceRequired) {
                    guideRates.push({
                        "itemId": "8",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.bxf'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.insuranceVid,
                        "payer": guide.insuranceName
                    });
                };
                if (guide.coRequired) {
                    guideRates.push({
                        "itemId": "9",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.cof'),
                        "currency": $rootScope.account.baseCurrency,
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "PPD",
                        "isPay": true,
                        "venderId": guide.traderVid,
                        "payer": guide.traderName
                    });
                };
                if (guide.booking.serviceTerm == "CY-DOOR" || guide.booking.serviceTerm == "DOOR-DOOR") {
                    guideRates.push({
                        "itemId": "18",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dlyf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    guideRates.push({
                        "itemId": "17",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dbgf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    guideRates.push({
                        "itemId": "20",
                        "item": $translate.instant('autopsApp.booking.bookingPrice.home.dhdf'),
                        "currency": "USD",
                        "unit": "BL",
                        "count": 1,
                        "paymentTerm": "CCT",
                        "isPay": true,
                        "venderId": guide.deliveryId,
                        "payer": guide.deliveryName
                    });
                    if (guide.gw * 1000 > guide.gw) {
                        guideRates.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": guide.gw,
                            "paymentTerm": "CCT",
                            "isPay": true,
                            "venderId": guide.deliveryId,
                            "payer": guide.deliveryName
                        });
                    } else {
                        guideRates.push({
                            "itemId": "19",
                            "item": $translate.instant('autopsApp.booking.bookingPrice.home.dcxf'),
                            "currency": "USD",
                            "unit": "MT",
                            "count": guide.gw / 1000,
                            "paymentTerm": "CCT",
                            "isPay": true,
                            "venderId": guide.deliveryId,
                            "payer": guide.deliveryName
                        });
                    };
                };
            };
            angular.forEach(guideRates, function(rate) {
                if (rate.payer == null) {
                    rate.payer = "";
                }
            })
            return guideRates;
        };
    }
})();
