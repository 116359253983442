(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressParcelDialogController', ExpressParcelDialogController);

    ExpressParcelDialogController.$inject = ['$rootScope', '$timeout', '$http', '$location', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate',
    'entity', 'ExpressParcel', 'OpsVender', 'OpsCarrier', 'OpsShipper', 'DataUtils', 'DateUtils', 'GuideRates', 'OpsReport', 'OpsRates', 'ExpressPackage',
    'OpsFiles', 'OpsCost', 'WmsIn', 'WmsWarehouse', 'ExpressCargo', 'WmsCargo', 'AddressBook', 'Dictionary', 'AccountBind', 'ParcelGoods', 'OpsCompany'];

    function ExpressParcelDialogController ($rootScope, $timeout, $http, $location, $scope, $stateParams, $uibModalInstance, $uibModal, $translate,
        entity, ExpressParcel, OpsVender, OpsCarrier, OpsShipper, DataUtils, DateUtils, GuideRates, OpsReport, OpsRates, ExpressPackage,
        OpsFiles, OpsCost, WmsIn, WmsWarehouse, ExpressCargo, WmsCargo, AddressBook, Dictionary, AccountBind, ParcelGoods, OpsCompany) {
        var vm = this;

        $scope.guide = entity;
        var guideCopy = angular.copy($scope.guide);
        vm.clear = clear;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.save = save;
        vm.updateTtl = updateTtl;
        vm.updateGwTtl = updateGwTtl;
        vm.updateVolTtl = updateVolTtl;
        vm.updateVol = updateVol;
        vm.getCargo = getCargo;
        vm.getLabel = getLabel;
        vm.rateRequest = rateRequest;
        vm.updatePkgType = updatePkgType;
        vm.setStatus = setStatus;
        vm.updateGwKgs = updateGwKgs;
        vm.updateGwLbs = updateGwLbs;
        vm.submit = submit;
        vm.updateCount = updateCount;
        vm.getEltLabel = getEltLabel;
        vm.updateValue = updateValue;
        vm.updateValueTtl = updateValueTtl;
        vm.cargoIsOutChange = cargoIsOutChange;
        vm.organizePL = organizePL;
        vm.inchOzChange = inchOzChange;
        vm.uploadLabel = uploadLabel;
        vm.uploadPod = uploadPod;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.autoCheapestChannel = autoCheapestChannel;
        vm.getLabelInfo = getLabelInfo;

        vm.warehouses = null;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.clearLabelPop = clearLabelPop;
        vm.addToConsol = addToConsol;
        vm.setCargoLocked = setCargoLocked;
        vm.uploadWatermarkPic = uploadWatermarkPic;
        vm.imageAutomaticProcessing = imageAutomaticProcessing;
        vm.getLabelApiRequestBody = getLabelApiRequestBody;
        vm.getDeliveryBl = getDeliveryBl;
        vm.getGuideShipperBl = getGuideShipperBl;
        vm.getAccountBinds = getAccountBinds;
        vm.isWmsOrder = isWmsOrder;
        vm.pushOneOrder = pushOneOrder;
        vm.updatePushOrdersStatus = updatePushOrdersStatus;
        vm.delPush = delPush;
        vm.byCtnsChange = byCtnsChange;
        vm.fillByCommodityDatabase = fillByCommodityDatabase;
        vm.getPushApiRequestBody = getPushApiRequestBody;
        vm.Return_Policy=[{name:"", pkgType:""}, {name:"Address Service Requested",pkgType:"1"},{name:"Forwarding Service Requested",pkgType:"2"},{name:"Change Service Requested",pkgType:"3"},{name:"Return Service Requested(Defult)",pkgType:"4"}];
        vm.ShipberPackagingType=[{name:"", pkgType:""}, {name:"Your own packaging",pkgType:"YOUR_PACKAGING"},{name:"FedEx® Envelope",pkgType:"FEDEX_ENVELOPE"},{name:"FedEx® Box",pkgType:"FEDEX_BOX"},{name:"FedEx® Small Box",pkgType:"FEDEX_SMALL_BOX"},{name:"FedEx® Medium Box",pkgType:"FEDEX_MEDIUM_BOX"},{name:"FedEx® Large Box",pkgType:"FEDEX_LARGE_BOX"},
            {name:"FedEx® Extra Large Box",pkgType:"FEDEX_EXTRA_LARGE_BOX"},{name:"FedEx® 10kg Box",pkgType:"FEDEX_10KG_BOX"},{name:"FedEx® 25kg Box",pkgType:"FEDEX_25KG_BOX"},{name:"FedEx® Pak",pkgType:"FEDEX_PAK"},{name:"FedEx® Tube",pkgType:"FEDEX_TUBE"},{name:"UPS Letter",pkgType:"UPS_LETTER"},{name:"UPS Tube",pkgType:"UPS_TUBE"},
            {name:"UPS Express® Pak",pkgType:"UPS_PAK"},{name:"UPS 10 KG Box®",pkgType:"UPS_10KG_BOX"},{name:"UPS 25 KG Box®",pkgType:"UPS_25KG_BOX"},{name:"UPS Express® Box - Small",pkgType:"UPS_EXPRESS_BOX_SMALL"},{name:"UPS Express® Box - Medium",pkgType:"UPS_EXPRESS_BOX_MEDIUM"},{name:"UPS Express® Box - Large",pkgType:"UPS_EXPRESS_BOX_LARGE"}];
        vm.Content_Categories=[{name:"",categoryCode:""},{name:"Lithium Metal / Alloy Batteries Contained in Equipment",categoryCode:"01"},{name:"Lithium Metal / Alloy Batteries Packed with Equipment",categoryCode:"02"},{name:"Lithium Metal / Alloy Batteries Stand-Alone",categoryCode:"03"},{name:"Lithium-ion or Lithium Polymer Batteries Contained in Equipment",categoryCode:"04"}
        ,{name:"Lithium-ion or Lithium Polymer Batteries with Equipment",categoryCode:"05"},{name:"Lithium-ion or Lithium Polymer Batteries Stand-Alone",categoryCode:"06"},{name:"Limited Quantity / ORM-D",categoryCode:"08"},{name:"Small Quantity Provision",categoryCode:"09"}]
        function isWmsOrder () {
            if($rootScope.account.companyId || !$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                return false;
            }
            for(var i = 0; i < $scope.guide.expressCargos.length; i++){
                if($scope.guide.expressCargos[i].wmsCargoId){
                    return true;
                }
            }
            return false;
        }

        var loadHostCompanyId = function() {
            if(!$rootScope.HOST_COMPANY_ID){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                    if(!$scope.guide.companyId){
                        $scope.guide.companyId = $rootScope.HOST_COMPANY_ID;
                    }
                })
            }
        }

        function setCargoLocked (cargo) {
            if(!$rootScope.account.companyId){
                return;
            }
            cargo.locked = !cargo.locked;
        }

        function organizePL () {
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.expressParcel.submitAlert'));
                return;
            };
            if (!$scope.guide.poNum) {
                layer.msg($translate.instant('autopsApp.expressParcel.poNumRequiredAlert'));
                return;
            };
            if (!$scope.guide.payParty) {
                layer.msg($translate.instant('autopsApp.expressParcel.payPartyNullAlert'));
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.labelUrl) {
                $rootScope.PDFPOP($scope.guide.labelUrl, null);
                return;
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-organize-dialog.html',
                controller: 'ExpressCargoOrganizeDialogController',
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return angular.copy($scope.guide);
                    }]
                }
            }).result.then(function() {
                $scope.files = new Array();
                loadGuide();
            }, function() {
            });
        }


        function submit (ingloreVolume) {
            if($rootScope.CLIENT_DEPOSITE && $rootScope.CLIENT_DEPOSITE.unpaidAmount){
                layer.msg($translate.instant('global.messages.creditOverLimit'));
                return;
            }
            if(!$scope.guide.shipmentType){
                layer.msg($translate.instant('autopsApp.expressParcel.shipmentTypeNullAlert'));
                return;
            }
            if($rootScope.ISTMS && !$scope.guide.byCtns && !ingloreVolume){
                if(!$scope.guide.pallets || !$scope.guide.gw){
                    layer.msg($translate.instant('autopsApp.expressParcel.tmsPlRequiredAlert'));
                    return;
                }
            }

            $scope.guide.submited = true;
            $scope.guide.status = "Submitted";
            $scope.guide.createdTime = new Date();
            save();
        }

        function warehouseSelected (warehouse) {
            if(haveWmsCargo()){
                $scope.guide.warehouseName = guideCopy.warehouseName;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                return;
            }
            $scope.guide.warehouseName = warehouse.name;
            $scope.guide.warehouseId = warehouse.id;
            $scope.guide.companyId = warehouse.companyId;
            $scope.guide.medCompanyId = warehouse.medCompanyId;
            $scope.guide.isVirtual = warehouse.isVirtual;
            $scope.guide.medCompany = warehouse.medCompany;
            $scope.guide.medVenderId = warehouse.medVenderId;
            if(!$scope.guide.bookingToId){
                $scope.guide.bookingToId = warehouse.venderId;
                $scope.guide.bookingToName = warehouse.venderName;
            }
            $scope.bindedAccounts = [];
            $scope.getAllAccounts(true);
            if(warehouse.shipperAddress && !$scope.guide.labelUrl){
                AddressBook.getAddressByCode({
                    companyId: $scope.guide.companyId,
                    code: warehouse.shipperAddress
                }, function(result){
                    if(result && result.addressOne){
                        $scope._onAddressSelected(result, "shipfrom");
                        if($rootScope.account.companyId){
                            layer.msg($translate.instant('autopsApp.expressParcel.addressUpdatedByWarehouse'));
                        }
                    }
                })
            }
            if($scope.guide.channel){
                ExpressParcel.checkChannel($scope.guide, function(){
                }, function(){
                    layer.confirm($translate.instant('global.invalidChannelAlert'), {
                        title: "Msg",
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                    }, function(index) {
                        layer.close(index);
                        $scope.guide.channel = null;
                        $translate.refresh();
                    }, function() {
                        return;
                    });
                })
            }
        }

        function getWarehouses () {
            if (vm.warehouses){
                return;
            }
            if(!$rootScope.account.companyId){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else if ($scope.guide.isVirtual
                        && (!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId)){
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                    vm.warehouses = data;
                });
            }
        }

        function clearLabelPop (isForce) {
            if(!$scope.guide.labelUrl){return;}
            if(!$rootScope.account.companyId && $scope.guide.labelBindType){
                layer.msg($translate.instant('autopsApp.expressParcel.voidLabelAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var alertContent = $translate.instant('autopsApp.expressParcel.clearLabelAlert');
            if(isForce){
                alertContent = $translate.instant('autopsApp.expressParcel.forceClearLabelAlert');
            }
            else if($scope.isUPS()){
                alertContent = $translate.instant('autopsApp.expressParcel.clearUpsLabelAlert');
            }

            layer.confirm(alertContent, {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                ExpressParcel.voidLabel({isForce: isForce}, $scope.guide, function(result){
                    layer.msg($translate.instant('global.messages.submitted'));
                    loadGuide();
                    $scope.files = [];
                }, function(error){
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    }
                    loadGuide();
                    $scope.files = [];
                });
            }, function() {
                return;
            });
        }

        function addCargo () {
            if($scope.guide.labelUrl){
                clearLabelPop();
                return;
            }
            if (!$scope.guide.expressCargos){
                $scope.guide.expressCargos = [];
            }
            var newCargo = {};
            if($rootScope.ISTMS && !$scope.guide.byCtns){
                newCargo.length = 40;
                newCargo.width = 48;
                newCargo.height = 74;
            }
            $scope.guide.expressCargos.push(newCargo);
        }

        function getCargo (){
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if (!$scope.guide.submited) {
                layer.confirm($translate.instant('autopsApp.expressParcel.getInventoryAlert'), {
                    icon: 0,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    submit(true)
                }, function() {
                    return;
                });
                return;
            };
            if (!$scope.guide.id || !angular.equals($scope.guide, guideCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var entt = {};
            entt.expressParcel = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                ExpressParcel.get({
                    id: $scope.guide.id
                }, function(result) {
                    $scope.guide = result;
                    updateVol();
                    $scope.updateGwVol();
                    updateTtl();
                    updatePackageTotal();
                    save();
                });
            }, function() {
            });

        }
        $scope.comCargoName = function(cargoName) {
            if(!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = cargoName;
            }
            else if ($scope.guide.goodsDiscription.indexOf(cargoName) == -1) {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + " " + cargoName;
            }
        };

        function updateGwKgs () {
            $scope.guide.gw = $scope.guide.lbs / 2.20462262;
            $scope.guide.gw = $scope.guide.gw.toFixed(3) - 0;
            $scope.updateGwVol();
        }
        function updateGwLbs () {
            $scope.guide.lbs = $scope.guide.gw * 2.20462262;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(3) - 0;
            $scope.updateGwVol();
        }

        function delCargo (cargo) {
            // if($scope.guide.labelUrl){
            //     clearLabelPop();
            //     return;
            // }
            if(cargo.locked){
                return;
            }

            if (!cargo.id) {
                var index = $scope.guide.expressCargos.indexOf(cargo);
                if (index > -1) {
                    $scope.guide.expressCargos.splice(index, 1);
                };
                updateTtl();
                $translate.refresh();
            }
            else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                if(cargo.refGuideId || cargo.refParcelId){
                    layer.confirm($translate.instant('autopsApp.expressParcel.removeFromConsolAlert'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            cargo.locked = true;
                            ExpressCargo.delete({
                                id: cargo.id
                            }, function(){
                                loadGuide();
                            });
                            layer.close(index);
                        }, function() {
                    });
                }
                else {
                    cargo.locked = true;
                    ExpressCargo.delete({
                        id: cargo.id
                    }, function(){
                        loadGuide();
                    });
                }
            }
        };


        function inchOzChange () {
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if($scope.guide.inchOz){
                    if(cargo.pkgs && cargo.lbs){
                        cargo.gw = DataUtils.round(cargo.lbs/cargo.pkgs, 3);
                    }
                    if(cargo.length){
                        cargo.length = DataUtils.round(cargo.length/2.54, 2);
                    }
                    if(cargo.width){
                        cargo.width = DataUtils.round(cargo.width/2.54, 2);
                    }
                    if(cargo.height){
                        cargo.height = DataUtils.round(cargo.height/2.54, 2);
                    }
                }
                else {
                    if(cargo.pkgs && cargo.gwTtl){
                        cargo.gw = DataUtils.round(cargo.gwTtl/cargo.pkgs, 3);
                    }
                    if(cargo.length){
                        cargo.length = DataUtils.round(cargo.length*2.54, 2);
                    }
                    if(cargo.width){
                        cargo.width = DataUtils.round(cargo.width*2.54, 2);
                    }
                    if(cargo.height){
                        cargo.height = DataUtils.round(cargo.height*2.54, 2);
                    }
                }
            })
        }
        $scope.loadGuide = function() {
            layer.confirm($translate.instant('autopsApp.guide.refreshAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {
            });
        }
        function updateGwTtl (cargo) {
            if(!cargo.gw){
                cargo.gw = 0;
            }
            if(cargo.pkgs){
                if($scope.guide.inchOz){
                    cargo.lbs = cargo.gw * cargo.pkgs
                    cargo.gwTtl = cargo.lbs / 2.20462262;
                }
                else {
                    cargo.gwTtl = cargo.gw * cargo.pkgs
                    cargo.lbs = cargo.gwTtl * 2.20462262;
                }
            }
            if(!cargo.gwTtl){
                cargo.gwTtl = 0;
            }
            if(!cargo.lbs){
                cargo.lbs = 0;
            }

            cargo.gwTtl = cargo.gwTtl.toFixed(3) -0;
            cargo.lbs = cargo.lbs.toFixed(3) -0;
            updateTtl();
        }
        function updateVol (cargo) {
            if(!cargo){
                return;
            }
            var l,w,h;
            l = cargo.length;
            w = cargo.width;
            h = cargo.height;
            if(l && w && h){
                if($scope.guide.inchOz || ($rootScope.ISTMS && !$scope.guide.byCtns)){
                    l = l * 2.54;
                    w = w * 2.54;
                    h = h * 2.54;
                }
                cargo.vol = DataUtils.round(l/100 * w/100 * h/100, 6);
            }
            if($rootScope.ISTMS && !$scope.guide.byCtns){
                if (cargo.vol && cargo.pallets){
                    cargo.volTtl = DataUtils.round(cargo.vol * cargo.pallets, 6);
                }
            }
            else {
                if (cargo.vol && cargo.pkgs){
                    cargo.volTtl = DataUtils.round(cargo.vol * cargo.pkgs, 6);
                }
            }
            updateTtl();
        }


        function updateVolTtl (cargo) {
            cargo.volTtl = 0;
            if($rootScope.ISTMS && !$scope.guide.byCtns){
                if (cargo.vol && cargo.pallets){
                    cargo.volTtl = DataUtils.round(cargo.vol * cargo.pallets, 6);
                }
            }
            else {
                if (cargo.vol && cargo.pkgs){
                    cargo.volTtl = DataUtils.round(cargo.vol * cargo.pkgs, 6);
                }
            }
            updateTtl();
        }
        function updateValue (cargo) {
            cargo.usdValueTtl = 0;
            if (cargo.quantity && cargo.usdValuePerUnit){
                cargo.usdValueTtl = cargo.quantity * cargo.usdValuePerUnit;
            }
            cargo.usdValueTtl = cargo.usdValueTtl.toFixed(2) -0;
            updateTtl();
        }

        function updateValue (cargo) {
            cargo.usdValueTtl = 0;
            if (cargo.quantity && cargo.usdValuePerUnit){
                cargo.usdValueTtl = cargo.quantity * cargo.usdValuePerUnit;
            }
            cargo.usdValueTtl = cargo.usdValueTtl.toFixed(2) -0;
            updateValueTtl();
        }

        function updateValueTtl () {
            var valueTtl = 0;
            angular.forEach($scope.guide.expressCargos, function(data){
                if(data.usdValueTtl){
                    valueTtl += data.usdValueTtl;
                }
            })
            $scope.guide.cargoValueUsd = valueTtl.toFixed(2) -0;
        }

        function updatePkgType (packageType) {
            $scope.guide.pkgType = packageType;
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if(cargo.packageType && cargo.packageType != packageType){
                    $scope.guide.pkgType = "PACKAGES";
                }
            })
        }
        $scope.packingListTtl = {};
        function updateCount () {
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;
            $scope.guide.lbs = 0;
            $scope.guide.pallets = 0;
            if (!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                return;
            }
            angular.forEach($scope.guide.expressCargos, function(data){
                var pallets = data.pallets;
                var pkgs = data.pkgs;
                if(!pallets){pallets = 0;}
                if(!pkgs){pkgs = 0;}

                if (data.vol){
                    if(!$rootScope.ISTMS || $scope.guide.byCtns){
                        data.volTtl = data.vol * pkgs;
                    }
                    else {
                        data.volTtl = data.vol * pallets;
                    }
                }

                if(!data.volTtl){
                    data.volTtl = 0;
                }
                data.volTtl = DataUtils.round(data.volTtl, 6);

                if(data.gw){
                    if($scope.guide.inchOz){
                        data.lbs = data.gw * pkgs
                    }
                    else {
                        data.gwTtl = data.gw * pkgs;
                    }
                }
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }
                if(!data.lbs){
                    data.lbs = 0;
                }

                if(!$rootScope.ISTMS || $scope.guide.byCtns){
                    if($scope.guide.inchOz){
                        data.gwTtl = DataUtils.round(data.lbs/2.20462262, 3);
                    }
                    else {
                        data.lbs = DataUtils.round(data.gwTtl * 2.20462262, 3);
                    }
                }
                data.gwTtl = DataUtils.round(data.gwTtl, 3);
                data.lbs = DataUtils.round(data.lbs, 3);

                $scope.guide.gw += data.gwTtl;
                $scope.guide.vol += data.volTtl;
                $scope.guide.lbs += data.lbs;
                $scope.guide.pkgNum += pkgs;
                $scope.guide.pallets += pallets;
                if($rootScope.ISTMS && !$scope.guide.byCtns && data.lbs && data.pallets){
                    var checkAmt = data.lbs/data.pallets;
                    if(checkAmt > 1000){
                        data.lbs = 1000 * data.pallets;
                        layer.msg("MAX 1000/PALLET")
                    }
                }
            })

            $scope.guide.gw = $scope.guide.gw.toFixed(3) -0;
            $scope.guide.vol = $scope.guide.vol.toFixed(4) -0;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(3) -0;
            $scope.guide.pkgNum = $scope.guide.pkgNum.toFixed(0) -0;
            $scope.guide.pallets = $scope.guide.pallets.toFixed(2) -0;
            $scope.packingListTtl = angular.copy($scope.guide);
            if(!$scope.guide.pallets){
                $scope.guide.pallets = guideCopy.pallets;
            }
            if($rootScope.ISTMS && !$scope.guide.byCtns){
                updateGwKgs();
            }
            $scope.updateGwVol();
        }

        function updatePackListTtl () {
            $scope.packingListTtl = angular.copy($scope.guide);
            $scope.packingListTtl.gw = 0;
            $scope.packingListTtl.vol = 0;
            $scope.packingListTtl.pkgNum = 0;
            $scope.packingListTtl.lbs = 0;
            $scope.packingListTtl.pallets = 0;
            if (!$scope.packingListTtl.expressCargos || $scope.packingListTtl.expressCargos.length == 0){
                return;
            }
            angular.forEach($scope.packingListTtl.expressCargos, function(data){
                var pallets = data.pallets;
                var pkgs = data.pkgs;
                if(!pallets){pallets = 0;}
                if(!pkgs){pkgs = 0;}

                if (data.vol){
                    if(!$rootScope.ISTMS || $scope.packingListTtl.byCtns){
                        data.volTtl = data.vol * pkgs;
                    }
                    else {
                        data.volTtl = data.vol * pallets;
                    }
                }

                if(!data.volTtl){
                    data.volTtl = 0;
                }
                data.volTtl = DataUtils.round(data.volTtl, 6);

                if(data.gw){
                    if($scope.packingListTtl.inchOz){
                        data.lbs = data.gw * pkgs
                    }
                    else {
                        data.gwTtl = data.gw * pkgs;
                    }
                }
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }
                if(!data.lbs){
                    data.lbs = 0;
                }

                if(!$rootScope.ISTMS || $scope.packingListTtl.byCtns){
                    if($scope.packingListTtl.inchOz){
                        data.gwTtl = DataUtils.round(data.lbs/2.20462262, 3);
                    }
                    else {
                        data.lbs = DataUtils.round(data.gwTtl * 2.20462262, 3);
                    }
                }
                data.gwTtl = DataUtils.round(data.gwTtl, 3);
                data.lbs = DataUtils.round(data.lbs, 3);

                $scope.packingListTtl.gw += data.gwTtl;
                $scope.packingListTtl.vol += data.volTtl;
                $scope.packingListTtl.lbs += data.lbs;
                $scope.packingListTtl.pkgNum += pkgs;
                $scope.packingListTtl.pallets += pallets;
                if($rootScope.ISTMS && !$scope.packingListTtl.byCtns && data.lbs && data.pallets){
                    var checkAmt = data.lbs/data.pallets;
                    if(checkAmt > 1000){
                        data.lbs = 1000 * data.pallets;
                        layer.msg("MAX 1000/PALLET")
                    }
                }
            })

            $scope.packingListTtl.gw = $scope.packingListTtl.gw.toFixed(3) -0;
            $scope.packingListTtl.vol = $scope.packingListTtl.vol.toFixed(4) -0;
            $scope.packingListTtl.lbs = $scope.packingListTtl.lbs.toFixed(3) -0;
            $scope.packingListTtl.pkgNum = $scope.packingListTtl.pkgNum.toFixed(0) -0;
            $scope.packingListTtl.pallets = $scope.packingListTtl.pallets.toFixed(2) -0;
        }


        function updateTtl () {
            if(!$scope.guide.sellCur){
                $scope.guide.sellCur = $rootScope.account.baseCurrency;
            }
            if(!$scope.guide.collectCur){
                $scope.guide.collectCur = $rootScope.account.baseCurrency;
            }
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;
            $scope.guide.lbs = 0;
            $scope.guide.pallets = 0;

            if (!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                return;
            }
            angular.forEach($scope.guide.expressCargos, function(data){
                var pallets = data.pallets;
                var pkgs = data.pkgs;
                if(!pallets){pallets = 0;}
                if(!pkgs){pkgs = 0;}
                if(!data.volTtl){
                    data.volTtl = 0;
                }

                data.volTtl = data.volTtl.toFixed(6) -0;
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }
                if(!data.lbs){
                    data.lbs = 0;
                }

                if($rootScope.ISTMS || $scope.guide.inchOz){
                    data.gwTtl = data.lbs / 2.20462262;
                }
                else {
                    data.lbs = data.gwTtl * 2.20462262;
                }

                data.lbs = data.lbs.toFixed(3) -0;
                data.gwTtl = data.gwTtl.toFixed(3) -0;

                if(pkgs && (!$rootScope.ISTMS || $scope.guide.byCtns)){
                    if($scope.guide.inchOz){
                        data.gw = data.lbs/ pkgs;
                    }
                    else{
                        data.gw = data.gwTtl/ pkgs;
                    }
                    data.vol = data.volTtl/ pkgs;

                    data.gw = data.gw.toFixed(3) -0;
                    data.vol = data.vol.toFixed(6) -0;
                }
                $scope.guide.gw += data.gwTtl;
                $scope.guide.vol += data.volTtl;
                $scope.guide.lbs += data.lbs;
                $scope.guide.pkgNum += pkgs;
                $scope.guide.pallets += pallets;
                if($rootScope.ISTMS && !$scope.guide.byCtns && data.lbs && data.pallets){
                    var checkAmt = data.lbs/data.pallets;
                    if(checkAmt > 1000){
                        // data.lbs = 1000 * data.pallets;
                        layer.msg("Over 1000 pounds per pallet")
                    }
                }
            })

            $scope.guide.gw = $scope.guide.gw.toFixed(3) -0;
            $scope.guide.vol = $scope.guide.vol.toFixed(4) -0;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(3) -0;
            $scope.guide.pkgNum = $scope.guide.pkgNum.toFixed(0) -0;
            $scope.guide.pallets = $scope.guide.pallets.toFixed(2) -0;
            $scope.packingListTtl = angular.copy($scope.guide);
            if(!$scope.guide.pallets){
                $scope.guide.pallets = guideCopy.pallets;
            }
            if($rootScope.ISTMS && !$scope.guide.byCtns){
                updateGwKgs();
            }
            $scope.updateGwVol();
        }

        $scope.isVMed = false;
        $scope.isVFinal = false;
        $scope.isVClient= false;
        function initVirtual () {
            if($rootScope.tmsTypes.indexOf($scope.guide.shipmentType) !== -1){
                $rootScope.ISTMS = true;
            }
            else{
                $rootScope.ISTMS = false;
            }
            if($scope.guide.isVirtual){
                if (!$rootScope.account.companyId){
                    $scope.isVClient = true;
                }
                else if($scope.guide.labelVirtual && $scope.guide.companyId == $rootScope.account.companyId){
                    $scope.isVMed = true;
                }
                else if($scope.guide.labelVirtual && $scope.guide.medCompanyId == $rootScope.account.companyId){
                    $scope.isVFinal = true;
                }
                else if(!$scope.guide.medCompanyId || $scope.guide.medCompanyId == $rootScope.account.companyId){
                    $scope.isVMed = true;
                }
                else {
                    $scope.isVFinal = true;
                }
            }
            $rootScope.VOID_MARK($scope.guide);
        }
        $timeout(function() {
            $rootScope.PARCEL_SERVICES  = null;
            var path = $location.path();
            if (path.indexOf("copy") >= 0) {
                document.title = "Copy Express/ Parcel";
                if ($scope.guide.guideRates && $scope.guide.guideRates.length > 0 && $rootScope.account.companyId) {
                    layer.open({
                        content: $translate.instant('autopsApp.booking.copyChoose', {
                            amt: $scope.guide.guideRates.length
                        }),
                        title: $translate.instant('global.messageInfo'),
                        btn: [$translate.instant('autopsApp.booking.copyAll'),
                            $translate.instant('autopsApp.booking.copyRev'),
                            $translate.instant('autopsApp.booking.copyExp'),
                            $translate.instant('autopsApp.booking.copyNone')],
                        yes: function(index, layero) {
                            for (var i = $scope.guide.guideRates.length - 1; i >= 0; i--) {
                                DataUtils.clearCopyRate($scope.guide.guideRates[i]);
                            }
                            layer.close(index);
                        },
                        btn2: function(index, layero) {
                            for (var i = $scope.guide.guideRates.length - 1; i >= 0; i--) {
                                if ($scope.guide.guideRates[i].isPay) {
                                    $scope.guide.guideRates.splice(i, 1);
                                    continue;
                                }
                                DataUtils.clearCopyRate($scope.guide.guideRates[i]);
                            }
                            layer.close(index);
                        },
                        btn3: function(index, layero) {
                            for (var i = $scope.guide.guideRates.length - 1; i >= 0; i--) {
                                if (!$scope.guide.guideRates[i].isPay) {
                                    $scope.guide.guideRates.splice(i, 1);
                                    continue;
                                }
                                DataUtils.clearCopyRate($scope.guide.guideRates[i]);
                            }
                            layer.close(index);
                        },
                        btn4: function(index, layero) {
                            $scope.guide.guideRates = [];
                            layer.close(index);
                        },
                        cancel: function() {
                            $scope.guide.guideRates = [];
                        }
                    });
                }
                else{
                    $scope.guide.guideRates = [];
                }
                angular.forEach($scope.guide.expressCargos, function(data){
                    data.id = null;
                    data.locked = false;
                    data.wmsCargoId = null;
                    data.refGuideId = null;
                    data.refParcelId = null;
                    data.location = null;
                    data.fba = null;
                    if(data.parcelGoods){
                        angular.forEach(data.parcelGoods, function(mixPl){
                            mixPl.id = null;
                        })
                    }
                })
                var now = new Date();
                $scope.guide.id = null;
                $scope.guide.remarks = null;
                $scope.guide.trackingId = null;
                $scope.guide.innerRemarks = null;
                $scope.guide.jobNum = null;
                $scope.guide.pickingNum = null;
                $scope.guide.mblNum = null;
                $scope.guide.soNum = null;
                $scope.guide.poNum = null;
                $scope.guide.poNumber = null;
                $scope.guide.etd = null;
                $scope.guide.eta = null;
                $scope.guide.dnSentTime = null;
                $scope.guide.paymentPaidTime = null;
                $scope.guide.paymentReceivedTime = null;
                $scope.guide.createdTime = null;
                $scope.guide.podTime = null;
                $scope.guide.costInputTime = null;
                $scope.guide.customReleaseTime = null;
                $scope.guide.orderTime = null;
                $scope.guide.otherTimeOne = null;
                $scope.guide.otherTimeTwo = null;
                $scope.guide.otherTimeThree = null;
                $scope.guide.pickupTime = null;
                $scope.guide.offShelfTime = null;
                $scope.guide.deliveryTime = null;
                $scope.guide.ctnNum = null;
                $scope.guide.ratesLocked = false;
                $scope.guide.isClosed = false;
                $scope.guide.etdLocked = false;
                $scope.guide.labelUrl = null;
                $scope.guide.trackingFinished = false;
                $scope.guide.isVoid = false;
                $scope.guide.masterId = null;
                $scope.guide.tkState = null;
                $scope.guide.customReleaseTime = null;
                $scope.guide.timeStamp = null;
                $scope.guide.paymentReceivedTime = null;
                $scope.guide.fba = null;
                $scope.guide.memo = null;
                $scope.guide.rating = null;
                $scope.guide.comments = null;
                $scope.guide.orderId = null;
                $scope.guide.orderUrl = null;
                $scope.guide.fulfillmentId = null;
                $scope.guide.submited = false;
                $scope.guide.guideId = null;
                $scope.guide.cargoIsOut = false;
                $scope.guide.orderPushed = false;
                $scope.guide.pushUpdated = false;
                $scope.guide.thirdpartyPlatform = null;
                $scope.guide.thirdpartyPlatformId = null;
                $scope.guide.thirdpartyPlatformRef = null;
                $scope.guide.isMaster = false;
                $scope.guide.labelRefNum = null;
                $scope.guide.ediResp = null;
                $scope.guide.autoCharged = false;
                $scope.guide.status = "new";
                $scope.guide.payParty = "shipfrom";
                $scope.guide.dutyPayParty = "shipto";

                guideCopy = angular.copy($scope.guide);
            }
            if(($rootScope.ISTMS && $scope.guide.customReleaseTime) || (!$rootScope.ISTMS && $scope.guide.otherTimeThree)){
                $scope.guide.cargoIsOut = true;
            }
            if(!$scope.guide.etd){
                $scope.guide.etd = new Date();
            }
            initVirtual();
            getCargoPics();
            guideCopy = angular.copy($scope.guide);
            updatePackListTtl();
            updatePackageTotal();
            $rootScope.LOAD_JOB_OWNERSHIP_COMABB($scope.guide);
            $rootScope.GET_PARCEL_SERVICE($scope.guide);
        });


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if ($rootScope.HAVENOAUTH("AU_AMEND_SHIPMENT") && $rootScope.account.companyId) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_SHIPMENT');
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && $rootScope.HAVENOAUTH('TMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if (angular.equals($scope.guide, guideCopy) && $scope.guide.id) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };
            if(!$rootScope.account.companyId
                && $scope.guide.otherTimeOne
                && $rootScope.ISTMS){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            var langName = $rootScope.EXPRESS_PARCEL_RQ_CHECK($scope.guide);
            if(langName){
                layer.alert($translate.instant('autopsApp.guide.requiredNullAlert', {param: langName}), {icon: 0});
                return;
            }
            $scope.isSaving = true;
            DataUtils.beforeParcelSave($scope.guide);
            if ($scope.guide.id !== null) {
                ExpressParcel.update($scope.guide, onSaveSuccess, onSaveError);
            } else {
                ExpressParcel.save($scope.guide, onSaveSuccess, onSaveError);
            }
        }
        function autoCheapestChannel (result) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.load(1, {shade: 0.3});
            ExpressParcel.autoCheapestChannel($scope.guide, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            layer.closeAll();
            $scope.guide = result;
            $scope.isSaving = false;
            if(!guideCopy.submited && $scope.guide.submited){
                layer.msg($translate.instant('global.messages.submitted'));
            }
            else {
                layer.msg($translate.instant('global.save_success'));
            }
            initVirtual();
            getCargoPics();
            guideCopy = angular.copy($scope.guide);
            $scope.$emit('autopsApp:expressParcelUpdate', guideCopy);
        }

        function onSaveError (result) {
            layer.closeAll();
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                $rootScope.ALERT_ERROR(result);
            }
        }

        function cargoIsOutChange () {
            if(!$rootScope.account.companyId){
                return;
            }
            guideCopy.cargoIsOut = !guideCopy.cargoIsOut;
            if (!angular.equals(guideCopy, $scope.guide)) {
                guideCopy.cargoIsOut = !guideCopy.cargoIsOut;
                $scope.guide.cargoIsOut = !$scope.guide.cargoIsOut;
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            ExpressParcel.cargoIsOutChange({
                id: $scope.guide.id
            }, function(result) {
                loadGuide();
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                loadGuide();
                layer.msg($translate.instant('global.save_fail'));
            });
        }

        function setStatus () {
            if(!$rootScope.account.companyId){
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            ExpressParcel.setStatus({
                expressId: $scope.guide.id,
                status: "checkOut"
            }, function(result) {
                loadGuide();
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                if (result.status == 403){
                    layer.msg($translate.instant('global.forbiddenBranch'));
                }
                else if (result.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.ratesNotNullAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }
            });
        };

        var loadGuide = function() {
            ExpressParcel.get({
                id: $scope.guide.id
            }, function(result) {
                $scope.guide = result;
                guideCopy = angular.copy($scope.guide);
                $scope.$emit('autopsApp:expressParcelUpdate', guideCopy);
                initVirtual();
            });
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar  = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.updateSales = function(item) {
            $scope.guide.salesName = item.name;
            $scope.guide.salesId = item.id;
            $scope.guide.team = item.team;
            $scope.guide.branch = item.branch;
        };
        $scope.updateCus = function(item) {
            $scope.guide.cusName = item.name;
            $scope.guide.cusId = item.id;
        };
        $scope.updateOperator = function(item) {
            $scope.guide.opName = item.name;
            $scope.guide.opId = item.id;
        };
        $scope.updateStorekeeper = function(item) {
            $scope.guide.storekeeper = item.name;
            $scope.guide.storekeeperId = item.id;
        };
        $scope.updateDispatcher = function(item) {
            $scope.guide.dispatcher = item.name;
            $scope.guide.dispatcherId = item.id;
        };
        $scope.opFilter = function(item) {
            return item.authorities.indexOf("ROLE_OPERATOR") !== -1;
        };
        $scope.dispatcherFilter = function(item) {
            return item.authorities.indexOf("ROLE_DISPATCHER") !== -1;
        };
        $scope.storekeeperFilter = function(item) {
            return item.authorities.indexOf("ROLE_WAREHOUSE") !== -1;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };

        $scope._onColoaderSelected = function(data) {
            $scope.guide.bookingToId = data.id;
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (!result.audited && result.coloaderMustAudit) {
                    $scope.guide.bookingToName = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                $scope.guide.bookingToId = result.venderId;
                $scope.guide.bookingToCode = result.code;
                $scope.guide.bookingToName = result.companyName;
                $scope.guide.bookingToEmail = result.email;
                if(result.contactList.length == 1){
                    updateContact("coloader", result.contactList[0]);
                }
                else {
                    choosedContact("coloader", result.contactList);
                }
            });
        }

        // $scope._onChannelSelected = function(data) {
        //     $scope.guide.channel = data;
        //     layer.load(1, {shade: 0.3});
        //     ExpressParcel.updateByChannel($scope.guide, function(result) {
        //         layer.closeAll();
        //         $scope.guide = result;
        //     }, function(){
        //         layer.closeAll();
        //     });
        // }

        // $scope._onCostChannelSelected = function(data) {
        //     $scope.guide.costChannel = data;
        //     layer.load(1, {shade: 0.3});
        //     ExpressParcel.updateByChannel($scope.guide, function(result) {
        //         layer.closeAll();
        //         $scope.guide = result;
        //     }, function(){
        //         layer.closeAll();
        //     });
        // }

        var choosedContact = function(type, contactList) {
            for(var i = contactList.length - 1; i >= 0; i--){
                if(contactList[i].defaultPic){
                    updateContact(type, contactList[i]);
                    return;
                }
            }
            $rootScope.chooseContactVenderType = type;
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return contactList;
                    }]
                }
            }).result.then(function(contact) {
                updateContact(type, contact);
                $translate.refresh();
            }, function() {});
        }

        var updateContact = function(type, contact){
            if (type == "client") {
                $scope.guide.shipperContactId = contact.id;
                if(contact.userId){
                    $scope.guide.shipperUserId = contact.userId;
                }
            } else if (type == "coloader") {
                $scope.guide.bookingToContactId = contact.id;
            } else if (type == "agent") {
                $scope.guide.deliveryContactId = contact.id;
                if(contact.userId){
                    $scope.guide.agentUserId = contact.userId;
                }
            }
        }
        $scope.onClientSelected = function(data) {
            if(haveWmsCargo()){
                $scope.guide.shipperName = guideCopy.shipperName;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                return;
            }
            OpsVender.getDTO({
                id: data.id,
                shipmentType: $scope.guide.shipmentType == "EXPRESS"?"EP":$scope.guide.shipmentType
            }, function(result) {
                if (!result.audited && result.clientMustAudit) {
                    $scope.guide.shipperName = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                $scope.guide.shipperId = result.venderId;
                $scope.guide.shipperName = result.companyName;
                $scope.guide.shipperUserId = result.userId;
                if(result.contactList.length == 1){
                    updateContact("client", result.contactList[0]);
                }
                else {
                    choosedContact("client", result.contactList);
                }
                if (result.cusId && result.cusName) {
                    $scope.guide.cusId = result.cusId;
                    $scope.guide.cusName = result.cusName;
                }
                if(result.creater && result.createdBy){
                    $scope.guide.salesId = result.createdBy;
                    $scope.guide.salesName = result.creater;
                    $scope.guide.team = result.team;
                    $scope.guide.branch = result.branch;
                }
                if (result.agentVid) {
                    var data = {};
                    data.id = result.agentVid;
                    $scope._onAgentSelected(data);
                }
                $rootScope.checkPoNum($scope.guide, 'PARCELUNI');
            });
        }


        $scope.clearService = function() {
            if(guideCopy.labelChannelCode != $scope.guide.labelChannelCode){
                $scope.guide.serviceType = null;
                $scope.guide.serviceDescription = null;
                return;
            }
            if(!$scope.guide.serviceDescription){
                $scope.guide.serviceType = null;
            }
        }

        $scope._onAgentSelected = function(data) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (!result.audited && result.agentMustAudit) {
                    $scope.guide.deliveryName = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                $scope.guide.deliveryId = result.venderId;
                $scope.guide.agentUserId = result.userId;
                $scope.guide.deliveryName = result.companyName;
                $scope.guide.deliverySecret = result.secret;
                $scope.guide.deliveryEmail = result.email;
                if(result.contactList.length == 1){
                    updateContact("agent", result.contactList[0]);
                }
                else {
                    choosedContact("agent", result.contactList);
                }
            });
        }


        $scope.showContactList = function(id, type) {
            if (!id) {
                return;
            }
            if ((type == "client" && $rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')) ||
                (type == "coloader" && $rootScope.HAVENOAUTH('FUN_VIEW_COLOADER')) ||
                (type == "agent" && $rootScope.HAVENOAUTH('FUN_VIEW_AGENT'))) {
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            var contact = {};
            contact.venderId = id;
            contact.contactId = getContactId(type);
            $rootScope.chooseContactVenderType = type;
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-detail.html',
                controller: 'OpsChangeLogDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return contact;
                    }]
                }
            }).result.then(function(result) {
                updateContact(type, result);
            }, function() {
            });
            // return OpsVender.get({id : venderId}).$promise;
        }

        var getContactId = function(type){
            if (type == "client") {
                return $scope.guide.shipperContactId;
            } else if (type == "coloader") {
                return $scope.guide.bookingToContactId;
            } else if (type == "agent") {
                return $scope.guide.deliveryContactId;
            }
        }

        $scope.updateGwVol = function() {
            if($rootScope.ISTMS && !$scope.guide.byCtns){return;}
            var vol = $scope.guide.vol;
            if(null == vol){vol = 0;}

            var gw = $scope.guide.gw;
            if(null == gw){gw = 0;}

            if ($scope.guide.divVolBy) {
                $scope.guide.vw = vol * 1000000 / $scope.guide.divVolBy;
            }
            if ($scope.guide.vw < gw) {
                $scope.guide.vw = gw;
            }
            $scope.guide.vw = DataUtils.round($scope.guide.vw, 3);
        };

        $scope.isUPS = function() {
            return $scope.guide.labelBindType == "UPS";
        }
        $scope.isDPD = function() {
            return $scope.guide.labelBindType == "DPD";
        }
        $scope.isDHL = function() {
            return $scope.guide.labelBindType == "DHL";
        }
        $scope.isFedex = function() {
            return $scope.guide.labelBindType == "FEDEX";
        }
        $scope.isEUB = function() {
            return $scope.guide.labelBindType == "EUB";
        }

        $scope.isDHLV4 = function() {
            return $scope.guide.labelBindType == "DHLV4";
        }

        $scope.shipServiceSelected = function(data) {
            $scope.guide.serviceType = data.type;
            // $scope.guide.serviceDescription = data.discription;
            if(data.international){
                if(data.international == "International"){
                    $scope.guide.international = true;
                }
                else {
                    $scope.guide.international = false;
                }
                $translate.refresh();
            }
            fedexHomeDeliveryCheck();
        }
        function fedexHomeDeliveryCheck() {
            if($scope.guide.labelBindType != "FEDEX"){
                return;
            }
            // layer.msg("Fedex address checking...", {
            //   shade: 0.3,
            //   time: 200000
            // });
            ExpressParcel.fedexHomeDeliveryCheck($scope.guide, function(result){
                layer.closeAll();
                $scope.guide.isResidential = result.isResidential;
                if($scope.guide.serviceDescription != result.serviceDescription){
                    var alertStr = $translate.instant('autopsApp.expressParcel.fedexAddressCheckResult', {
                        isResidential: $scope.guide.isResidential?$translate.instant('global.yes_answer'):$translate.instant('global.no_answer')
                    });
                    layer.confirm(alertStr, {
                        icon: 3,
                        btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                    }, function(index) {
                        $scope.guide.serviceType = result.serviceType;
                        $scope.guide.serviceDescription = result.serviceDescription;
                        layer.close(index);
                    }, function() {
                    });
                }
            });
            // }, $rootScope.ALERT_ERROR);
        }


        $scope.payPartyChange = function(payParty) {
            if(payParty != "thirdparty"){
                $scope.guide.accountId = null;
                $scope.guide.accountCode = null;
            }
        }
        $scope.dutyPayPartyChange = function(payParty) {
            if(payParty != "thirdparty"){
                $scope.guide.dutyPayPartyCode = null;
                $scope.guide.dutyPayPartyId = null;
            }
        }

        $scope.setUpsLtlService = function (isLtl) {
            $scope.guide.freightLtl = isLtl
            $scope.guide.serviceDescription = null;
            $scope.guide.serviceType = null;
        }
        $scope.shipServices = [];
        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.serviceDescription = "";
            $scope.guide.serviceType = "";
            $scope.guide.carrierCode = carrier.code;
            if($scope.guide.isClosed || $scope.guide.ratesLocked){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(!$scope.guide.guideRates){
                $scope.guide.guideRates = [];
            }
            // $rootScope.UPDATE_BY_CARRIER(carrier, 'expressParcel', $scope.guide);
            if(carrier.sellingCode){
                OpsCost.getOneByIdentification({
                    companyId: $rootScope.account.companyId,
                    type:$rootScope.ISTMS?2:24,
                    identification: carrier.sellingCode
                }, function(data){
                    $scope.guide.sellingMath = data.identification;
                    $scope.guide.sellingId = data.id;
                    if($rootScope.ISTMS){
                        getTmsRateByCost(data);
                    }
                    else {
                        getRateByCost(data);
                    }
                })
            }

            if(carrier.costCode){
                OpsCost.getOneByIdentification({
                    companyId: $rootScope.account.companyId,
                    type:$rootScope.ISTMS?3:25,
                    identification: carrier.costCode
                }, function(data){
                    $scope.guide.costMath = data.identification;
                    $scope.guide.costId = data.id;
                    if($rootScope.ISTMS){
                        getTmsRateByCost(data);
                    }
                    else {
                        getRateByCost(data);
                    }
                })
            }
        }

        var getTmsRateByCost = function(cost) {
            var rate = {};
            if(cost.type == 2){
                rate.isPay = false;
                rate.payer = $scope.guide.shipperName;
                rate.venderId = $scope.guide.shipperId;
            }
            else {
                rate.isPay = true;
                rate.payer = $scope.guide.bookingToName;
                rate.venderId = $scope.guide.bookingToId;
            }

            if($rootScope.ISTMS){
                rate.item = "Delivery Charge";
                rate.itemId = 4;
            }
            else {
                rate.item = "Freight";
                rate.itemId = 10;
            }

            rate.currency = $rootScope.account.baseCurrency;
            rate.payCurrency = $rootScope.account.baseCurrency;
            rate.payExchange = 1;
            rate.paymentTerm = "PPD";
            rate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            rate.inputTime = new Date();

            if($scope.guide.shipmentType == "FTL"){
                if($scope.guide.ftlType == "26FT"){
                    rate.unit = "26FT";
                    rate.count = 1;
                    rate.price = cost.sixth;

                }
                else if ($scope.guide.ftlType == "53FT"){
                    rate.unit = "53FT";
                    rate.count = 1;
                    rate.price = cost.seventh;
                }
                var ttl = rate.count * rate.price;
                rate.total = DataUtils.round(ttl, 2);
                rate.payAmount = rate.total;
                rate.remarks = "AutoCalc by " + cost.identification;
            }
            else {
                var amt = 0;
                var fw = 0;
                var fwp = 0;
                if(cost.serviceTerm == "CBM" && $scope.guide.vol){
                    amt = $scope.guide.vol;
                    rate.unit = "CBM";
                }
                else if(cost.serviceTerm == "TON" && $scope.guide.gw){
                    amt = $scope.guide.gw/1000;
                    rate.unit = "TON";
                }
                else if (cost.serviceTerm == "PALLET"){
                    rate.unit = "PALLET";
                    amt = $scope.guide.pallets;
                }
                else if (cost.serviceTerm == "KG"){
                    rate.unit = "KG";
                    amt = $scope.guide.gw;
                }

                if(cost.weightOne && cost.firstWeight){
                    fw = cost.weightOne;
                    fwp = cost.firstWeight;
                }

                rate.count = DataUtils.round(amt - fw, 4);

                if(cost.weightTwo && amt <= cost.weightTwo && cost.twenty){
                    rate.price = cost.twenty;
                }
                else if(cost.weightThree && amt <= cost.weightThree && cost.forty){
                    rate.price = cost.forty;
                }
                else if(cost.weightFour && amt <= cost.weightFour && cost.fortyHq){
                    rate.price = cost.fortyHq;
                }
                else if(cost.weightFive && amt <= cost.weightFive && cost.fortyFive){
                    rate.price = cost.fortyFive;
                } else {
                    rate.price = cost.fifth;
                }
                var ttl = rate.count * rate.price;
                ttl += fwp;
                rate.total = DataUtils.round(ttl, 2);
                rate.payAmount = rate.total;
                rate.remarks = "AutoCalc by: " + cost.identification + ", Count: " + amt;

                if(cost.weightSeven && rate.payAmount < cost.weightSeven){
                    rate.payAmount = rate.total = rate.price = cost.weightSeven;
                    rate.count = 1;
                    rate.remarks += " <MIN>";
                }
                else if(cost.weightEight && rate.payAmount > cost.weightEight){
                    rate.payAmount = rate.total = rate.price = cost.weightEight;
                    rate.count = 1;
                    rate.remarks += " <MAX>";
                }
                else if (fwp){
                    rate.price = rate.total;
                    rate.count = 1;
                    rate.remarks += " <FW+AW>";
                }
            }

            $scope.guide.guideRates.push(rate);
        }

        var getRateByCost = function(cost) {
            var rate = {};
            if(cost.type == 24){
                rate.isPay = false;
                rate.payer = $scope.guide.shipperName;
                rate.venderId = $scope.guide.shipperId;
            }
            else {
                rate.isPay = true;
                rate.payer = $scope.guide.bookingToName;
                rate.venderId = $scope.guide.bookingToId;
            }
            if ($translate.instant('global.enCn') == "cn"){
                rate.item = "运费";
            }
            else {
                rate.item = "Freight";
            }
            rate.itemId = 10;
            rate.currency = $rootScope.account.baseCurrency;
            rate.payCurrency = $rootScope.account.baseCurrency;
            rate.payExchange = 1;
            rate.paymentTerm = "PPD";
            rate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            rate.inputTime = new Date();

            var awRate = angular.copy(rate);
            if(cost.firstWeight){
                rate.unit = "BL";
                rate.count = 1;
                rate.remarks = "First weight: " + cost.weightOne + "KG";
                rate.price = cost.firstWeight;

                $scope.amountChange(rate);
                $scope.guide.guideRates.push(rate);
            }
            if(!cost.weightOne){
                cost.weightOne = 0;
            }
            if($scope.guide.vw > cost.weightOne){
                awRate.firstWeight = cost.weightOne;
                awRate.unit = "VW";
                awRate.count = $scope.guide.vw - cost.weightOne;
                awRate.count = DataUtils.round(awRate.count, 4);
                if($scope.guide.vw > cost.weightFive && cost.sixth){
                    awRate.price = cost.sixth;
                }
                else if($scope.guide.vw > cost.weightFour && cost.fifth){
                    awRate.price = cost.fifth;
                }
                else if($scope.guide.vw > cost.weightThree && cost.fortyFive){
                    awRate.price = cost.fortyFive;
                }
                else if($scope.guide.vw > cost.weightTwo && cost.fortyHq){
                    awRate.price = cost.fortyHq;
                } else {
                    awRate.price = cost.twenty;
                }
                awRate.remarks = "Added weight: " + $scope.guide.vw + " - " + cost.weightOne + " = " + awRate.count + "KG";
                $scope.amountChange(awRate);
                $scope.guide.guideRates.push(awRate);
            }
        }
        $scope.files = new Array();

        KEYBOARD = "expressParcelDialog";
        var saved = false;
        $(document).keydown(function(event) {
            if( KEYBOARD != "expressParcelDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    save();
                }
            }
        });

        $scope.$on('modal.closing', function(event, reason, closed){
            if(reason == "escape key press"){
                event.preventDefault(); //You can use this to prevent the modal from closing
                clear();
            }
        });

        function rateRequest(){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };

            if(!$scope.guide.payParty){
                layer.msg($translate.instant('autopsApp.expressParcel.payPartyNullAlert'));
                return;
            };
            layer.load(1, {shade: 0.3});
            ExpressParcel.getLabelRates($scope.guide, function(result){
                layer.closeAll();
                ratesResultPop(result);
            }, function(error){
                layer.closeAll();
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                ratesResultPop({});
            });
        }

        function getLabelApiRequestBody(type){
            layer.load(1, {shade: 0.3});
            ExpressParcel.getLabelApiRequestBody({
                type: type
            }, $scope.guide, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, $scope.guide.jobNum + "_" + type + ".txt");
            }, function(error){
                layer.closeAll();
            });
        }

        function ratesResultPop(result){
            var entity = {};
            entity.rateResult = result;
            entity.expressParcel = angular.copy($scope.guide);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-delete-dialog.html',
                controller: 'ExpressCargoDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                ExpressParcel.get({
                    id: $scope.guide.id
                }, function(newGuide) {
                    $scope.guide = newGuide;
                    guideCopy = angular.copy($scope.guide);
                    $scope.$emit('autopsApp:expressParcelUpdate', guideCopy);
                    initVirtual();
                    if($scope.guide.labelUrl || $scope.guide.mblNum){
                        $rootScope.OPS_ALERT("Label generated!");
                        return;
                    }
                    $scope.guide.carrier = result.channel;
                    if ($rootScope.OPSPORTS.expressLiners && $scope.guide.carrier) {
                        // 如果carrier与carrierCode对应，则无需修改carrierCode。不对应才需要修改carrierCode
                        var carrierSame = false;
                        angular.forEach($rootScope.OPSPORTS.expressLiners, function (carrier) {
                            if (carrier.name == $scope.guide.carrier && carrier.code == $scope.guide.carrierCode) {
                                carrierSame = true;
                            }
                        })
                        if (!carrierSame) {
                            angular.forEach($rootScope.OPSPORTS.expressLiners, function (carrier) {
                                if (carrier.name == $scope.guide.carrier) {
                                    $scope.guide.carrierCode = carrier.code;
                                }
                                if (carrier.name.indexOf("UPS") != -1 && $scope.guide.carrier.indexOf("UPS") != -1) {
                                    $scope.guide.carrierCode = carrier.code;
                                }
                                if (carrier.name.indexOf("DHL") != -1 && $scope.guide.carrier.indexOf("DHL") != -1) {
                                    $scope.guide.carrierCode = carrier.code;
                                }
                                if (carrier.name.indexOf("FedEx") != -1 && $scope.guide.carrier.indexOf("FedEx") != -1) {
                                    $scope.guide.carrierCode = carrier.code;
                                }
                            })
                        }
                    }
                    $scope.guide.labelRefNum = result.labelRefNum;
                    $scope.guide.serviceType = result.serviceCode;
                    $scope.guide.serviceDescription = result.service;
                    $scope.guide.shipFromId = result.addressId;
                    $scope.guide.labelBindType = result.labelBindType;
                    $scope.guide.shipfromCode = result.addressCode;
                    $scope.guide.labelChannelCode = result.labelChannelCode;
                    save();
                    $rootScope.PARCEL_SERVICES  = null;
                });
            })
        }

        $scope.updateFin = function(item) {
            $scope.guide.finId = item.id;
        };

        function getEltLabel(){
            if(!$scope.guide.soNum){
                layer.msg("SO/入仓号必填！");
                return;
            };
            if(!$scope.guide.cargoValueUsd){
                layer.msg("申报货值必填！");
                return;
            };
            if(!$scope.guide.goodsDiscription){
                layer.msg("货物品名必填！");
                return;
            };
            if(!$scope.guide.shiptoAttentionName){
                layer.msg("收件人公司名必填！");
                return;
            };
            if(!$scope.guide.shiptoEmailAddress){
                layer.msg("收件人邮箱必填！");
                return;
            };
            if(!$scope.guide.shiptoPhoneNumber){
                layer.msg("收件人电话必填！");
                return;
            };
            if(!$scope.guide.shiptoCountryCode || !$scope.guide.shiptoCity){
                layer.msg("收件人国家/ 城市必填！");
                return;
            };
            if($scope.guide.payParty && !$scope.guide.collect){
                layer.msg("代收金额必填！");
                return;
            };

            var isOk = true;
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if(!cargo.quantity){
                    isOk = false;
                }
                if(!cargo.cargoName){
                    isOk = false;
                }
            })
            if(!isOk){
                layer.msg("装箱单的货名 和 产品数量 必填！");
                return;
            }

            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            ExpressParcel.eltPostParcel($scope.guide, function(result){
                $rootScope.OPS_ALERT(result.msg);
                loadGuide();
            }, function(error){
                $rootScope.OPS_ALERT(error.data.msg);
            });
        }

        function getLabel(){
            if($rootScope.CLIENT_DEPOSITE && $rootScope.CLIENT_DEPOSITE.unpaidAmount){
                layer.msg($translate.instant('global.messages.creditOverLimit'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.expressParcel.submitAlert'));
                return;
            };
            if ($scope.guide.labelUrl && ($scope.guide.companyId != 347 || ($scope.guide.mblNum && $scope.guide.mblNum != 'null'))) {
                $rootScope.PDFPOP($scope.guide.labelUrl, null);
                return;
            };
            if($rootScope.isYuyue($scope.guide.companyId) && $scope.guide.serviceDescription == "FEDEX_2_DAY" && !$scope.guide.labelPkgType){
                layer.msg("包装类型未选择");
                return;
            }
            var provinceCode = angular.uppercase($scope.guide.shiptoProvinceCode);
            if(provinceCode && (provinceCode == "HI" || provinceCode == "PR" || provinceCode == "AK"
                 || provinceCode == "HAWAII" || provinceCode.indexOf("ALASKA") != -1)){
                layer.confirm($translate.instant('autopsApp.expressParcel.outUsAlert'), {
                    icon: 3,
                    title: "Alert",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    doGetLabel();
                }, function() {
                    return;
                });
            }
            else if($scope.guide.shiptoCountryCode && $scope.guide.shipperCountryCode
                    && angular.uppercase($scope.guide.shipperCountryCode) != angular.uppercase($scope.guide.shiptoCountryCode)){
                layer.confirm($translate.instant('autopsApp.expressParcel.internationalLabelAlert'), {
                    icon: 3,
                    title: "Alert",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    doGetLabel();
                }, function() {
                    return;
                });
            }
            else{
                doGetLabel();
            }
        }
        function doGetLabel() {
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 2000000
            });
            if(!$scope.guide.payParty){
                $scope.guide.payParty = "shipfrom";
            }
            if($scope.guide.freightLtl){
                ExpressParcel.getLtlLabel($scope.guide, function(result) {
                    layer.closeAll();
                    $rootScope.PDFPOP(result.labelUrl, "label");
                    loadGuide();
                }, $rootScope.ALERT_ERROR);
            }
            else {
                ExpressParcel.getLabel($scope.guide, function(result) {
                    layer.closeAll();
                    $rootScope.PDFPOP(result.labelUrl, "label");
                    loadGuide();
                }, $rootScope.ALERT_ERROR);
            }
        }

        function getLabelInfo(){
            if($rootScope.CLIENT_DEPOSITE && $rootScope.CLIENT_DEPOSITE.unpaidAmount){
                layer.msg($translate.instant('global.messages.creditOverLimit'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.submited) {
                layer.msg($translate.instant('autopsApp.expressParcel.submitAlert'));
                return;
            };
            layer.msg("Get label Info...", {
                shade: 0.3,
                time: 2000000
              });
            ExpressParcel.getLabelInfo($scope.guide, function(result) {
                layer.closeAll();
                loadGuide();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/express-cargos/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        return;
                    }
                    angular.forEach(data, function (cargo) {
                        $scope.guide.expressCargos.push(cargo);
                    })
                    updateTtl();
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                }, function(result) {
                    $scope.isSaving = false;
                });
            };
        };

        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function(checkLabelAccount) {
            if((!$scope.guide.shipFromId || !$scope.guide.warehouseId) && checkLabelAccount){
                return;
            }
            if(!checkLabelAccount && $rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    filterLabelAccountsByWhId(checkLabelAccount);
                    $scope.isSaving = false;
                }, function(result) {
                    $scope.isSaving = false;
                });
            }
            else {
                filterLabelAccountsByWhId(checkLabelAccount);
            }
        };

        function filterLabelAccountsByWhId(checkLabelAccount) {
            if($scope.guide.warehouseId && $scope.bindedAccounts && $scope.bindedAccounts.length > 0){
                for(var i = $scope.bindedAccounts.length - 1; i >= 0; i--){
                    if(checkLabelAccount){
                        if($scope.guide.shipFromId == $scope.bindedAccounts[i].id
                            && $scope.bindedAccounts[i].whId
                            && $scope.bindedAccounts[i].whId != $scope.guide.warehouseId){
                            $scope.guide.labelBindType = null;
                            $scope.guide.shipFromId = null;
                            $scope.guide.shipfromCode = null;
                            $scope.guide.serviceDescription = "";
                            $scope.guide.serviceType = "";
                        }
                    }
                    else {
                        if($scope.bindedAccounts[i].whId && $scope.bindedAccounts[i].whId != $scope.guide.warehouseId){
                            $scope.bindedAccounts.splice(i, 1);
                        }
                    }
                }
            }
        }

        $scope._onAddressSelected = function(data, type) {
            if(type == "thirdparty"){
                $scope.guide.accountId = data.id;
            }
            else if(type == "shipfrom" || type == "labelBindType"){
                if(type == "labelBindType" || data.bindType){
                    $rootScope.PARCEL_SERVICES  = null;
                    $scope.guide.labelBindType = data.bindType;
                    $scope.guide.shipFromId = data.id;
                    $scope.guide.shipfromCode = data.code;
                    $scope.guide.serviceDescription = "";
                    $scope.guide.serviceType = "";
                    $scope.guide.labelChannelCode = null;
                    if(data.bindType == "UPS" || data.bindType == "DHL"
                        || data.bindType == "FEDEX" || data.bindType == "DPD" || data.bindType == "EUB"){
                        $scope.guide.carrierCode = angular.lowercase(data.bindType);
                        $scope.guide.carrier = data.bindType;
                    }
                    if($scope.guide.shipperId && data.id){
                        layer.load(1, {shade: 0.3});
                        AddressBook.getChargeChannel({
                            addressBookId: data.id,
                            venderId: $scope.guide.shipperId
                        }, function(result){
                            if(result && result.chargeChannel && result.chargeChannel != $scope.guide.channel){
                                if(result.chargeChannel){
                                    $scope.guide.channel = result.chargeChannel;
                                }
                                ExpressParcel.updateByChannel($scope.guide, function(result) {
                                    layer.closeAll();
                                    $scope.guide = result;
                                }, function(){
                                    layer.closeAll();
                                });
                            }
                            else {
                                layer.closeAll();
                            }
                        }, function(){
                            layer.closeAll();
                        })
                    }
                    if(data.servicesJson){
                        var services = angular.fromJson(data.servicesJson);
                        if(services && services.length == 1){
                            $scope.guide.serviceDescription = services[0].discription;
                            $scope.guide.serviceType = services[0].type;
                        }
                    }
                    $rootScope.GET_PARCEL_SERVICE($scope.guide);
                    if($scope.guide.shipperAddressLineOne && type != "shipfrom"){
                        return;
                    }
                }
                $scope.guide.shipperCompanyName = data.companyName;
                if(data.addressOne){
                    $scope.guide.shipperAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shipperAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shipperAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }
                if(data.addressTwo){
                    $scope.guide.shipperAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shipperAddressLineThree = data.addressThree.substring(0, 35);
                }
                if(!$scope.guide.labelBindType && data.bindType){
                    $scope.guide.labelBindType = data.bindType;
                    $scope.guide.shipfromCode = data.code;
                    $scope.guide.shipFromId = data.id;
                }
                else {
                    if(data.binded){
                        $rootScope.PARCEL_SERVICES  = null;
                        $scope.guide.labelBindType = data.bindType;
                        $scope.guide.shipfromCode = data.code;
                        $scope.guide.shipFromId = data.id;
                    }
                }
                $scope.guide.shipperCode = data.code;
                $scope.guide.shipperAttentionName = data.attn;
                $scope.guide.shipperPostalCode = data.postCode;
                $scope.guide.shipperCountryCode = data.countryCode;
                $scope.guide.shipperProvinceCode = data.province;
                $scope.guide.shipperCity = data.city;
                $scope.guide.shipperPhoneNumber = data.tel;
                $scope.guide.shipperEmailAddress = data.email;
                $scope.guide.shipperTaxId = data.taxId;
                $scope.guide.shipperBl = null;

                getGuideShipperBl();
            }
            else if (type == 'shipto'){
                $scope.guide.shipToId = data.id;
                $scope.guide.shiptoCompanyName = data.companyName;

                if(data.addressOne){
                    $scope.guide.shiptoAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shiptoAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shiptoAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }
                if(data.addressTwo){
                    $scope.guide.shiptoAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shiptoAddressLineThree = data.addressThree.substring(0, 35);
                }
                $scope.guide.shiptoDoorplate = data.doorplate;
                $scope.guide.shiptoAttentionName = data.attn;
                $scope.guide.shiptoPostalCode = data.postCode;
                $scope.guide.shiptoCountryCode = data.countryCode;
                $scope.guide.shiptoProvinceCode = data.province;
                $scope.guide.shiptoCity = data.city;
                $scope.guide.shiptoPhoneNumber = data.tel;
                $scope.guide.shiptoEmailAddress = data.email;
                $scope.guide.cneeBl = null;
                $scope.guide.isResidential = data.isResidential;
                getDeliveryBl();
            }
            else {
                if(type == 'ownImporterId' && data.countryCode != $scope.guide.shiptoCountryCode){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.importerCountryDiffAlert'));
                    return;
                }
                $scope.guide[type] = data.id;
            }
        }

        $scope.saveAddress = function(type) {
            var addressBook = {};
            if(type == "shipfrom"){
                if(!$scope.guide.shipperCode
                    || !$scope.guide.shipperCompanyName
                    || !$scope.guide.shipperAddressLineOne
                    || !$scope.guide.shipperAttentionName
                    || !$scope.guide.shipperPhoneNumber){
                    layer.msg($translate.instant('autopsApp.expressParcel.saveAddressAlert'));
                    return;
                }
                addressBook.code = $scope.guide.shipperCode;
                addressBook.companyName = $scope.guide.shipperCompanyName;
                addressBook.addressOne = $scope.guide.shipperAddressLineOne;
                addressBook.addressTwo = $scope.guide.shipperAddressLineTwo;
                addressBook.addressThree = $scope.guide.shipperAddressLineThree;
                addressBook.attn = $scope.guide.shipperAttentionName;
                addressBook.postCode = $scope.guide.shipperPostalCode;
                addressBook.countryCode = $scope.guide.shipperCountryCode;
                addressBook.province = $scope.guide.shipperProvinceCode;
                addressBook.city = $scope.guide.shipperCity;
                addressBook.tel = $scope.guide.shipperPhoneNumber;
                addressBook.email = $scope.guide.shipperEmailAddress;
                addressBook.taxId = $scope.guide.shipperTaxId;
            }
            else {
                if(!$scope.guide.shiptoCode
                    || !$scope.guide.shiptoCompanyName
                    || !$scope.guide.shiptoAddressLineOne
                    || !$scope.guide.shiptoAttentionName
                    || !$scope.guide.shiptoPhoneNumber){
                    layer.msg($translate.instant('autopsApp.expressParcel.saveAddressAlert'));
                    return;
                }
                addressBook.code = $scope.guide.shiptoCode;
                addressBook.companyName = $scope.guide.shiptoCompanyName;
                addressBook.addressOne = $scope.guide.shiptoAddressLineOne;
                addressBook.addressTwo = $scope.guide.shiptoAddressLineTwo;
                addressBook.doorplate = $scope.guide.shiptoDoorplate;
                addressBook.addressThree = $scope.guide.shiptoAddressLineThree;
                addressBook.attn = $scope.guide.shiptoAttentionName;
                addressBook.postCode = $scope.guide.shiptoPostalCode;
                addressBook.countryCode = $scope.guide.shiptoCountryCode;
                addressBook.province = $scope.guide.shiptoProvinceCode;
                addressBook.city = $scope.guide.shiptoCity;
                addressBook.tel = $scope.guide.shiptoPhoneNumber;
            }
            AddressBook.save(addressBook, function(result){
                if(type == "shipfrom"){
                    $scope.guide.shipFromId = result.id;
                }
                else {
                    $scope.guide.shipToId = result.id;
                }
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(er){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }
        function getDeliveryBl() {
            if(!$scope.guide.cneeBl){
                $scope.guide.cneeBl = getCneeBl();
            }
            $scope.guide.deliveryBl = $scope.guide.cneeBl;
        }
        function getGuideShipperBl() {
            if(!$scope.guide.shipperBl){
                $scope.guide.shipperBl = getShipperBl();
            }
            $scope.guide.guideShipperBl = $scope.guide.shipperBl;
        }

        function getShipperBl(){
            var blInfo = "";
            if($scope.guide.shipperCompanyName){
                blInfo += $scope.guide.shipperCompanyName + "\n";
            }
            if($scope.guide.shipperAddressLineOne){
                blInfo += $scope.guide.shipperAddressLineOne + " ";
            }
            if($scope.guide.shipperAddressLineTwo){
                blInfo += $scope.guide.shipperAddressLineTwo + " ";
            }
            if($scope.guide.shipperAddressLineThree){
                blInfo += $scope.guide.shipperAddressLineThree + " ";
            }
            if($scope.guide.shipperCity){
                blInfo += $scope.guide.shipperCity + " ";
            }
            if($scope.guide.shipperProvinceCode){
                blInfo += $scope.guide.shipperProvinceCode + " ";
            }
            if($scope.guide.shipperCountryCode){
                blInfo += $scope.guide.shipperCountryCode + " ";
            }
            if($scope.guide.shipperPostalCode){
                blInfo += $scope.guide.shipperPostalCode + " ";
            }
            blInfo += "\n";

            if($scope.guide.shipperAttentionName){
                blInfo += $scope.guide.shipperAttentionName + " ";
            }
            if($scope.guide.shipperPhoneNumber){
                blInfo += $scope.guide.shipperPhoneNumber;
            }
            return blInfo;
        }
        function getCneeBl(){
            var blInfo = "";
            if($scope.guide.shiptoCompanyName){
                blInfo += $scope.guide.shiptoCompanyName + "\n";
            }
            if($scope.guide.shiptoAddressLineOne){
                blInfo += $scope.guide.shiptoAddressLineOne + " ";
            }
            if($scope.guide.shiptoAddressLineTwo){
                blInfo += $scope.guide.shiptoAddressLineTwo + " ";
            }
            if($scope.guide.shiptoAddressLineThree){
                blInfo += $scope.guide.shiptoAddressLineThree + " ";
            }
            if($scope.guide.shiptoCity){
                blInfo += $scope.guide.shiptoCity + " ";
            }
            if($scope.guide.shiptoProvinceCode){
                blInfo += $scope.guide.shiptoProvinceCode + " ";
            }
            if($scope.guide.shiptoCountryCode){
                blInfo += $scope.guide.shiptoCountryCode + " ";
            }
            if($scope.guide.shiptoPostalCode){
                blInfo += $scope.guide.shiptoPostalCode + " ";
            }
            blInfo += "\n";
            if($scope.guide.shiptoAttentionName){
                blInfo += $scope.guide.shiptoAttentionName + " ";
            }
            if($scope.guide.shiptoPhoneNumber){
                blInfo += $scope.guide.shiptoPhoneNumber;
            }
            return blInfo;
        }


        $scope.previewReport = function(reportCode, fileType) {
            getGuideShipperBl();
            getDeliveryBl();

            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.reportType = reportCode;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = fileType;
            if(!guideReportDTO.expressCargos){
                guideReportDTO.expressCargos = [];
            }
            // for(var i = guideReportDTO.expressCargos.length; i < 7; i ++){
            //     guideReportDTO.expressCargos.push({});
            // }
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, $scope.guide.jobNum + "_BOL");
            }, function(error) {

            });
        }


        $scope.printAllBol = function(reportCode) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.reportType = reportCode;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            ExpressParcel.printAllBol({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }

        var haveWmsCargo = function() {
            var haveWmsCargo = false;
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if(cargo.wmsCargoId){
                    haveWmsCargo = true;
                }
            })
            return haveWmsCargo;
        }
        $scope.setVoid = function() {
            if(!$scope.guide.id){
                return;
            }
            if($rootScope.HAVENOAUTH('AU_VOID_ORDER')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_ORDER');
                return;
            }
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            if ($scope.guide.masterId) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.bindedAlert'));
                return;
            }
            if (($scope.guide.masterId==0 || $scope.guide.isMaster) && $scope.guide.expressCargos.length > 0) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.bindedAlert'));
                return;
            }
            if(!$scope.guide.isVoid && $scope.guide.cargoIsOut){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.status.wmsOut'));
                return;
            }
            if ($scope.isYuyueEdiFm()) {
                $rootScope.OPS_ALERT("委托订单，不能作废！");
                return;
            }
            if ($scope.guide.isVoid) {
                ExpressParcel.setVoid({id:$scope.guide.id}, function(result) {
                    loadGuide();
                    layer.msg($translate.instant('global.valid_success'));
                }, function(result){
                    $rootScope.ALERT_ERROR(result);
                });
            } else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                layer.confirm($translate.instant('autopsApp.guide.delAllRatesAlert'), {
                    icon: 3,
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    ExpressParcel.setVoid({id:$scope.guide.id}, function(result) {
                        loadGuide();
                        layer.msg($translate.instant('global.invalid_success'));
                    }, function(result){
                        $rootScope.ALERT_ERROR(result);
                    });
                }, function() {
                });
            }
        }

        $scope.deleteGuide = function() {
            if(haveWmsCargo()){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('AU_DEL_BIZ')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_BIZ');
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.delete.question', {
                id: $scope.guide.jobNum
            }), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                ExpressParcel.delete({
                    id: $scope.guide.id
                }, function() {
                    layer.close(index);
                    $scope.$emit('autopsApp:parcelDelUpdate', $scope.guide);
                    $uibModalInstance.dismiss();
                    layer.msg($translate.instant('global.delete_success'));
                }, function (error) {
                    if(error.status == 403){
                        layer.msg($translate.instant('autopsApp.guide.clearWmsCargoAlert'));
                    }
                    else if(error.status == 409){
                        layer.msg($translate.instant('global.delete_forbidden'));
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }, function() {});
        }

        $scope.getParcelPuLabel = function() {
            ExpressParcel.getParcelPuLabel($scope.guide, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.printLabel = function(reportType) {
            ExpressParcel.printLabel({
                reportType: reportType
            }, $scope.guide, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        $scope.setStatus = function(fd, status) {
            if($scope.guide[fd] && $rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');
                return;
            }
            if (!$scope.guide.id || !angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if($scope.guide[fd]){
                layer.confirm($translate.instant('autopsApp.guide.clearNodeAlert'), {icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        doSetStatus(status, null);
                        layer.close(index);
                    }, function() {
                });
                return;
            }

            $uibModal.open({
                templateUrl: 'app/entities/ops-log/ops-log-dialog.html',
                controller: 'OpsLogDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return $scope.guide[fd];
                    }]
                }
            }).result.then(function(result) {
                doSetStatus(status, result);
            }, function() {
            });
        }
        function doSetStatus(status, timeStr) {
            ExpressParcel.setStatus({
                expressId: $scope.guide.id,
                status: status,
                timeStr: timeStr
            }, function(result) {
                loadGuide();
                layer.msg($translate.instant('global.update_success'));
            }, function(result) {
                if (result.status == 404){
                    layer.msg($translate.instant('global.refreshAlert'));
                }
                else if (result.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.ratesNotNullAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }
            });
        }

        $scope.clearNodeTime = function(fd) {
            if($scope.guide[fd] && $rootScope.HAVENOAUTH('AU_AMEND_TIMESTAMP')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_TIMESTAMP');
                return;
            }
            $scope.guide[fd] = null;
            $translate.refresh();
        }


        var onAuthError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.authErrorAlert'));
            } else if (result.status === 409) {
                layer.msg($translate.instant('global.same_company_alert'));
            } else if (result.status === 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            initVirtual();
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        $scope.authorizeBranch = function() {
            if (!$scope.guide.bookingToId) {
                layer.msg($translate.instant('autopsApp.guide.authorizeBranchNull'));
                return;
            }
            if(!$scope.guide.submited){
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.guide.authorizeBranch');

            var content = $scope.guide.bookingToName + $translate.instant('autopsApp.guide.home.auth_send_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                ExpressParcel.authorize($scope.guide, onSendSuccess, onAuthError);
            }, function() {
                return;
            });
        }
        var onSynSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result.result;
            var entity = {};
            entity.changeLog = result.changeLog;
            if(result.changeLog){
                $uibModal.open({
                    templateUrl: 'app/entities/ops-change-log/ops-change-log-dialog.html',
                    controller: 'OpsChangeLogDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return entity;
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            }
            $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.synSuccess'));
        };

        var onSynFailed = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.guide.synFailed'));
        };
        $scope.synExpressParcel = function() {
            if($rootScope.HAVENOAUTH("SYN_EDI_DATA")){
                $rootScope.FORBIDDEN_BY_ALERT('SYN_EDI_DATA');
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $scope.isSaving = true;
            ExpressParcel.synExpressParcel($scope.guide, onSynSuccess, onSynFailed);
        }

        $scope.unbind = function() {
            layer.confirm($translate.instant('autopsApp.guide.unbindAlert'), {
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                ExpressParcel.unbind({
                    guideId: $scope.guide.id,
                    branchGuideId: $scope.guide.branchGuideId
                }, function(result) {
                    $scope.guide.branchGuideId = null;
                    layer.close(index);
                    layer.msg($translate.instant('global.messages.submitted'));
                }, onSaveError);
            }, function() {});
        }


        $scope.podSend = function(type) {
            var subNumStr = "";
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if(cargo.fba){
                    subNumStr = subNumStr + cargo.fba + " ";
                }
            })
            if(!subNumStr){
                subNumStr = $scope.guide.jobNum;
            }
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;

            email.subject = subNumStr + "-- POD";
            email.content = "Attached POD copy of " +subNumStr + "\n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.isExpress = true;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
            }, function(result) {});
        }

        var showAllShipFrom = false;
        $scope.shipFromFilterChange = function(value) {
            showAllShipFrom = value;
        }
        $scope.shipFromFilter = function(item) {
            if(item.companyId == 0 && !showAllShipFrom){
                return false;
            }
            return true;
        };

        $scope.dhlMailTypes = [{
            type: "2",
            discription: "Irregular Parcel"
        },{
            type: "3",
            discription: "Machinable Parcel"
        },{
            type: "6",
            discription: "BPM Machinable"
        },{
            type: "7",
            discription: "Parcel Select Mach"
        },{
            type: "8",
            discription: "Parcel Select NonMach"
        },{
            type: "9",
            discription: "Parcel Select NonMach"
        },{
            type: "20",
            discription: "Marketing Parcel < 6oz"
        },{
            type: "30",
            discription: "Marketing Parcel >= 6oz"
        }];

        $scope.expressCargoPop = function(cargo) {
           // if(!cargo.id){
           //      layer.msg($translate.instant('global.saveAlert'));
           //      return;
           //  }
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-detail.html',
                controller: 'ExpressCargoDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return $scope.guide;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            })
        }

        $scope.ratesPop = function() {
             if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(!$rootScope.account.companyId){
                $rootScope.RATESPOP($scope.guide, "expressParcel", null);
                return;
            }
            $rootScope.PARCEL_RATES_POP($scope.guide);
        }

        var unsubscribeLoadParcel = $rootScope.$on('autopsApp:loadParcel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }
        });
        $scope.$on('$destroy', unsubscribeLoadParcel);

        $scope.orderAcknowledgement = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var expressParcels = [];
            expressParcels.push(angular.copy($scope.guide));
            ExpressParcel.orderAcknowledgement(expressParcels, function(){
                loadGuide();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        $scope.shipNoice = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var expressParcels = [];
            expressParcels.push(angular.copy($scope.guide));
            ExpressParcel.shipNoice(expressParcels, function(){
                loadGuide();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

        $scope.sendInvoice = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var expressParcels = [];
            expressParcels.push(angular.copy($scope.guide));
            ExpressParcel.sendInvoice(expressParcels, function(){
                loadGuide();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        // $scope.deleteFulfillment = function (){
        //     ExpressParcel.deleteFulfillment($scope.guide, onSaveSuccess, onSaveError);
        // }

        // $scope.phoneNumCheck = function (phoneNum){
        //     if($scope.isUPS() && phoneNum.length != 10){
        //         layer.msg($translate.instant('global.saveAlert'));
        //         $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.phoneNumAlert'));
        //     }
        // }
        $scope.getCloseouts = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(!$scope.guide.labelUrl){
                layer.msg($translate.instant('autopsApp.expressParcel.noLabelAlert'));
                return;
            }
            var expressParcels = [];
            expressParcels.push(angular.copy($scope.guide));
            $scope.isSaving = true;
            ExpressParcel.getCloseouts(expressParcels, function(result){
                $scope.isSaving = false;
                $rootScope.PRINTBYTES(result.data);
                loadGuide();
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            })
        }

        $scope.rePickingUp = function (){
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            var expressParcels = [];
            expressParcels.push(angular.copy($scope.guide));
            $scope.isSaving = true;
            ExpressParcel.rePickingUp(expressParcels, function(result){
                $scope.isSaving = false;
                loadGuide();
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            })
        }

        function uploadLabel (file) {
            if (!file) {
                return;
            }
            if (!$scope.guide.mblNum) {
                layer.msg($translate.instant('autopsApp.expressParcel.mblNullAlert'));
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!DataUtils.isPdf(file.name) && !DataUtils.isPic(file.name)) {
                layer.msg("Please upload the PDF/Picture file");
                return;
            }
            if($scope.guide.labelUrl){
                layer.confirm($translate.instant('autopsApp.expressParcel.combineLabelAlert'), {
                    title: "Msg",
                    icon: 3,
                    btn: [$translate.instant('entity.action.replace'), $translate.instant('entity.action.merge')]
                }, function(index) {
                    layer.close(index);
                    uploadLabelDo(file, false);
                }, function() {
                    uploadLabelDo(file, true);
                });
            }
            else{
                uploadLabelDo(file, false);
            }
        };

        function uploadLabelDo (file, combineLabel) {
            var fd = new FormData();
            var filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $scope.guide.companyId);
            fd.append('combineLabel', combineLabel);
            $http.post(
                'api/express-parcels/uploadLabel', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        $scope.files = [];
                        loadGuide();
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        }


        function uploadPod (file) {
            if (!file) {
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var fd = new FormData();
            var filePath = "/" + "expressParcel/" + $scope.guide.id + "/POD/POD-";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $scope.guide.companyId);
            $http.post(
                'api/express-parcels/uploadPod', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        $scope.files = [];
                        loadGuide();
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        function uploadWatermarkPic (file) {
            if (!file) {
                return;
            }
            if (!DataUtils.isPdf(file.name) && !DataUtils.isPic(file.name)) {
                layer.msg("Please upload the PDF/Picture file");
                return;
            }
            if (!$scope.guide.poNum) {
                layer.msg($translate.instant('autopsApp.expressParcel.poNumRequiredAlert'));
                return;
            };
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var fd = new FormData();
            var filePath = "/" + "expressParcel/" + $scope.guide.id + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('poNum', $scope.guide.poNum);
            fd.append('companyId', $scope.guide.companyId);
            $http.post(
                'api/express-parcels/uploadWatermarkPic', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if(!$scope.files){
                        $scope.files = [];
                    }
                    $scope.files.push(data);
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };


        $scope.nextslsLabel = function (){
            $scope.isSaving = true;
            ExpressParcel.nextslsLabel($scope.guide, function(result){
                $scope.isSaving = false;
                loadGuide();
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.noRecord'));
            })
        }

        $scope.checkJobNum = function() {
            if (!$scope.guide.jobNum || guideCopy.jobNum == $scope.guide.jobNum) {
                return;
            } else {
                ExpressParcel.checkJobNum({
                    companyId: $rootScope.account.companyId,
                    guideId: $scope.guide.id,
                    jobNum: $scope.guide.jobNum
                }, function(result) {
                    if (result.isRepeated) {
                        $scope.guide.jobNum = null;
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.jobNumRepeated'));
                        return;
                    }
                });
            }
        }
        var updateUnsubscribe = $rootScope.$on('autopsApp:isRemoteConfirmed', function(event, result) {
            if(!result){
                $scope.guide.isRemote = false;
            }
            if(result.postcode == $scope.guide.shiptoPostalCode && result.country == $scope.guide.shiptoCountryCode ){
                $scope.guide.isRemote = true;
            }
        });
        $scope.$on('$destroy', updateUnsubscribe);

        function addToConsol (isFms) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.prompt({
                formType: 0,
                value: '',
                title: $translate.instant('autopsApp.guide.inputJobPoNum')
            }, function(value, index, elem) {
                ExpressParcel.addToParcelConsol({
                    companyId: $rootScope.account.companyId,
                    masterId: $scope.guide.id,
                    jobNum: value,
                    isVirtual: $scope.guide.isVirtual,
                    isFms: isFms
                }, function (argument) {
                    loadGuide();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(result){
                    if(result.status == 423){
                        layer.msg($translate.instant('global.lockedAlert'));
                    }
                    else if(result.status == 403){
                        layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                    }
                    else if(result.status == 409){
                        layer.msg($translate.instant('autopsApp.guide.home.combineConflict'));
                    }
                    else if(result.status == 404){
                        layer.msg($translate.instant('global.noRecord'));
                    }
                    else{
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                })
            });
        }
        $scope.setConsolidated = function() {
            if (!$scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_CONSOLIDATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CONSOLIDATE_UNLOCK');
                return;
            }
            if($scope.isVMed || $scope.isSaving){
                return;
            }
            $scope.guide.consolidated = !$scope.guide.consolidated;
        }
        $scope.setIsClosed = function() {
            if($scope.isVMed || $scope.isSaving){
                return;
            }
            if (!$scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if ($scope.guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            if(!$scope.guide.id || $scope.isVMed){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.load(1, {shade: 0.3});
            ExpressParcel.setIsClosed({id:$scope.guide.id}, function(result) {
                loadGuide();
                layer.closeAll();
                layer.msg($translate.instant('global.save_success'));
            }, function(result){
                layer.closeAll();
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }
        $scope.createReturnOrder = function() {
            if($rootScope.HAVENOAUTH('WMS_RETURN')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if(!$scope.guide.submited || !$scope.guide.jobNum){
                layer.msg($translate.instant('autopsApp.expressParcel.submitAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                controller: 'WmsInDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return ExpressParcel.createReturnOrder($scope.guide).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.getReturnLabel = function() {
            if($rootScope.HAVENOAUTH('WMS_RETURN')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if(!$scope.guide.submited || !$scope.guide.jobNum){
                layer.msg($translate.instant('autopsApp.expressParcel.submitAlert'));
                return;
            }
             $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                controller: 'ExpressParcelDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return ExpressParcel.getReturnLabel($scope.guide).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }


        $scope.addressCheck = function() {
            if(angular.uppercase($scope.guide.shiptoCountryCode) != "US"){
                layer.msg($translate.instant('autopsApp.expressParcel.notUsAlert'));
                return;
            }
            ExpressParcel.addressCheck($scope.guide, function(result){
                $scope.guide.isResidential = result.expressParcel.isResidential;
                $scope.guide.shiptoAddressLineOne = result.expressParcel.shiptoAddressLineOne;
                $scope.guide.shiptoAddressLineTwo = result.expressParcel.shiptoAddressLineTwo;
                $scope.guide.shiptoCity = result.expressParcel.shiptoCity;
                $scope.guide.shiptoProvinceCode = result.expressParcel.shiptoProvinceCode;
                $scope.guide.shiptoPostalCode = result.expressParcel.shiptoPostalCode;
                $scope.guide.onHold = result.expressParcel.onHold;
                $scope.guide.isRemote = result.expressParcel.isRemote;
                $scope.guide.memo = result.expressParcel.memo;

                if(result.codeType && result.codeType.description){
                    $rootScope.OPS_ALERT(result.codeType.description);
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.addressCheckResult', {
                        isResidential: $scope.guide.isResidential?$translate.instant('global.yes_answer'):$translate.instant('global.no_answer'),
                        isRemote: $scope.guide.isRemote?$translate.instant('global.yes_answer'):$translate.instant('global.no_answer')
                    }));
                }
            }, function(error){
                layer.msg($translate.instant('global.requestFail'));
            })
        }
        $scope.getCityState = function() {
            if(angular.uppercase($scope.guide.shiptoCountryCode) != "US"){
                return;
            }
            if($scope.guide.shiptoProvinceCode && $scope.guide.shiptoCity){
                return;
            }
            if(!$scope.guide.shiptoPostalCode || $scope.guide.shiptoPostalCode.length < 5){
                return;
            }
            ExpressParcel.getCityState($scope.guide, function(result){
                if(result.shiptoCity){
                    $scope.guide.shiptoCity = result.shiptoCity;
                }
                if(result.shiptoProvinceCode){
                    $scope.guide.shiptoProvinceCode = result.shiptoProvinceCode;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $scope.getZipCode = function() {
            if(angular.uppercase($scope.guide.shiptoCountryCode) != "US"){
                return;
            }
            if(!$scope.guide.shiptoProvinceCode || !$scope.guide.shiptoCity || $scope.guide.shiptoPostalCode){
                return;
            }
            ExpressParcel.getZipCode($scope.guide, function(result){
                if(result.shiptoPostalCode){
                    $scope.guide.shiptoPostalCode = result.shiptoPostalCode;
                }
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $scope.getAccountBindName = function(accountBindId) {
            AccountBind.get({id : accountBindId}, function(result){
                if(result && result.name){
                    layer.msg(result.name);
                }
            });
        }

        function imageAutomaticProcessing () {
            layer.load(1, {shade: 0.3});
            ExpressParcel.imageAutomaticProcessing({
                id: $scope.guide.id
            }, function(result){
                $scope.files = [];
                layer.closeAll();
                layer.msg($translate.instant('global.processingComplete'));

            });
        }

        $scope.amendEtd = function() {
            if(!$scope.guide.etdLocked){
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var guideDto = {};
            guideDto.id = $scope.guide.id;
            guideDto.etd = $scope.guide.etd;
            guideDto.lockEDate = true;
            guideDto.shipmentType = "expressParcel";
            $uibModal.open({
                templateUrl: 'app/entities/guide/amend-etd.html',
                controller: 'AmendEtdController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return guideDto;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
                $translate.refresh();
            }, function() {});
        }

        vm.accountBinds = [];
        function getAccountBinds (){
            if($scope.guide.thirdpartyPlatformId || !$rootScope.account.companyId || vm.accountBinds.length > 0){
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: $rootScope.ISTMS? "tms" : "orders"
            }, function(result){
                vm.accountBinds = result;
            })
        }
        function pushOneOrder (accountBind) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($rootScope.isYuyue($scope.guide.companyId)
                && $rootScope.tmsTypes.indexOf($scope.guide.shipmentType) !== -1){
                if(!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                    $rootScope.OPS_ALERT("没有货物明细");
                    return;
                }
                if($scope.guide.isMaster || $scope.guide.masterId == 0){
                    if($scope.guide.shipmentType != "LTL"){
                        $rootScope.OPS_ALERT("服务类型必须是“拼车配送(LTL)”");
                        return;
                    }
                    for(var i = 0; i < $scope.guide.expressCargos.length; i++){
                        if(!$scope.guide.expressCargos[i].refGuideId && !$scope.guide.expressCargos[i].refParcelId){
                            $rootScope.OPS_ALERT("货源必须是“并单”");
                            return;
                        }
                    }
                }
                else {
                    if($scope.guide.shipmentType != "WMS"){
                        $rootScope.OPS_ALERT("服务类型必须是“仓储服务”");
                        return;
                    }
                    if(!$scope.guide.warehouseName || !$scope.guide.warehouseId){
                        $rootScope.OPS_ALERT("库房名称是必选项");
                        return;
                    }
                    // for(var i = 0; i < $scope.guide.expressCargos.length; i++){
                    //     if(!$scope.guide.expressCargos[i].wmsCargoId){
                    //         $rootScope.OPS_ALERT("货源必须是“库存”，不可以是其它任何类型的货源");
                    //         return;
                    //     }
                    // }
                }
            }
            var pushOrdersDTO = {
                accountBind: accountBind,
                expressParcelDTOs: [angular.copy($scope.guide)]
            }
            //W24108297240 是这个类型，又不存在面单的时候，需要从里面选物流渠道什么的
            var goeosv2 = accountBind.type === 'GOEOSV2' && !pushOrdersDTO.expressParcelDTOs[0].labelUrl
            if(((accountBind.type == 'chukou1' || accountBind.type == 'ecang' || accountBind.type == 'westernpost' || accountBind.type == 'nodeWms')
                && (accountBind.thirdPartCode.indexOf(";") != -1 || accountBind.payeeCode.indexOf(";") != -1)) || goeosv2 ){
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                    loadGuide();
                }, function() {
                });
                return;
            }
            if(accountBind.type == 'GOEOS'){
                layer.confirm("推送订单时，是否需要包含快递面单?", {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    var entity = angular.copy($scope.guide);
                    entity.thirdpartyPlatformId = accountBind.id;
                    ExpressParcel.pushOneLabel(entity, function(resultList){
                        layer.msg($translate.instant('global.messages.submitted'));
                        loadGuide();
                    }, $rootScope.ALERT_ERROR);
                    $translate.refresh();
                }, function() {
                    pushOrderDo(pushOrdersDTO);
                });
            } else {
                pushOrderDo(pushOrdersDTO);
            }
        }

        function pushOrderDo(pushOrdersDTO) {
            layer.load(1, {shade: 0.3});
            ExpressParcel.pushOrders(pushOrdersDTO, function(resultList){
                layer.closeAll();
                if(resultList && resultList.length == 1){
                    var result = resultList[0];
                    if(!result.thirdpartyPlatformId && result.innerRemarks){
                        $rootScope.OPS_ALERT(result.innerRemarks);
                    }
                    else {
                        layer.msg($translate.instant('global.send_success'));
                    }
                }
                loadGuide();
            }, function (){
                layer.closeAll();
                layer.msg($translate.instant('global.send_fail'));
            });
        }


        $scope.pushOneLabel = function() {
            ExpressParcel.pushOneLabel($scope.guide, function(resultList){
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

        function updatePushOrdersStatus () {
            layer.load(1, {shade: 0.3});
            var pushOrdersDTO = {
                expressParcelDTOs: [angular.copy($scope.guide)]
            }
            ExpressParcel.updatePushOrdersStatus(pushOrdersDTO, function(resultList){
                loadGuide();
                layer.closeAll();
                layer.msg($translate.instant('global.update_success'));
            }, function (){
                layer.closeAll();
                layer.msg($translate.instant('global.update_fail'));
            });
        }
        function delPush () {
            layer.confirm($translate.instant('autopsApp.expressParcel.delPushAlert'), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.load(1, {shade: 0.3});
                var pushOrdersDTO = {
                    expressParcelDTOs: [angular.copy($scope.guide)]
                }
                ExpressParcel.delPush(pushOrdersDTO, function(){
                    loadGuide();
                    layer.closeAll();
                }, function (error){
                    layer.closeAll();
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        layer.msg($translate.instant('global.requestFail'));
                    }
                });
                layer.close(index);
            }, function() {
            });
        }
        $scope._onCountrySelected = function(item, fd) {
            if($scope.guide.companyId == 411 || $rootScope.HOST_COMPANY_ID == 411){
                $scope.guide[fd] = angular.uppercase(item.enName) + "(" + item.code + ")";
            }
        }

        function getCargoPics() {
            var cargoIsOutError = false;
            angular.forEach($scope.guide.expressCargos, function (expressCargo) {
                if((expressCargo.locked == true) != ($scope.guide.cargoIsOut == true)){
                    cargoIsOutError = true;
                }
                var filePath = "/" + "expressCargo/" + expressCargo.id + "/cargoPic/";
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: $rootScope.account.companyId
                },function (data){
                    if(data && data.length > 0){
                        expressCargo.cargoPics = data;
                        guideCopy = angular.copy($scope.guide);
                    }
                });
                angular.forEach(expressCargo.parcelGoods, function (parcelGood) {
                    filePath = "/" + "parcelGoods/" + parcelGood.id + "/cargoPic/";
                    OpsFiles.getFilesList({
                        filepath: filePath,
                        companyId: $rootScope.account.companyId
                    },function (data){
                        if(data && data.length > 0){
                            parcelGood.cargoPics = data;
                            guideCopy = angular.copy($scope.guide);
                        }
                    });
                })
            })
            if(cargoIsOutError && $scope.guide.id){
                ExpressParcel.cargoIsOutChange({
                    id: $scope.guide.id
                }, function(result) {
                    loadGuide();
                    layer.msg($translate.instant('global.save_success'));
                }, function(result) {
                    loadGuide();
                    layer.msg($translate.instant('global.save_fail'));
                });
            }
        }

        function contains(file) {
            file = angular.lowercase(file);
            var regx  = new Array('jpg','png','jpeg','bmp','tiff');
            for(var m = 0 ; m < regx.length; m++){
                if(file == regx[m]){
                    return true;
                }
            }
            return false;
        }
        $scope.uploadPic = function(file, expressCargo) {
            if (!file) {
                return;
            }
            if (!expressCargo.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var fileSplit = file.name.split(".");
            if(!contains(fileSplit[fileSplit.length-1])){
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            if(file.size>2040960){
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }
            var fd = new FormData();
            var filePath = "/" + "expressCargo/" + expressCargo.id + "/cargoPic/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(function(data) {
                    if(!expressCargo.cargoPics){
                        expressCargo.cargoPics = [];
                    }
                    expressCargo.cargoPics.push(data);
                    $scope.cargoPic = data;
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        $scope.uploadGoodsPic = function(file, expressCargo) {
            if (!file) {
                return;
            }
            if (!expressCargo.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var fileSplit = file.name.split(".");
            if(!contains(fileSplit[fileSplit.length-1])){
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            if(file.size>2040960){
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }
            var fd = new FormData();
            var filePath = "/" + "parcelGoods/" + expressCargo.id + "/cargoPic/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(function(data) {
                    if(!expressCargo.cargoPics){
                        expressCargo.cargoPics = [];
                    }
                    expressCargo.cargoPics.push(data);
                    $scope.cargoPic = data;
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        $scope.downLoadPic = function(cargoPic) {
            var aTag = document.createElement("a");
            aTag.href = cargoPic.fileurl;
            aTag.click();
        }

        $scope.addMixPl = function(cargo) {
            if(!cargo.parcelGoods || cargo.parcelGoods.length == 0){
                cargo.parcelGoods = [];
                var mixPl = angular.copy(cargo);
                mixPl.id = null;
                mixPl.expressCargoId = null;
                mixPl.expressCargo = null;
                mixPl.cargoPics = [];
                cargo.parcelGoods.push(mixPl);
            }
            else {
                cargo.parcelGoods.push({});
            }
            $translate.refresh();
        };
        $scope.updateSubPrice = function(cargo) {
            if(cargo.pieces && cargo.unitPrice){
                cargo.totalPrice = DataUtils.round(cargo.pieces * cargo.unitPrice, 2);
            }
        };
        $scope.updatePrice = function(expressCargo) {
            if(expressCargo.quantity &&  expressCargo.usdValuePerUnit){
                expressCargo.usdValueTtl = DataUtils.round(expressCargo.quantity * expressCargo.usdValuePerUnit, 2);
            }
        };
        $scope.deleteMixPl = function(mixPl, expressCargo) {
            if($scope.guide.isClosed){return;}
            if (mixPl.id && $scope.guide.id) {
                var loadIndex = layer.load(1, {shade: 0.3});
                ParcelGoods.delete({
                    id: mixPl.id
                }, function(){
                    layer.close(loadIndex);
                    loadGuide();
                }, function(result){
                    layer.close(loadIndex);
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                })
            }
            else {
                var index = expressCargo.parcelGoods.indexOf(mixPl);
                if (index > -1) {
                    expressCargo.parcelGoods.splice(index, 1);
                }
            }
        }
        $scope.getAuthAccounts = function() {
            if(!$scope.guide.shipFromId){
                return;
            }
            AddressBook.getAuthAccounts({
                addressId: $scope.guide.shipFromId
            }, function(result){
                $scope.authAccounts = result;
            })
        }
        $scope.isResidentialChange = function() {
            if ($scope.guide.companyId == 129) {
                if(!$scope.guide.isResidential && "GROUND_HOME_DELIVERY" == $scope.guide.serviceDescription){
                    $scope.guide.serviceType = $scope.guide.labelBindType == "FEDEXNEW" ? "FEDEX_GROUND" : "29";
                    $scope.guide.serviceDescription = "FEDEX_GROUND";
                }
                else if ($scope.guide.isResidential && "FEDEX_GROUND" == $scope.guide.serviceDescription){
                    $scope.guide.serviceType = $scope.guide.labelBindType == "FEDEXNEW" ? "GROUND_HOME_DELIVERY" : "37";
                    $scope.guide.serviceDescription = "GROUND_HOME_DELIVERY";
                }
            }
        }
        $scope.setCancelShipment = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.setCancelShipment({id:$scope.guide.id}, function(result) {
                loadGuide();
                layer.closeAll();
                layer.msg($translate.instant('global.save_success'));
            }, $rootScope.ALERT_ERROR);
        }

        $scope.setTrackingFinished = function(type) {
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if(!$scope.guide.mblNum){
                return;
            }
            ExpressParcel.checkOkForAutoTrack({
                mblNum: $scope.guide.mblNum,
                carrier: $scope.guide.carrier
            }, function (result) {
                if(!result.okForAutoTrack){
                    layer.msg($translate.instant('autopsApp.expressParcel.autoTrackNotSupported'));
                }
                else {
                    if("autoTrack" == type){
                        if($scope.guide.trackingFinished == null){
                            $scope.guide.trackingFinished = false;
                        }
                        else {
                            $scope.guide.trackingFinished = null;
                        }
                    }
                    else {
                        $scope.guide.trackingFinished = !$scope.guide.trackingFinished;
                    }
                }
            })
        }

        $scope.setProblemShipment = function(type) {
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            if($scope.guide.problemShipment && $scope.guide.trackingFinished == false){
                $scope.guide.trackingFinished = true;
                layer.msg($translate.instant('autopsApp.guide.trackingFinishedDone'));
            }
            $scope.guide.problemShipment = !$scope.guide.problemShipment;
        }

        function byCtnsChange() {
            $scope.guide.byCtns = guideCopy.byCtns;
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if ($scope.guide.isVoid) {
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.byCtnsChange({id: $scope.guide.id}, function(result) {
                loadGuide();
                layer.closeAll();
                layer.msg($translate.instant('global.save_success'));
            }, $rootScope.ALERT_ERROR);
        }

        function fillByCommodityDatabase() {
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            ExpressParcel.fillByCommodityDatabase({expressParcelId: $scope.guide.id}, function(result){
                $scope.guide = result;
                layer.msg($translate.instant('global.messages.updateStatusSuccess'));
            }, $rootScope.ALERT_ERROR);
        }
        $scope.wmsLabels = function(reportType, fileType) {
            if(!$scope.guide.shipperId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.parcelId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: fileType
            }, wmsIn, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, $scope.guide.jobNum + "_labels");
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.trackingAndUpdateParcel = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            layer.load(1, {shade: 0.3});
            ExpressParcel.trackingAndUpdateParcel({id: $scope.guide.id}, function(result) {
                loadGuide();
                layer.closeAll();
                layer.msg($translate.instant('global.update_success'));
            }, $rootScope.ALERT_ERROR);
        }

        function getPushApiRequestBody(accountBind){
            var pushOrdersDTO = {
                accountBind: accountBind,
                expressParcelDTO: $scope.guide
            }
            if((accountBind.type == 'chukou1' || accountBind.type == 'ecang' || accountBind.type == 'westernpost' || accountBind.type == 'nodeWms')
                && (accountBind.thirdPartCode.indexOf(";") != -1 || accountBind.payeeCode.indexOf(";") != -1)){
                pushOrdersDTO.getRequestBody = true;
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                }, function() {
                });
                return;
            }
            ExpressParcel.getPushApiRequestBody(pushOrdersDTO, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, $scope.guide.jobNum + "_request_body.txt");
            }, function(error){
                layer.closeAll();
            });
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:parcelUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }
        });
        $scope.$on('$destroy', unsubscribeUpdateByChannel);

        $scope.isYuyueEdiFm = function() {
            if ($rootScope.isYuyue($scope.guide.companyId)
                && $scope.guide.thirdpartyPlatformRef && $scope.guide.thirdpartyPlatformRef.indexOf("KD") == 0
                && $scope.guide.thirdpartyPlatform == "auwms") {
                return true;
            }

            if ($scope.guide.branchGuideId && $scope.guide.branchGuideId > $scope.guide.id) {
                return true;
            }
            return false;
        }


        function _onGoodsSelected(data, cargo) {
            cargo.sku = data.sku;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.cargoName = data.cargoName;
            cargo.cargoNameEn = data.cargoNameEn;
            cargo.hsCode = data.hsCode;
            cargo.productUrl = data.productUrl;
            if(data.declareValue){
                cargo.usdValuePerUnit = data.declareValue;
            }
            else {
                cargo.usdValuePerUnit = data.goodsValue;
            }
            cargo.quantity = data.piece;
            updateGwTtl(cargo);
            updateVolTtl(cargo);
        }

        /*expressPackage*/
        vm.addPkgCargo = addPkgCargo;
        vm.packOne = packOne;
        vm.updatePkgCount = updatePkgCount;
        vm.updatePkgGwTtl = updatePkgGwTtl;
        vm.updatePkgVolTtl = updatePkgVolTtl;
        vm.updatePackageTotal = updatePackageTotal;
        vm.updatePkgVol = updatePkgVol;
        vm.delPkgCargo = delPkgCargo;

        function addPkgCargo () {
            if(!$scope.guide.expressPackages){
                $scope.guide.expressPackages = [];
            }
            $scope.guide.expressPackages.push({});
        }
        function packOne () {
            if(!$scope.guide.expressPackages){
                $scope.guide.expressPackages = [];
            }
            var allCargoName = null;
            var allSku = "";
            angular.forEach($scope.guide.expressCargos, function(data){
                if(!allCargoName){
                    allCargoName = data.cargoName;
                }
                if(allSku.length < 35 && data.sku){
                    allSku += data.sku + " ";
                }
            })
            var newCargo = {
                cargoName: allCargoName,
                sku: allSku,
                pkgs: 1,
                packageType: "CTNS",
                gw: $scope.guide.gw,
                gwTtl: $scope.guide.gw,
                vol: $scope.guide.vol,
                volTtl: $scope.guide.vol
            }

            $scope.guide.expressPackages.push(newCargo);
            updatePackageTotal();
        }
        var updatePackageTotal = function() {
            var ttlPkgs = 0, ttlGw = 0, ttlVol = 0;
            angular.forEach($scope.guide.expressPackages, function(data){
                if(data.pkgs){
                    ttlPkgs += data.pkgs;
                    if(data.gwTtl){
                        ttlGw += data.gwTtl;
                    }
                    if(data.volTtl){
                        ttlVol += data.volTtl;
                    }
                }
            })

            vm.totalLine = {
                status: "Total",
                pkgs: DataUtils.round(ttlPkgs, 0),
                gwTtl: DataUtils.round(ttlGw, 2),
                volTtl: DataUtils.round(ttlVol, 3)
            };
            // if(ttlPkgs){
            //     $scope.guide.pkgNum = ttlPkgs;
            //     $scope.guide.gw = ttlGw.toFixed(3) -0;
            //     $scope.guide.vol = ttlVol.toFixed(4) -0;
            //     $scope.guide.lbs = (ttlGw * 2.20462262).toFixed(3) -0;
            // }
        }
        function updatePkgCount () {
            if (!$scope.guide.expressPackages || $scope.guide.expressPackages.length == 0){
                return;
            }
            angular.forEach($scope.guide.expressPackages, function(data) {
                var pkgs = data.pkgs;
                if(!pkgs){pkgs = 0;}

                if (data.vol){
                    data.volTtl = data.vol * pkgs;
                }
                if(!data.volTtl){
                    data.volTtl = 0;
                }
                data.volTtl = data.volTtl.toFixed(3) -0;

                if(data.gw){
                    data.gwTtl = data.gw * pkgs
                }
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }
                data.gwTtl = data.gwTtl.toFixed(2) -0;
            })
            updatePackageTotal();
        }
        function updatePkgGwTtl (cargo) {
            if(cargo.gw && cargo.pkgs){
                cargo.gwTtl = cargo.gw * cargo.pkgs
            }
            else {
                cargo.gwTtl = 0;
            }
            cargo.gwTtl = cargo.gwTtl.toFixed(2) -0;
            updatePackageTotal();
        }
        function updatePkgVolTtl (cargo) {
            cargo.volTtl = 0;
            if (cargo.vol && cargo.pkgs){
                cargo.volTtl = cargo.vol * cargo.pkgs;
            }
            cargo.volTtl = cargo.volTtl.toFixed(2) -0;
            updatePackageTotal();
        }
        function updatePkgVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length/100 * cargo.width/100 * cargo.height/100;
                cargo.vol = cargo.vol.toFixed(6) -0;
                updatePkgVolTtl(cargo);
            }
        }
        function delPkgCargo (cargo) {
            if (!cargo.id) {
                var index = $scope.guide.expressPackages.indexOf(cargo);
                if (index > -1) {
                    $scope.guide.expressPackages.splice(index, 1);
                };
                updatePackageTotal();
                $translate.refresh();
            }
            else {
                if (!angular.equals(guideCopy, $scope.guide)) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                ExpressPackage.delete({
                    id: cargo.id
                }, function(){
                    loadGuide();
                });
            }
        };

        $scope.switchJobCompany = function(companyId) {
            if ($rootScope.HAVENOAUTH('AU_ORDER_TRANSFER')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_ORDER_TRANSFER');
                return;
            }
            if (!$scope.guide.id) {
                return;
            }
            ExpressParcel.switchJobCompany({
                expressParcelId: $scope.guide.id,
                newComId: companyId
            }, function(result) {
                layer.msg($translate.instant('global.messages.switchSuccess'));
                clear();
            }, $rootScope.ALERT_ERROR)
        };
    }
})();
